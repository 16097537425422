import React, { useEffect, useState } from "react";
import {
  Box,
  Paper,
  Grid,
  Typography,
  Button,
  Container,
  TextField,
  FormControl
} from "@mui/material";
import Navbar from "./Navbar";
// import DynamicBackground from "./Dynamicbg";
// import payment_data from "../data/payment_data";
// import SP_Jain_data from "../data/spJain";
import { Link, useNavigate,useParams,useLocation } from "react-router-dom";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import Select from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";

const SignupUpdated = () => {
  let navigate = useNavigate();
  // const {id} = useParams()
  // console.log(useLocation())
  const [firstname, setFirstName] = useState("");
  const [url, setUrl] = useState("");
  const [lastname, setLastName] = useState("");
  const [middlename, setMiddleName] = useState("");
  const [email, setEmail] = useState("");
  const [backopen, setBackopen] = useState(false);
  const [programs, setPrograms] = useState([]);
  const[category_id,setCategory]=useState('')
  const[pid,setpid]=useState('')
  const [course, setCourse] = useState([]);
  const [courses, setCourses] = useState("");
  const [courseName, setCoursename] = useState("");
  const [id, setid] = useState("");

  const handleChangecourse = (pid, name) => {
    setCourses(pid);
    setCoursename(name)
    // setCoursescode(pcode);
    // setCategory(cid);

   
  };

  const LoaderClose = () => {
    setBackopen(false);
  };
  const LoaderOpen = () => {
    setBackopen(true);
  };
  const handleChangeFirstName = (e) => {
    setFirstName(e.target.value);
  };
  const handleChangeLastName = (e) => {
    setLastName(e.target.value);
  };
  const handleChangeMiddleName = (e) => {
    setMiddleName(e.target.value);
  };
  const handleChangeEmail = (e) => {
    setEmail(e.target.value);
    localStorage.setItem("email",e.target.value);
  };
  // Business Analytics
  const data=[
    {value:2,vertical:"Sales & Marketing"},
    {value:4,vertical:"Strategic HR Analytics"},
    // {value:11,vertical:"Business Analytics"}
  ]
  useEffect(()=>{
    const url = new URL(window.location.href);
    setid(url.hostname.split('.')[0]);
    import(`../data/${id}_data.js`)
    .then((module) => {
      const data = module.default;
      setCourse(data[0].SignUpVertical)
        setUrl(data[0].bg)
    })
    .catch((error) => {
      console.error(`Error loading property file: ${error}`);
    });
  // const ans=  payment_data.filter((val)=>{
  //  return val.id==id
  //   })
  //   setCourse(ans[0].SignUpVertical)
  //   setUrl(ans[0].bg)

  },[id])
  const handleChange=(cat)=>{
    // console.log(e.target.value,"yttttt")
    setCategory(cat)
   
  }
  
  function convertEmailToLowerCase(email) {
    // Split the email string into username and domain
    var parts = email.split("@");
    
    // Convert the username to lowercase
    var username = parts[0].toLowerCase();
    var last= parts[1].toLowerCase();
    
    // Return the modified email
    return username + "@" + last;
  }
  const handleChangeprogram=(code,id)=>{
    setpid(id)
  }
  const handleSubmit = (e) => {
    try{
      LoaderOpen();
      e.preventDefault();
      if (email && firstname && lastname && courses) {
        axios({
          method: "post",
          url: `${process.env.REACT_APP_NODE_URL}/api/v1/signup/`,
          data: {
            type: "ApplyNow",
            email: convertEmailToLowerCase(email),
            firstname: firstname.replace(/\s/g, ''),
            middlename: middlename.replace(/\s/g, ''),
            lastname: lastname.replace(/\s/g, ''),
            category_id: courses,
            university:id,
            course:courseName,
            url:`https://${id}.accredian.com/login`
           
            // category:category_id
            // password: password,
          },
        }).then(function (response) {
         
          LoaderClose();
          if (response.data.statusCode == 200) {
            toast.success("Your account has been created successfully", {
              position: "top-right",
              autoClose: 2000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "colored",
            });
            setEmail("");
  
            setTimeout(() => {
              navigate(`/login`);
            }, 2000);
          }
          else if(response.data.statusCode == 401){
            toast.error(
              "Please try again. If you already have an account, please try login.",
              {
                position: "top-right",
                autoClose: 4000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "colored",
              }
            );
          }
          
          else {
            toast.error(
              "We apologize for the inconvenience. Our system is currently experiencing heavy load. Please try again after some time. Thank you for your patience.",
              {
                position: "top-right",
                autoClose: 4000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "colored",
              }
            );
          }
        }).catch((err)=>{
          LoaderClose();
          toast.error(
            "Apologies for any inconvenience. Our system is experiencing high demand, causing delays. Please retry later. Your patience is appreciated.",
            {
              position: "top-center",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "colored",
            }
          );
        })
      } else {
        LoaderClose();
        toast.error("Please enter all the required fields", {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      }
    }
    catch(error){
      console.log("signup api error",error)
    }
    
  };

  // console.log(category_id,"lll")
  return (
    <>
   
      <Navbar />
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={backopen}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <ToastContainer />
      <Box sx={{display:{xs:"none",lg:"flex",sm:"flex",md:"flex"},
      justifyContent:"center",alignItems:"center",
      backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.2)),url(${url})`,
      backgroundSize: "cover",
      backgroundAttachment:"fixed",
      height:{lg:"100vh",sm:"950px",md:"1300px"}
    
    }}>
     
        
            <Box sx={{ pt:{lg:1,xs:6,sm:6,md:6}}}>
              <Container fixed>
                <Box>
                  <Box>
                    <Grid container spacing={12} justifyContent="center">
                      <Grid item lg={12} sm={12} md={12} sx={{ pb: 4 }}>
                        <Paper
                          elevation={3}
                          sx={{
                            boxShadow: "0 0 1rem 0 rgba(0, 0, 0, .5)",
                            borderRadius: "15px",
                            backgroundColor: "#fff",

                            //   backdropFilter: "blur(5px)",
                            //   border: "1px solid #fdb714",
                            p: 0.5,
                          }}
                        >
                          <Box>
                            <Typography
                              sx={{
                                textAlign: "center",
                                //   py: 1,
                                fontWeight: "bold",
                                color: "#002E5C",
                                fontSize: "27px",
                              }}
                            >
                              Sign Up
                            </Typography>
                          </Box>
                          <hr
                            style={{
                              backgroundColor: "#000",
                              border: "none",
                              height: "1.3px",
                              width: "90%",
                            }}
                          />
                          <Box sx={{ mx: 2, py: 1 }}>
                            <form onSubmit={handleSubmit}>
                              <Box sx={{ mt: 1 }}>
                                <Box sx={{display:"flex",justifyContent:"space-between"}}>
                                <Box>
                                  <Typography
                                    sx={{
                                      fontWeight: "bold",
                                      fontSize: "16px",
                                      color: "#000",
                                      textAlign: "left",
                                    }}
                                  >
                                    First Name
                                    <span style={{ color: "red" }}>*</span>
                                  </Typography>
                                  <TextField
                                  fullWidth
                                    required
                                    name="firstname"
                                    id="firstname"
                                    type="text"
                                    sx={{
                                      width:{lg:240,xl:320},
                                      mb: 2,
                                      background: "#f7f8f9",
                                      borderRadius: "4px",
                                    }}
                                    size="small"
                                    onKeyPress={(e) => {
                                      if (new RegExp(/[a-zA-Z]/).test(e.key)) {
                                      } else e.preventDefault();
                                    }}
                                    onChange={handleChangeFirstName}
                                  />
                                </Box>
                                <Box>
                                  <Typography
                                    sx={{
                                      fontWeight: "bold",
                                      fontSize: "16px",
                                      color: "#000",
                                      textAlign: "left",
                                      ml:2,
                                    }}
                                  >
                                    Middle Name
                                  </Typography>
                                  <TextField
                                    fullWidth
                                    // required
                                    name="middlename"
                                    id="middlename"
                                    type="text"
                                    sx={{
                                      width:{lg:240,sm:230,md:230,xl:320},
                                      mb: 2,
                                      ml:2,
                                      background: "#f7f8f9",
                                      borderRadius: "4px",
                                    }}
                                    size="small"
                                    onKeyPress={(e) => {
                                      if (new RegExp(/[a-zA-Z]/).test(e.key)) {
                                      } else e.preventDefault();
                                    }}
                                    onChange={handleChangeMiddleName}
                                  />
                                </Box>
                                </Box>
                               <Box sx={{display:"flex",justifyContent:"space-between"}}>
                               <Box>
                                  <Typography
                                    sx={{
                                      fontWeight: "bold",
                                      fontSize: "16px",
                                      color: "#000",
                                      textAlign: "left",
                                    }}
                                  >
                                    Last Name
                                    <span style={{ color: "red" }}>*</span>
                                  </Typography>
                                  <TextField
                                    fullWidth
                                    required
                                    name="lastname"
                                    id="lastname"
                                    type="text"
                                    sx={{
                                      width:{lg:240,xl:320},
                                      mb: 2,
                                      background: "#f7f8f9",
                                      borderRadius: "4px",
                                    }}
                                    size="small"
                                    onKeyPress={(e) => {
                                      if (new RegExp(/[a-zA-Z]/).test(e.key)) {
                                      } else e.preventDefault();
                                    }}
                                    onChange={handleChangeLastName}
                                  />
                                </Box>
                                <Box>
                                  <Typography
                                    sx={{
                                      fontWeight: "bold",
                                      fontSize: "16px",
                                      color: "#000",
                                      textAlign: "left",
                                      ml:2,
                                    }}
                                  >
                                    Email<span style={{ color: "red" }}>*</span>
                                  </Typography>
                                  <TextField
                                    name="email"
                                    id="email"
                                    type="email"
                                    fullWidth
                                    required
                                    sx={{
                                      width:{lg:240,sm:230,md:230,xl:320},
                                      mb: 2,
                                      ml:2,
                                      background: "#f7f8f9",
                                      borderRadius: "4px",
                                    }}
                                    size="small"
                                    onChange={handleChangeEmail}
                                  />
                                </Box>
                              </Box>
                              <Box sx={{ mb: 2 }}>
                              <Box>
                            <Typography
                              sx={{
                                fontWeight: "bold",
                                fontSize: "16px",
                                textAlign:"left",
                                color: "#000000",
                              }}
                            >
                              Select Your Program Category <span style={{ color: "red" }}>*</span>
                            </Typography>
                            <FormControl fullWidth>
                              <Select
                              required
                                size="small"
                                sx={{ width:{xs:"auto",lg:"auto"}, background: "#f7f8f9",textAlign:"left" }}
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                // value={country}
                                defaultValue="program"
                                label="course"
                                // onChange={handleChange}
                              >
                                   <MenuItem disabled value="program">
            <em>Select Your Program Category</em>
          </MenuItem>
          {course.map((val, i) => (
                                  <MenuItem
                                    onClick={() =>
                                      handleChangecourse(
                                        val.id,
                                        val.name
                                      )
                                    }
                                    value={val.id}
                                  >
                                    {val.name}
                                  </MenuItem>
                                ))}
                              </Select>
                            </FormControl>
                          </Box>
                               </Box>
                                
                               {/* <Box sx={{ mb: 2.5 }}>
                              <Box>
                            <Typography
                              sx={{
                                fontWeight: "bold",
                                fontSize: "16px",
                                textAlign:"left",
                                color: "#000000",
                              }}
                            >
                              Program <span style={{ color: "red" }}>*</span>
                            </Typography>
                            <FormControl fullWidth>
                              <Select
                              required
                                size="small"
                                sx={{ width:{xs:"auto",lg:"auto"}, background: "#f7f8f9",textAlign:"left" }}
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                // value={country}
                                defaultValue="program"
                                label="course"
                                // onChange={handleChangeCountry}
                              >
                                   <MenuItem value="program">
            <em>Select program</em>
          </MenuItem>
                                {programs.map((val, i) => (
                                  <MenuItem
                                    onClick={() =>
                                      handleChangeprogram(
                                        val.pcode,
                                        val.pid,
                                        
                                      )
                                    }
                                    value={val.pid}
                                  >
                                  {val.pname}
                                  </MenuItem>
                                 ))} 
                              </Select>
                            </FormControl>
                          </Box>
                               </Box> */}
                                <Box></Box>
                              </Box>
                              {/* <Box sx={{ mt: 1 }}>
                                <Box></Box>
                              </Box> */}

                              <Button
                                sx={{
                                  background: "#002E5C",
                                  color: "#fff",
                                  fontWeight: "bold",
                                  textTransform: "none",
                                  fontSize: "20px",
                                  "&:hover": {
                                    background: "#002E5C",
                                    color: "#fff",
                                  },
                                }}
                                variant="contained"
                                fullWidth
                                type="submit"
                              >
                                Sign Up
                              </Button>
                            </form>
                            <Box
                              sx={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                              }}
                            >
                              <Typography
                                sx={{
                                  py: 1,
                                  color: "#000",
                                  fontWeight: "bold",
                                }}
                              >
                                Already a user?
                              </Typography>
                              <Link
                                style={{
                                  marginTop: "-5px",
                                  marginLeft: "10px",
                                  color: "red",
                                  fontWeight: "bold",
                                }}
                                to={`/login`}
                              >
                                Login
                              </Link>
                            </Box>
                          </Box>
                        </Paper>
                      </Grid>
                    </Grid>
                  </Box>
                </Box>
              </Container>
            </Box>
          </Box>
           
          {/* </Grid> */}
        {/* </Grid> */}
     

{/* mobile version */}
      <Box sx={{display:{xs:"block",lg:"none",sm:"none",md:"none"},
    backgroundImage: `url(${url})`,
    backgroundSize: "cover",
    backgroundAttachment:"fixed",
    height:"100vh",
    }}>
      <Box
        sx={{
          width: "100%",
          // backgroundImage: `url(${require("../images/image_10.png")})`,
          // backgroundSize: "cover",
          // backgroundPosition: "center",
          // backgroundRepeat: "no-repeat",
        }}
      >
        <Grid container>
          {/* <Grid item lg={3}>
            <Box sx={{ ml: 3, mt: 3 }}>
              <Box sx={{ mb: 1 }}>
                <Typography
                  sx={{
                    color: "#000",
                    fontWeight: "bold",
                    fontSize: "21px",
                    width: 300,
                    textAlign: "left",
                  }}
                >
                  Online certifications from a NIRF #34 ranked Management College
                </Typography>
              </Box>
              <Box
                sx={{
                  borderRadius: "6px",
                  p: 1,
                  background: "linear-gradient(#002E5C,#2A7BCC)",
                  width: 290,
                }}
              >
                <Typography
                  sx={{
                    color: "#fff",
                    fontWeight: "bold",
                    fontSize: "24px",
                    width: 280,
                    textAlign: "left",
                  }}
                >
                   Executive Program in
                </Typography>
                <Typography
                  sx={{
                    color: "#fff",
                    fontSize: "24px",
                    width: 280,
                    textAlign: "left",
                  }}
                >
                   Strategic HR Analytics and Sales & Marketing
                </Typography>
              </Box>
            </Box>
          </Grid> */}
          <Grid item xs={12}>
            <Box sx={{ pt: 8 }}>
              <Container fixed>
                <Box>
                  <Box>
                    <Grid container spacing={12} justifyContent="center">
                      <Grid item xs={12} sx={{ pb: 4 }}>
                        <Paper
                          elevation={3}
                          sx={{
                            boxShadow: "0 0 1rem 0 rgba(0, 0, 0, .2)",
                            borderRadius: "15px",
                            backgroundColor: "#fff",

                            //   backdropFilter: "blur(5px)",
                            //   border: "1px solid #fdb714",
                            p: 0.5,
                          }}
                        >
                          <Box>
                            <Typography
                              sx={{
                                textAlign: "center",
                                //   py: 1,
                                fontWeight: "bold",
                                color: "#002E5C",
                                fontSize: "27px",
                              }}
                            >
                              Sign Up
                            </Typography>
                          </Box>
                          <hr
                            style={{
                              backgroundColor: "#000",
                              border: "none",
                              height: "1.3px",
                              width: "90%",
                            }}
                          />
                          <Box sx={{ mx: 2, py: 1 }}>
                            <form onSubmit={handleSubmit}>
                              <Box sx={{ mt: 1 }}>
                                <Box>
                                  <Typography
                                    sx={{
                                      fontWeight: "bold",
                                      fontSize: "16px",
                                      color: "#000",
                                      textAlign: "left",
                                    }}
                                  >
                                    First Name
                                    <span style={{ color: "red" }}>*</span>
                                  </Typography>
                                  <TextField
                                    fullWidth
                                    required
                                    name="firstname"
                                    id="firstname"
                                    type="text"
                                    sx={{
                                      mb: 2,
                                      background: "#f7f8f9",
                                      borderRadius: "4px",
                                    }}
                                    size="small"
                                    onKeyPress={(e) => {
                                      if (new RegExp(/[a-zA-Z]/).test(e.key)) {
                                      } else e.preventDefault();
                                    }}
                                   
                                    onChange={handleChangeFirstName}
                                  />
                                </Box>
                                <Box>
                                  <Typography
                                    sx={{
                                      fontWeight: "bold",
                                      fontSize: "16px",
                                      color: "#000",
                                      textAlign: "left",
                                    }}
                                  >
                                    Middle Name
                                  </Typography>
                                  <TextField
                                    fullWidth
                                    // required
                                    name="middlename"
                                    id="middlename"
                                    type="text"
                                    sx={{
                                      mb: 2,
                                      background: "#f7f8f9",
                                      borderRadius: "4px",
                                    }}
                                    size="small"
                                    onKeyPress={(e) => {
                                      if (new RegExp(/[a-zA-Z]/).test(e.key)) {
                                      } else e.preventDefault();
                                    }}
                                    onChange={handleChangeMiddleName}
                                  />
                                </Box>
                                <Box>
                                  <Typography
                                    sx={{
                                      fontWeight: "bold",
                                      fontSize: "16px",
                                      color: "#000",
                                      textAlign: "left",
                                    }}
                                  >
                                    Last Name
                                    <span style={{ color: "red" }}>*</span>
                                  </Typography>
                                  <TextField
                                    fullWidth
                                    required
                                    name="lastname"
                                    id="lastname"
                                    type="text"
                                    sx={{
                                      mb: 2,
                                      background: "#f7f8f9",
                                      borderRadius: "4px",
                                    }}
                                    size="small"
                                    onKeyPress={(e) => {
                                      if (new RegExp(/[a-zA-Z]/).test(e.key)) {
                                      } else e.preventDefault();
                                    }}
                                    onChange={handleChangeLastName}
                                  />
                                </Box>
                              </Box>
                              <Box sx={{ mb: 1 }}>
                                <Box>
                                  <Typography
                                    sx={{
                                      fontWeight: "bold",
                                      fontSize: "16px",
                                      color: "#000",
                                      textAlign: "left",
                                    }}
                                  >
                                    Email<span style={{ color: "red" }}>*</span>
                                  </Typography>
                                  <TextField
                                    name="email"
                                    id="email"
                                    type="email"
                                    fullWidth
                                    required
                                    sx={{
                                      mb: 2,
                                      background: "#f7f8f9",
                                      borderRadius: "4px",
                                    }}
                                    size="small"
                                    onChange={handleChangeEmail}
                                  />
                                </Box>
                                <Box></Box>
                              </Box>
                              <Box sx={{ mb: 1 }}>
                              <Box>
                            <Typography
                              sx={{
                                fontWeight: "bold",
                                fontSize: "16px",
                                textAlign:"left",
                                color: "#000000",
                              }}
                            >
                            Select Your Program Category <span style={{ color: "red" }}>*</span>
                            </Typography>
                            <FormControl fullWidth>
                              <Select
                              required
                                size="small"
                                sx={{ width:{xs:"auto",lg:"auto"}, background: "#f7f8f9",textAlign:"left" }}
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                // value={country}
                                defaultValue="program"
                                label="course"
                               
                              >
                                   <MenuItem  disabled value="program">
            <em>Select Your Program Category</em>
          </MenuItem>
                                
          {course.map((val, i) => (
                                  <MenuItem
                                  onClick={() =>
                                    handleChangecourse(
                                      val.id,
                                      val.name
                                    )
                                  }
                                  value={val.id}
                                >
                                  {val.name}
                                </MenuItem>
                                ))}
                               
                              </Select>
                            </FormControl>
                          </Box>
                              </Box>
                              {/* <Box sx={{ mb: 2 }}>
                              <Box>
                            <Typography
                              sx={{
                                fontWeight: "bold",
                                fontSize: "16px",
                                textAlign:"left",
                                color: "#000000",
                              }}
                            >
                              Program <span style={{ color: "red" }}>*</span>
                            </Typography>
                            <FormControl fullWidth>
                              <Select
                                size="small"
                                sx={{ width:{xs:"auto",lg:"auto"}, background: "#f7f8f9",textAlign:"left" }}
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                // value={country}
                                defaultValue="program"
                                label="course"
                                // onChange={handleChangeCountry}
                              >
                                   <MenuItem value="program">
            <em>Select program</em>
          </MenuItem>
                              
                                  <MenuItem
                                    // onClick={() =>
                                    //   handleChangecourse(
                                    //     val.pcode,
                                    //     val.pid,
                                    //     val.category_id
                                    //   )
                                    // }
                                    // value={val.pid}
                                  >
                                   Data Science
                                  </MenuItem>
                               
                              </Select>
                            </FormControl>
                          </Box>
                              </Box> */}

                              <Button
                                sx={{
                                  background: "#002E5C",
                                  color: "#fff",
                                  fontWeight: "bold",
                                  textTransform: "none",
                                  fontSize: "20px",
                                  "&:hover": {
                                    background: "#002E5C",
                                    color: "#fff",
                                  },
                                }}
                                variant="contained"
                                fullWidth
                                type="submit"
                              >
                                Sign Up
                              </Button>
                            </form>
                            <Box
                              sx={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                              }}
                            >
                              <Typography
                                sx={{
                                  py: 1,
                                  color: "#000",
                                  fontWeight: "bold",
                                }}
                              >
                                Already a user?
                              </Typography>
                              <Link
                                style={{
                                  marginTop: "-5px",
                                  marginLeft: "10px",
                                  color: "red",
                                  fontWeight: "bold",
                                }}
                                to={`/login`}
                              >
                                Login
                              </Link>
                            </Box>
                          </Box>
                        </Paper>
                      </Grid>
                    </Grid>
                  </Box>
                </Box>
              </Container>
            </Box>
          </Grid>
        </Grid>
      </Box>

      </Box>
          </>
  );
};
export default SignupUpdated;