import React, { useEffect, useState } from "react";
import {
  Box,
  Paper,
  Grid,
  Typography,
  Button,
  Container,
  TextField,
  FormControl,
  InputLabel,
  OutlinedInput,
  InputAdornment,
  IconButton,
  CardMedia,
  Tabs,
  Tab,
} from "@mui/material";
// import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
// import InputLabel from "@mui/material/InputLabel";
import { alpha, styled } from "@mui/material/styles";
import MenuItem from "@mui/material/MenuItem";
import InputBase from "@mui/material/InputBase";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import { Link, useNavigate, useParams } from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
// import useRazorpay from "react-razorpay";
// import logo from '../../images/accredian-logo.png'
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Swal from "sweetalert2";
// import payment_data from "../../data/payment_data";
// import icon from '../../images/icon.png'
import LinearProgress, {
  linearProgressClasses,
} from "@mui/material/LinearProgress";
const BootstrapInput = styled(InputBase)(({ theme }) => ({
  "label + &": {
    marginTop: theme.spacing(3),
  },
  "& .MuiInputBase-input": {
    borderRadius: 7,
    position: "relative",
    backgroundColor: theme.palette.mode === "light" ? "#fcfcfb" : "#2b2b2b",
    border: "1px solid #ced4da",
    fontSize: 16,
    width: 400,
    height: 19,
    padding: "10px 12px",
    boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
    transition: theme.transitions.create([
      "border-color",
      "background-color",
      "box-shadow",
    ]),
    // Use the system font instead of the default Roboto font.
    fontFamily: [
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
    "&:focus": {
      boxShadow: `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
      borderColor: theme.palette.primary.main,
    },
  },
}));

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 10,
  borderRadius: 5,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor:
      theme.palette.grey[theme.palette.mode === "light" ? 200 : 800],
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: theme.palette.mode === "light" ? "#2A7BCC" : "#2A7BCC",
  },
}));

const ApplicationFee = (props) => {
  let navigate = useNavigate();
  const { id } = useParams();
  const [user, setUser] = useState("");
  // const [amount, setAmount] = useState("");
  useEffect(() => {
    var user_id = localStorage.getItem("user_id");
    setUser(user_id);
  }, []);

  if (user == null) {
    navigate(`/Login/${id}`);
  }

  const [openPersonal, setOpenPersonal] = useState(false);
  const [openADD, setOpenADD] = useState(false);
  const [openaddress, setOpenaddress] = useState(false);
  const [courseName, setCourseName] = useState([]);
  const [course, setCourse] = useState([]);
  const [courses, setCourses] = useState(" ");
  const [category, setCategory] = useState(" ");
  const [coursescode, setCoursescode] = useState("");
  const [batch, setBatch] = useState([]);
  const [batchs, setBatchs] = useState(" ");
  const [batchtext, setBatchtxt] = useState("");
  const [register_id, setRegisterid] = useState(" ");
  const [paymentid, setPaymentid] = useState("");
  const [backopen, setBackopen] = useState(false);
  const [batchid, setBatchid] = useState("");
  const [nxt, setNxt] = useState(true);
  const [payment, setPayment] = useState("");
  const [show, setShow] = useState(false);
  const [addmissionPayment, setAddmissionpayment] = useState();
  const [program_name, setProgram] = useState("");
  const [error, setError] = useState(false);
  const [batch_date,setBatchDate]=useState("")
  

  const [value, setValue] = useState(0);
  const [enablePay, setPay] = useState(true);
  const [checked, setChecked] = useState("");
  const [showPaymentState, setPaymentState] = useState(false);

  // const[enablePayFinance,setPayFinance]=useState(true)
  const handleCheck = (event) => {
    setChecked(event.target.checked);
  };
  useEffect(() => {
    if (
      // (addmissionPayment && coursescode && !error && batch_date) ||
      // (addmissionPayment && program_name && !error && batchtext && batch.length==1) ||
      // (addmissionPayment && coursescode && !error && batchtext)||
      // (addmissionPayment && program_name && !error && batch_date)
      (addmissionPayment && !error && batch.length>1 && batch_date && program_name)||
      (addmissionPayment && !error && batch.length==1 && batchtext && program_name)||
      (addmissionPayment && !error && batch.length>1 && batch_date && coursescode)||
      (addmissionPayment && !error && batch.length==1 && batchtext && coursescode)
    ) {
      setPay(false);
    } else {
      setPay(true);
    }
  }, [enablePay, addmissionPayment, coursescode, program_name, error,batchtext,batch_date,batch]);
  useEffect(() => {
    if ((batch.length>1&&batch_date&&(coursescode || program_name))||(batch.length==1&&(coursescode || program_name))) {
      setNxt(false);
    }
    else{
      setNxt(true)
    }
  }, [coursescode, program_name,batch,batch_date]);
  const handleChange = (event, newValue) => {
    setValue(newValue);
    setBatchDate("")
  };
  // console.log(coursescode,batchtext,nxt,'check')
  const LoaderClose = () => {
    setBackopen(false);
  };
  const LoaderOpen = () => {
    setBackopen(true);
  };
  const handleClickPersonal = () => {
    setOpenPersonal(!openPersonal);
  };
  const handleClickAdd = () => {
    setOpenADD(!openADD);
  };
  const handleClickaddress = () => {
    setOpenaddress(!openaddress);
  };
  const handleChangecourse = (pcode, pid, pname) => {
    setCoursescode(pcode);
    setCourses(pid);
    setProgram(pname);
  };
  // console.log(course[0].pname,"dekho dekho");
  const handleChangebatch = (registerId, batch_id, batchtext,batchDate) => {
    setBatchid(batch_id);
    setBatchtxt(batchtext);
    setBatchDate(batchDate)
  };

  const full_paymentClick = () => {
    setPayment("");
    setPay(true);
    // setChecked(checked)
  };
  const handlePayment = () => {
    setAddmissionpayment("");

    document.getElementById("one").style.background = "#2A7BCC";
    document.getElementById("onet").style.color = "#fff";
    // document.getElementById("debit").style.background = "#fff";
    // document.getElementById("debitt").style.color = "#000";
    document.getElementById("credit").style.background = "#fff";
    document.getElementById("creditt").style.color = "#000";
    document.getElementById("finance").style.background = "#fff";
    document.getElementById("financet").style.color = "#000";
    setPayment("oneTime");
  };
  const handlePaymentDebit = () => {
    // document.getElementById("debit").style.background = "#2A7BCC";
    // document.getElementById("debitt").style.color = "#fff";
    document.getElementById("one").style.background = "#fff";
    document.getElementById("onet").style.color = "#000";
    document.getElementById("credit").style.background = "#fff";
    document.getElementById("creditt").style.color = "#000";
    document.getElementById("finance").style.background = "#fff";
    document.getElementById("financet").style.color = "#000";
    setPayment("debit");
  };
  const handlePaymentCredit = () => {
    // setAddmissionpayment('')
    // setCountry('')
    // setState('')
    // setCity('')
    document.getElementById("credit").style.background = "#2A7BCC";
    document.getElementById("creditt").style.color = "#fff";
    // document.getElementById("debit").style.background = "#fff";
    // document.getElementById("debitt").style.color = "#000";
    document.getElementById("one").style.background = "#fff";
    document.getElementById("onet").style.color = "#000";
    document.getElementById("finance").style.background = "#fff";
    document.getElementById("financet").style.color = "#000";
    setPayment("credit");
  };
  const handlePaymentFinance = () => {
    // setCity('')
    document.getElementById("finance").style.background = "#2A7BCC";
    document.getElementById("financet").style.color = "#fff";
    document.getElementById("credit").style.background = "#fff";
    document.getElementById("creditt").style.color = "#000";
    // document.getElementById("debit").style.background = "#fff";
    // document.getElementById("debitt").style.color = "#000";
    document.getElementById("one").style.background = "#fff";
    document.getElementById("onet").style.color = "#000";
    setPayment("finance");
  };
  const handleClick = () => {
    LoaderOpen();

    axios({
      method: "post",
      url: `${process.env.REACT_APP_NODE_URL}/api/v1/paymentPortal/updateVendorFullPayment`,
      data: {
        type: "update_vendor_full_payment",
        user_id: parseInt(localStorage.getItem("user_id")),
        university: localStorage.getItem("university"),
        email:localStorage.getItem("email")
      },
    })
      .then((res) => {
        // console.log(res,"final")
        LoaderClose();
        if (res.data.statusCode == 200) {
          localStorage.setItem("currentStep", res.data.data.current_step_count);
          localStorage.setItem(
            "currentStepStatus",
            res.data.data.current_step_status
          );
          toast.success(
            "Thanks for submitting your request. We will get back to you for further process",
            {
              position: "top-right",
              autoClose: 4000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "colored",
            }
          );
          setTimeout(() => {
            setShow(true);
          }, 4000);
        }
        else{
          Swal.fire({
            title: "Oops!",
            text: props.elseErrormessage,
            icon: "error",
            confirmButtonText: "OK",
          });
        }
      })
      .catch((err) => {
        LoaderClose();
        Swal.fire({
          title: "Oops!",
          text: props.catchErrorMessage,
          icon: "error",
          confirmButtonText: "OK",
        });
      });
    //   }

    // });
  };
  const handleClickChange = () => {
    LoaderOpen();

    axios({
      method: "post",
      url: `${process.env.REACT_APP_NODE_URL}/api/v1/paymentPortal/updateChoiceFullPayment`,
      data: {
        type: "update_choice_full_payment",
        user_id: parseInt(localStorage.getItem("user_id")),
        university: localStorage.getItem("university"),
        email:localStorage.getItem("email")
      },
    })
      .then((res) => {
        if (res.data.statusCode == 200) {
          // setpaymentChange(true)
          LoaderClose();
          setShow(false);
          setChecked(!checked);
          localStorage.setItem("currentStep", res.data.data.current_step_count);
          localStorage.setItem(
            "currentStepStatus",
            res.data.data.current_step_status
          );
        }
        else{
          LoaderClose();
          Swal.fire({
            title: "Oops!",
            text: props.elseErrormessage,
            icon: "error",
            confirmButtonText: "OK",
          });
        }
      })
      .catch((err) => {
        LoaderClose();
        Swal.fire({
          title: "Oops!",
          text: props.catchErrorMessage,
          icon: "error",
          confirmButtonText: "OK",
        });
      });
  };
  const paymentChange = (e) => {
    if (e.target.value > 30000) {
      setAddmissionpayment(e.target.value);
      setError(false);
    } else {
      setError(true);
    }
  };

  const fetchone = () => {
    axios({
      method: "post",
      url: `${process.env.REACT_APP_NODE_URL}/api/v1/paymentPortal/fetchProgramsAndBatches`,
      data: {
        type: "fetch_programs_and_batches",
        category: localStorage.getItem("category"),
        email:localStorage.getItem("email")
      },
    })
      .then((response) => {
        // console.log(response,"see")
        if (response.data.statusCode == 200) {
          if (response.data.data.batches[0].status == 200) {
            setBatch(response.data.data.batches);
            setBatchid(response.data.data.batches[0].batch_id);
            setBatchtxt(response.data.data.batches[0].batch_text);
          }
          if (response.data.data.programs) {
            setCourse(response.data.data.programs);
            setProgram(response.data.data.programs[0].pname);
            setCoursescode(response.data.data.programs[0].pcode);
            setCourses(response.data.data.programs[0].pid);
          }
        }
        else{
          Swal.fire({
            title: "Oops!",
            text: props.elseErrormessage,
            icon: "error",
            confirmButtonText: "OK",
          });
        }
      })
      .catch((err) => {
        Swal.fire({
          title: "Oops!",
          text: props.catchErrorMessage,
          icon: "error",
          confirmButtonText: "OK",
        });
      });
  };
  useEffect(() => {
    fetchone();
  }, []);
  const Refresh = () => {
    try {
      axios({
        method: "post",
        url: `${process.env.REACT_APP_NODE_URL}/api/v1/paymentPortal/getUserBasicDetails`,
        data: {
          type: "get_user_basic_details",
          user_id: localStorage.getItem("user_id"),
          university: localStorage.getItem("university"),
          email:localStorage.getItem("email")
        },
      })
        .then(function (response) {
          if (response.data.statusCode == 200) {
            if (
              localStorage.getItem("currentStep") ==
              response.data.data[0].current_step_count
            ) {
              Swal.fire({
                title: "Payment in process",
                text: "Your payment is still being processed. Please contact the admission counsellor to check the status of your payment",
                icon: "info",
                confirmButtonText: "OK",
              });
            } else {
              Swal.fire({
                title: "Success",
                text: "Your payment has been captured successfully. Please click on the 'Next' button below to proceed to the next step.",
                icon: "success",
                confirmButtonText: "Next",
              }).then((result) => {
                if (result.isConfirmed) {
                  localStorage.setItem(
                    "currentStep",
                    response.data.data[0].current_step_count
                  );
                  localStorage.setItem(
                    "currentStepStatus",
                    response.data.data[0].current_step_status
                  );
                  window.location.reload();
                }
              });
            }
          } else {
            Swal.fire({
              title: "Oops!",
              text: props.elseErrormessage,
              icon: "error",
              confirmButtonText: "OK",
            });
          }
        })
        .catch((err) => {
          Swal.fire({
            title: "Oops!",
            text: props.catchErrorMessage,
            icon: "error",
            confirmButtonText: "OK",
          });
        });
    } catch {
      console.log("refresh api not invoked");
    }
  };

  const InsertintoPubsub = (payment_id) => {
    if (value === 0) {
      LoaderOpen();
      localStorage.setItem("selectedProgram", program_name);
      localStorage.setItem("selectedProgram_id", courses);
      localStorage.setItem("selectedbatch_id", batchid);
      localStorage.setItem("selectedbatch_name", batchtext);
      localStorage.setItem("selectedProgram_code", coursescode);
      axios({
        method: "post",
        url: `${process.env.REACT_APP_NODE_URL}/api/v1/paymentPortal/updateRegistrationStep`,
        data: {
          type: "insert_application_details_pubsub",
          user_id: parseInt(localStorage.getItem("user_id")),
          payment_id: payment_id,
          firstname: localStorage.getItem("firstname"),
          lastname: localStorage.getItem("lastname"),
          mobile: localStorage.getItem("mobile"),
          email: localStorage.getItem("email"),
          batch: batchid,
          university: localStorage.getItem("university"),
          program: courses,
          amount: props.reg_amount * 100,
          category: localStorage.getItem("category"),
          // pname:course[0].pcode,
          // register_id:parseInt(batch[0].register_id)
        },
      })
        .then((response) => {
          if (response.data.statusCode == 200) {
            props.handleNext2();
            localStorage.setItem(
              "currentStep",
              response.data.data.current_step_count
            );
            localStorage.setItem(
              "currentStepStatus",
              response.data.data.current_step_status
            );
            LoaderClose();
          } else {
            LoaderClose();
            triggerPaymentInprocess(
              payment_id,
              "registration payment api response code is not 200"
            );
            setPaymentState(true);
          }
        })
        .catch((err) => {
          LoaderClose();
          triggerPaymentInprocess(
            payment_id,
            "registration payment api response failed"
          );
          setPaymentState(true);
        });
    } else {
      LoaderOpen();
      axios({
        method: "post",
        url: `${process.env.REACT_APP_NODE_URL}/api/v1/paymentPortal/updateFullPaymentStep`,
        data: {
          type: "insert_full_payment_details_pubsub",
          user_id: parseInt(localStorage.getItem("user_id")),
          payment_id: payment_id,
          firstname: localStorage.getItem("firstname"),
          lastname: localStorage.getItem("lastname"),
          mobile: localStorage.getItem("mobile"),
          email: localStorage.getItem("email"),
          batch: batchid,
          program: courses,
          university: localStorage.getItem("university"),
          amount: addmissionPayment * 100,
          category: localStorage.getItem("category"),
        },
      })
        .then((response) => {
          if (response.data.statusCode == 200) {
            LoaderClose();
            props.handleNext2();
            props.handleNext3();
            localStorage.setItem(
              "currentStep",
              response.data.data.current_step_count
            );
            localStorage.setItem(
              "currentStepStatus",
              response.data.data.current_step_status
            );
          } else {
            LoaderClose();
            triggerPaymentInprocess(
              payment_id,
              "full payment pubsub payment api response code is not 200"
            );
            setPaymentState(true);
          }
        })
        .catch((err) => {
          LoaderClose();
          triggerPaymentInprocess(
            payment_id,
            "full payment pubsub payment api failed"
          );
          setPaymentState(true);
        });
    }
  };
  const triggerPaymentInprocess = (payment_id, message) => {
    // LoaderOpen()

    axios({
      method: "post",
      url: `${process.env.REACT_APP_NODE_URL}/api/v1/paymentPortal/updateGmApplicationPayment`,
      data: {
        // type: "update_vendor_full_payment",
        user_id: parseInt(localStorage.getItem("user_id")),
        university: localStorage.getItem("university"),
        payment_id: payment_id,
        message: message,
        step_name: "registration",
        email:localStorage.getItem("email")
      },
    })
      .then((res) => {
        if (res.data.statusCode == 200) {
          localStorage.setItem("currentStep", res.data.data.current_step_count);
          localStorage.setItem(
            "currentStepStatus",
            res.data.data.current_step_status
          );
          setPaymentState(true);
        }
        else{
          Swal.fire({
            title: "Oops!",
            text: props.elseErrormessage,
            icon: "error",
            confirmButtonText: "OK",
          });
        }
      })
      .catch((err) => {
        Swal.fire({
          title: "Oops!",
          text: props.catchErrorMessage,
          icon: "error",
          confirmButtonText: "OK",
        });
      });
  };

  const checkoutHandler = async (amount) => {
    try {
      const orderResponse = await axios({
        method: "post",
        url: `${process.env.REACT_APP_CLOUD_FUNCTION_URL}/razorpay-create-order-v1`,
        data: {
          amount: amount,
          user_id: parseInt(localStorage.getItem("user_id")),
          firstname: localStorage.getItem("firstname"),
          lastname: localStorage.getItem("lastname"),
          mobile: localStorage.getItem("mobile"),
          email: localStorage.getItem("email"),
          batch: batchid,
          university: localStorage.getItem("university"),
          program: courses,
          category: localStorage.getItem("category"),
          step: amount > 11800 ? "fullPayment" : "registration",
          type: "create_order",
        },
      }).catch((err) => {
        Swal.fire({
          title: "Oops!",
          text: "We apologize for the inconvenience. Our system is currently experiencing heavy load. Please try again after some time. Thank you for your patience.",
          icon: "warning",
          confirmButtonText: "OK",
        });
      });
      if (orderResponse.data.statusCode == 200) {
        if (orderResponse.data.data.id) {
          const options = {
            key: process.env.REACT_APP_RAZORPAY_KEY,
            amount: amount * 100,
            currency: "INR",
            name: "Accredian",
            description: "registration fees for " + coursescode,
            image: "../../images/accredian-logo.png",
            order_id: orderResponse.data.data.id,

            handler: async function (response) {
              if (response) {
                setPaymentid(response.razorpay_payment_id);
                axios({
                  method: "post",
                  url: `${process.env.REACT_APP_CLOUD_FUNCTION_URL}/razorpay-create-order-v1`,
                  data: {
                    order_id: orderResponse.data.data.id,
                    payment_id: response.razorpay_payment_id,
                    signature: response.razorpay_signature,
                    email: localStorage.getItem("email"),
                    type: "verify_payment",
                  },
                })
                  .then((res) => {
                    if (res.data.statusCode == 200) {
                      InsertintoPubsub(response.razorpay_payment_id);
                    } else {
                      triggerPaymentInprocess(
                        response.razorpay_payment_id,
                        "payment signature not verified"
                      );
                      setPaymentState(true);
                    }
                  })
                  .catch((err) => {
                    triggerPaymentInprocess(
                      response.razorpay_payment_id,
                      "capturePayment api error"
                    );
                    setPaymentState(true);
                  });
              } else {
                triggerPaymentInprocess(
                  response.razorpay_payment_id,
                  "razorpay response not received"
                );
                setPaymentState(true);
              }
            },
            prefill: {
              name:
                localStorage.getItem("firstname") +
                localStorage.getItem("lastname"),
              email: localStorage.getItem("email"),
              contact: localStorage.getItem("mobile"),
            },
            notes: {
              address: "Razorpay Corporate Office",
            },
            theme: {
              color: "#121212",
            },
          };
          const razor = new window.Razorpay(options);
          razor.on("payment.failed", function (response) {
            console.log(response.error.code);
            console.log(response.error.description);
            console.log(response.error.source);
            console.log(response.error.step);
            console.log(response.error.reason);
            console.log(response.error.metadata.order_id);
            console.log(response.error.metadata.payment_id);
          });
          razor.on("payment.authorized", function (response) {
            triggerPaymentInprocess(
              response.razorpay_payment_id,
              "payment is in authorized state"
            );
            setPaymentState(true);
          });
          razor.open();
        } else {
          Swal.fire({
            title: "Oops!",
            text: "Apologies for any inconvenience. Our system is experiencing high demand, causing delays. Please retry later. Your patience is appreciated.",
            icon: "warning",
            confirmButtonText: "OK",
          });
        }
      } else {
        Swal.fire({
          title: "Oops!",
          text: "We apologize for the inconvenience. Our system is currently experiencing heavy load. Please try again after some time. Thank you for your patience.",
          icon: "warning",
          confirmButtonText: "OK",
        });
      }
    } catch {
      console.log("handle function not called");
    }
  };

  return (
    <>
      <Box
        sx={{ display: { xs: "none", lg: "block", sm: "none", md: "none" } }}
      >
        <Box
          sx={{
            display: "grid",
            placeItems: "center",
            p: 3,
            width: "95%",
            borderRadius: "15px",

            overflowY: "scroll",
            height: 350,
            "&::-webkit-scrollbar": {
              width: "0.2em",
            },
            "&::-webkit-scrollbar-track": {
              boxShadow: "inset 0 0 6px silver",
              webkitBoxShadow: "inset 0 0 6px silver",
            },
            "&::-webkit-scrollbar-thumb": {
              backgroundColor: "silver",
              outline: "1px solid silver",
            },
          }}
        >
          <Box
            sx={{
              borderRadius: "8px",
              p: 1.5,
              background: "#fff",
              backgroundColor: "rgba(255, 255, 255, .60)",
              // pl: 4,
              // pr: 4,

              ml: 5,
              backdropFilter: "blur(5px)",
              // display: "grid",
              placeContent: "center",
              border: "1px solid #002E5C",
              width: "82%",
            }}
          >
            <Box sx={{ display: "grid", placeItems: "center" }}>
              {/* <Box sx={{display:"flex"}}> */}
              <Typography
                sx={{
                  color: "red",
                  animation: "blink 1s infinite ",
                  "@keyframes blink": {
                    "0%, 100%": {
                      opacity: 1,
                      transform: "scale(1)",
                    },
                    "50%": {
                      opacity: 1,
                      transform: "scale(1.1)",
                    },
                  },
                  fontSize: { xs: "14px", lg: "16px" },
                }}
              >
                <i style={{ textAlign: "center" }}>
                  Please select a payment option
                </i>
              </Typography>
              <ArrowDownwardIcon
                sx={{
                  color: "red",
                  mb: 1,
                  animation: "blink 1s infinite ",
                  "@keyframes blink": {
                    "0%, 100%": {
                      opacity: 1,
                      transform: "scale(1)",
                    },
                    "50%": {
                      opacity: 1,
                      transform: "scale(1.1)",
                    },
                  },
                }}
              />
              <Box
                sx={{
                  border: "2px solid black",
                  width: { xs: 220, lg: "fit-content" },
                  borderRadius: { xs: "7px", lg: "9px" },
                  height: "41px",
                }}
              >
                <Tabs
                  orientation="horizontal"
                  onChange={handleChange}
                  value={value}
                  centered
                  sx={{
                    //  border:"2px solid black",
                    "& button": {
                      borderRadius: 1,
                      color: "#000 !important",
                      minHeight: "fit-content !important",
                      textTransform: "initial !important",
                    },
                    "& button.Mui-selected": {
                      backgroundColor: "#2A7BCC !important",
                      color: "#fff!important",
                      borderRadius: "8px",
                      // width: "100%",
                    },
                    "& span.MuiTabs-indicator": {
                      display: "none",
                    },
                  }}
                >
                  <Tab
                    value={0}
                    sx={{ textAlign: "left" }}
                    label="Registration"
                  ></Tab>
                  <Tab
                    onClick={full_paymentClick}
                    value={1}
                    sx={{ textAlign: "left" }}
                    label="Full Payment"
                  ></Tab>
                </Tabs>
              </Box>
              {/* </Box> */}
            </Box>
            {/* <Typography sx={{fontWeight:"bold",fontSize:"20px",textAlign:"left",mb:2}}>Application Fee</Typography> */}
            {value === 0 && (
              <Box>
                {localStorage.getItem("currentStepStatus") == "pending" &&
                showPaymentState == false ? (
                  <Box>
                    <Box sx={{ p: 1.5, px: 5 }}>
                      <Box
                        sx={{
                          mb: 1,
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                        //   onClick={handleClickPersonal}
                      >
                        {/* {openPersonal ? <ExpandLess sx={{color:"#fff"}} /> : <ExpandMore sx={{color:"#fff"}} />} */}
                        {/* <ArrowBackIcon sx={{cursor:"pointer"}} onClick={handleBack}/> */}
                        <Typography
                          sx={{
                            color: "#000",
                            fontWeight: "bold",
                            textAlign: "center",
                            fontSize: "23px",
                            // ml:43
                          }}
                        >
                          Application Fee
                        </Typography>
                      </Box>
                      {/* 
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                mt: 1,
                mb: 1,
              }}
            >
              <Box>
                <Typography
                  sx={{
                    fontWeight: "bold",
                    fontSize: "14px",
                    color: "#000",
                    textAlign: "left",
                  }}
                >
                  First Name
                </Typography>
                <BootstrapInput
                  sx={{ width: 480 }}
                  size="small"
                  inputProps={{ readOnly: true }}
                  value={localStorage.getItem("firstname")}
                />
              </Box>
              <Box>
                <Typography
                  sx={{
                    fontWeight: "bold",
                    fontSize: "14px",
                    color: "#000",
                    textAlign: "left",
                  }}
                >
                  Middle Name
                </Typography>
                <BootstrapInput
                  // sx={{ width: 480 }}
                  size="small"
                  inputProps={{ readOnly: true }}
                  value={localStorage.getItem("middlename")}
                />
              </Box>
            </Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                mt: 1,
                mb: 1,
              }}
            >
              <Box>
                <Typography
                  sx={{
                    fontWeight: "bold",
                    fontSize: "14px",
                    color: "#000",
                    textAlign: "left",
                  }}
                >
                  Last Name
                </Typography>
                <BootstrapInput
                  sx={{ width: 480 }}
                  size="small"
                  inputProps={{ readOnly: true }}
                  value={localStorage.getItem("lastname")}
                />
              </Box>
              <Box>
                <Typography
                  sx={{
                    fontWeight: "bold",
                    fontSize: "14px",
                    color: "#000",
                    textAlign: "left",
                  }}
                >
                  Phone Number
                </Typography>
                <BootstrapInput
                  type="number"
                  // sx={{ width: 480 }}
                  size="small"
                  inputProps={{ readOnly: true }}
                  value={localStorage.getItem("mobile")}
                />
              </Box>
            </Box> */}
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                          mt: 1,
                          mb: 1,
                        }}
                      >
                        <Box>
                          <Typography
                            sx={{
                              fontWeight: "bold",
                              fontSize: "14px",
                              color: "#000",
                              textAlign: "left",
                            }}
                          >
                            Email ID <span style={{ color: "red" }}>*</span>
                          </Typography>
                          <TextField
                            type="email"
                            sx={{
                              width: 420,
                              background: "rgb(217, 217, 217)",
                              borderRadius: "5px",
                            }}
                            size="small"
                            inputProps={{ readOnly: true }}
                            value={localStorage.getItem("email")}
                          />
                        </Box>
                        <Box>
                          <Typography
                            sx={{
                              fontWeight: "bold",
                              fontSize: "14px",
                              color: "#000",
                              textAlign: "left",
                            }}
                          >
                            Program <span style={{ color: "red" }}>*</span>
                          </Typography>
                          {course.length == 1 ? (
                            <TextField
                              // type="email"
                              sx={{
                                width: 425,
                                borderRadius: "5px",
                                background: "#d9d9d9",
                              }}
                              size="small"
                              inputProps={{ readOnly: true }}
                              value={program_name}
                            />
                          ) : (
                            <FormControl
                              fullWidth
                              sx={{
                                boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
                              }}
                            >
                              <Select
                                size="small"
                                sx={{
                                  width: 425,
                                  background: "#fff",
                                  textAlign: "left",
                                }}
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                defaultValue={"Select the Program"}

                                // label="course"
                                // onChange={handleChangecourse}
                              >
                                <MenuItem disabled value="Select the Program">
                                  <i>Select the Program</i>
                                </MenuItem>
                                {course.map((val) => (
                                  <MenuItem
                                    key={val.pid}
                                    value={val.pid}
                                    onClick={() =>
                                      handleChangecourse(
                                        val.pcode,
                                        val.pid,
                                        val.pname
                                      )
                                    }
                                  >
                                    {val.pname}
                                  </MenuItem>
                                ))}
                              </Select>
                            </FormControl>
                          )}
                          {/* <TextField
                              // type="email"
                              sx={{ width: 425,borderRadius:"5px",background:"#fff" }}
                              size="small"
                              inputProps={{ readOnly: true }}
                              value={program_name}
                            /> */}
                        </Box>
                      </Box>
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                          mt: 1,
                          mb: 1,
                        }}
                      >
                        <Box>
                          <Typography
                            sx={{
                              fontWeight: "bold",
                              fontSize: "14px",
                              color: "#000",
                              textAlign: "left",
                            }}
                          >
                            Application Fee{" "}
                            <span style={{ color: "red" }}>*</span>
                          </Typography>
                          <TextField
                            sx={{
                              width: 420,
                              background: "rgb(217, 217, 217)",
                              borderRadius: "5px",
                            }}
                            size="small"
                            inputProps={{ readOnly: true }}
                            // defaultValue={`INR${amount}`}
                            value={`INR 10,000 + GST`}
                          />
                        </Box>
                        <Box sx={{ mb: 1 }}>
                          <Typography
                            sx={{
                              fontWeight: "bold",
                              fontSize: "14px",
                              color: "#000",
                              textAlign: "left",
                            }}
                          >
                            Batch <span style={{ color: "red" }}>*</span>
                          </Typography>
                          
                {batch.length==1?(
                     <TextField
                     // type="email"
                     sx={{
                       width: 425,
                       borderRadius: "5px",
                       background: "rgb(217, 217, 217)",
                     }}
                     size="small"
                     inputProps={{ readOnly: true }}
                     value={batchtext}
                   />
                ):(
                  <FormControl fullWidth sx={{boxShadow:"0px 4px 4px rgba(0, 0, 0, 0.25)"}}>
                  
                  <Select
                    size="small"
                    sx={{ width: 425, background: "#fff", textAlign: "left" }}
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    // value={batchs}
                    defaultValue="Select the Batch"
                    // label="batch"
                   
                  >
                     <MenuItem value="Select the Batch">
                    <i>Select the Batch</i>
                    </MenuItem>
                    {batch.map((val, i) => (
                      <MenuItem  onClick={() => handleChangebatch(val.register_id,val.batch_id,val.batch_text,val.batch)} value={val.batch_id}>{val.batch_text}</MenuItem>
                    ))}
                  </Select>
                </FormControl>
                )}
                         
                        </Box>
                      </Box>
                    </Box>

                    <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
                      {/* <Typography
              sx={{
                fontWeight: "bold",
                fontSize: "14px",
                color: "#fff",
                mb: 1,
              }}
            >
              <i>Please make the payment below</i>
            </Typography> */}

                      <Button
                        // variant="conatained"
                        // size="small"
                        // onClick={()=>{props.handleNext()}}

                        sx={{
                          background: "#002E5C",
                          textTransform: "none",
                          color: "#fff",
                          "&:hover": { background: "#002E5C", color: "#fff" },
                          cursor: "pointer",
                        }}
                        onClick={() => {
                          checkoutHandler(props.reg_amount);
                        }}
                        variant="contained"
                        size="small"
                        width="150"
                        height="80"
                        disabled={nxt}
                      >
                        Pay Now
                      </Button>
                    </Box>
                  </Box>
                ) : (
                  <Box>
                    {localStorage.getItem("currentStepStatus") ==
                      "payment_inprocess" || showPaymentState ? (
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <Box
                          sx={{
                            background: "#fff",
                            borderRadius: "5px",
                            border: "1px solid #2A7BCC",
                            p: 3,
                            width: 300,
                            mt: 3,
                          }}
                        >
                          <BorderLinearProgress
                            variant="determinate"
                            value={80}
                            sx={{ mb: 2 }}
                          />
                          <Typography sx={{ mb: 1.5 }}>
                            We are currently processing your payment. Kindly
                            check your payment status after 30 minutes. For
                            additional details, please reach out to your
                            admission counsellor.
                          </Typography>
                          <Box
                            sx={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                          >
                            <Button
                              onClick={Refresh}
                              variant="conatained"
                              size="small"
                              sx={{
                                textAlign: "center",
                                color: "#fff",
                                textTransform: "none",
                                background: "#2A7BCC",
                                "&:hover": {
                                  background: "#2A7BCC",
                                  color: "#fff",
                                },
                              }}
                              // onClick={handleChangePayment}
                            >
                              Check Status
                            </Button>
                          </Box>
                        </Box>
                      </Box>
                    ) : (
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <Box
                          sx={{
                            background: "#fff",
                            borderRadius: "5px",
                            border: "1px solid #2A7BCC",
                            p: 3,
                            width: 350,
                            mt: 3,
                          }}
                        >
                          <BorderLinearProgress
                            variant="determinate"
                            value={80}
                            sx={{ mb: 2 }}
                          />
                          <Typography sx={{ mb: 2.5 }}>
                            Your payment is under process. Please contact your
                            admission counsellor for further details
                          </Typography>
                          <Box
                            sx={{
                              display: "flex",
                              justifyContent: "space-between",
                              alignItems: "space-between",
                            }}
                          >
                            <Button
                              onClick={handleClickChange}
                              variant="conatained"
                              size="small"
                              sx={{
                                textAlign: "center",
                                color: "#fff",
                                width: "160px",
                                height: "40px",
                                textTransform: "none",
                                background: "#2A7BCC",
                                "&:hover": {
                                  background: "#2A7BCC",
                                  color: "#fff",
                                  width: "160px",
                                  height: "40px",
                                },
                              }}
                              // onClick={handleChangePayment}
                            >
                              Switch Pay Method
                            </Button>
                            <Button
                              onClick={Refresh}
                              variant="conatained"
                              size="small"
                              sx={{
                                textAlign: "center",
                                color: "#fff",
                                width: "160px",
                                height: "40px",
                                textTransform: "none",
                                background: "#2A7BCC",
                                "&:hover": {
                                  background: "#2A7BCC",
                                  color: "#fff",
                                  width: "160px",
                                  height: "40px",
                                },
                              }}
                              // onClick={handleChangePayment}
                            >
                              Check Loan Status
                            </Button>
                          </Box>
                        </Box>
                      </Box>
                    )}
                  </Box>
                )}
              </Box>
            )}
            {value === 1 && (
              <Box>
                {!show &&
                localStorage.getItem("currentStepStatus") == "pending" &&
                showPaymentState == false ? (
                  <Box sx={{ p: 1.5 }}>
                    <Box sx={{ mb: 3 }}>
                      <Typography
                        sx={{
                          color: "#000",
                          fontWeight: "bold",
                          textAlign: "center",
                          fontSize: "23px",
                        }}
                      >
                        Select Your Payment Method
                      </Typography>
                    </Box>

                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-evenly",

                        mt: 1,
                        mb: 3,
                      }}
                    >
                      <Box
                        id="one"
                        sx={{
                          p: 2,
                          border: "1px solid #2A7BCC",
                          borderRadius: "7px",
                          background: "#fff",
                          cursor: "pointer",
                        }}
                        onClick={handlePayment}
                      >
                        <Typography
                          id="onet"
                          sx={{
                            fontWeight: "bold",
                            fontSize: "14px",
                            color: "#000",
                            textAlign: "left",
                          }}
                        >
                          One time payment
                        </Typography>
                        {/* <BootstrapInput
  sx={{ width: 480 }}
  size="small"
  inputProps={{ readOnly: true }}
  value={localStorage.getItem("firstname")}
/> */}
                      </Box>
                      <Box
                        id="credit"
                        sx={{
                          p: 2,
                          border: "1px solid #2A7BCC",
                          borderRadius: "5px",
                          background: "#fff",
                          cursor: "pointer",
                        }}
                        onClick={handlePaymentCredit}
                      >
                        <Typography
                          id="creditt"
                          sx={{
                            fontWeight: "bold",
                            fontSize: "14px",
                            color: "#000",
                            textAlign: "left",
                          }}
                        >
                          Credit Card EMI
                        </Typography>
                        {/* <BootstrapInput
  sx={{ width: 480 }}
  size="small"
  inputProps={{ readOnly: true }}
  value={localStorage.getItem("middlename")}
/> */}
                      </Box>
                      {/* <Box
      id="debit"
      sx={{
        p: 2,
        border: "1px solid #2A7BCC",
        borderRadius: "5px",
        background: "#fff",
        cursor: "pointer",
      }}
      onClick={handlePaymentDebit}
    >
      <Typography
        id="debitt"
        sx={{
          fontWeight: "bold",
          fontSize: "14px",
          color: "#000",
          textAlign: "left",
        }}
      >
        Debit Card EMI
      </Typography>

    </Box> */}
                      <Box
                        id="finance"
                        sx={{
                          p: 2,
                          border: "1px solid #2A7BCC",
                          borderRadius: "5px",
                          background: "#fff",
                          cursor: "pointer",
                        }}
                        onClick={handlePaymentFinance}
                      >
                        <Typography
                          id="financet"
                          sx={{
                            fontWeight: "bold",
                            fontSize: "14px",
                            color: "#000",
                            textAlign: "left",
                          }}
                        >
                          Finance partner
                        </Typography>
                        {/* <BootstrapInput
  sx={{ width: 480 }}
  size="small"
  inputProps={{ readOnly: true }}
  value={localStorage.getItem("firstname")}
/> */}
                      </Box>
                    </Box>
                    {/* <Box
sx={{
display: "flex",
justifyContent: "space-between",
mt: 1,
mb: 1,
}}
>



</Box> */}
                    {payment == "" ? (
                      <Box></Box>
                    ) : (
                      <Box>
                        {!(payment == "finance") ? (
                          <Box>
                            <Box
                              sx={{
                                display: "flex",
                                justifyContent: "space-between",
                                mt: 1,
                                mb: 1,
                              }}
                            >
                              <Box>
                                <Typography
                                  sx={{
                                    fontWeight: "bold",
                                    fontSize: "14px",
                                    color: "#000",
                                    textAlign: "left",
                                  }}
                                >
                                  Email ID{" "}
                                  <span style={{ color: "red" }}>*</span>
                                </Typography>
                                <TextField
                                  type="email"
                                  sx={{
                                    width: 425,
                                    borderRadius: "5px",
                                    background: "rgb(217, 217, 217)",
                                  }}
                                  size="small"
                                  inputProps={{ readOnly: true }}
                                  value={localStorage.getItem("email")}
                                />
                              </Box>
                              <Box>
                                <Typography
                                  sx={{
                                    fontWeight: "bold",
                                    fontSize: "14px",
                                    color: "#000",
                                    textAlign: "left",
                                  }}
                                >
                                  Program{" "}
                                  <span style={{ color: "red" }}>*</span>
                                </Typography>
                                {/* <TextField
                // type="email"
                sx={{ width: 425,borderRadius:"5px",background:"#fff" }}
                size="small"
                inputProps={{ readOnly: true }}
                value={program_name}
              /> */}
                                {course.length == 1 ? (
                                  <TextField
                                    // type="email"
                                    sx={{
                                      width: 425,
                                      borderRadius: "5px",
                                      background: "#d9d9d9",
                                    }}
                                    size="small"
                                    inputProps={{ readOnly: true }}
                                    value={program_name}
                                  />
                                ) : (
                                  <FormControl
                                    fullWidth
                                    sx={{
                                      boxShadow:
                                        "0px 4px 4px rgba(0, 0, 0, 0.25)",
                                    }}
                                  >
                                    <Select
                                      size="small"
                                      sx={{
                                        width: 425,
                                        background: "#fff",
                                        textAlign: "left",
                                      }}
                                      labelId="demo-simple-select-label"
                                      id="demo-simple-select"
                                      defaultValue={"Select the Program"}

                                      // label="course"
                                      // onChange={handleChangecourse}
                                    >
                                      <MenuItem
                                        disabled
                                        value="Select the Program"
                                      >
                                        <i>Select the Program</i>
                                      </MenuItem>
                                      {course.map((val) => (
                                        <MenuItem
                                          key={val.pid}
                                          value={val.pid}
                                          onClick={() =>
                                            handleChangecourse(
                                              val.pcode,
                                              val.pid,
                                              val.pname
                                            )
                                          }
                                        >
                                          {val.pname}
                                        </MenuItem>
                                      ))}
                                    </Select>
                                  </FormControl>
                                )}
                                {/* <FormControl fullWidth>
                <Select
                  size="small"
                  sx={{
                    width: 428,
                    background: "#fff",
                    textAlign: "left",
                  }}
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  // value={country}
                  defaultValue="38"
                  label="course"
                  // onChange={handleChangeCountry}
                >
                  {course.map((val) => (
                    <MenuItem
                      key={val.pid}
                      value={val.pid}
                      onClick={() =>
                        handleChangecourse(val.pcode, val.pid)
                      }
                    >
                      {val.pname}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl> */}
                              </Box>
                            </Box>
                            <Box
                              sx={{
                                display: "flex",
                                justifyContent: "space-between",
                                mt: 1,
                                mb: 1,
                              }}
                            >
                              <Box>
                                <Typography
                                  sx={{
                                    fontWeight: "bold",
                                    fontSize: "14px",
                                    color: "#000",
                                    textAlign: "left",
                                  }}
                                >
                                  Admission Fee{" "}
                                  <span style={{ color: "red" }}>*</span>
                                </Typography>
                                <TextField
                                  type="number"
                                  sx={{
                                    width: 425,
                                    background: "#fff",
                                    borderRadius: "6px",
                                  }}
                                  size="small"
                                  error={error}
                                  helperText={
                                    error ? "Please Enter a valid Amount" : ""
                                  }
                                  // inputProps={{ readOnly: true }}
                                  onChange={paymentChange}
                                />
                              </Box>
                              <Box sx={{ mb: 1 }}>
                                <Typography
                                  sx={{
                                    fontWeight: "bold",
                                    fontSize: "14px",
                                    color: "#000",
                                    textAlign: "left",
                                  }}
                                >
                                  Batch <span style={{ color: "red" }}>*</span>
                                </Typography>
                                {batch.length==1?(
                     <TextField
                     // type="email"
                     sx={{
                       width: 425,
                       borderRadius: "5px",
                       background: "rgb(217, 217, 217)",
                     }}
                     size="small"
                     inputProps={{ readOnly: true }}
                     value={batchtext}
                   />
                ):(
                  <FormControl fullWidth sx={{boxShadow:"0px 4px 4px rgba(0, 0, 0, 0.25)"}}>
                  
                  <Select
                    size="small"
                    sx={{ width: 425, background: "#fff", textAlign: "left" }}
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    // value={batchs}
                    defaultValue="Select the Batch"
                    // label="batch"
                   
                  >
                     <MenuItem value="Select the Batch">
                    <i>Select the Batch</i>
                    </MenuItem>
                    {batch.map((val, i) => (
                      <MenuItem  onClick={() => handleChangebatch(val.register_id,val.batch_id,val.batch_text,val.batch)} value={val.batch_id}>{val.batch_text}</MenuItem>
                    ))}
                  </Select>
                </FormControl>
                )}
                                {/* <TextField
                                  // type="email"
                                  sx={{
                                    width: 425,
                                    borderRadius: "5px",
                                    background: "rgb(217, 217, 217)",
                                  }}
                                  size="small"
                                  inputProps={{ readOnly: true }}
                                  value={batchtext}
                                /> */}
                                {/* <FormControl fullWidth>
               
                <Select
                  size="small"
                  sx={{
                    width: 428,
                    background: "#fff",
                    textAlign: "left",
                  }}
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={batchs}
                  // defaultValue="2023-06-01"
                  // label="batch"
                  onChange={handleChangebatch}
                >
                  {batch.map((val, i) => (
                    <MenuItem value={val.batch_id}>
                      {val.batch_text}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl> */}
                              </Box>
                            </Box>

                            {/* <Box
sx={{ mb: 0.5, p: 1, borderRadius: "5px" }}
// onClick={handleClickaddress}
>

<Typography
  sx={{
    color: "#000",
    fontWeight: "bold",
    textAlign: "center",
    fontSize: "23px",
  }}
>
  Address Details
</Typography>
</Box>

<Box
sx={{
  display: "flex",
  justifyContent: "space-between",
  mt: 1,
  mb: 1,
}}
>
<Box>
  <Typography
    sx={{
      fontWeight: "bold",
      fontSize: "14px",
      color: "#000",
      textAlign: "left",
    }}
  >
    Country <span style={{ color: "red" }}>*</span>{" "}
  </Typography>
  <FormControl fullWidth sx={{boxShadow:"0px 4px 4px rgba(0, 0, 0, 0.25)"}}>
    
    <Select
      size="small"
      sx={{ width: 427, background: "#fff", textAlign: "left" }}
      labelId="demo-simple-select-label"
      id="demo-simple-select"
      value={country}
      // defaultValue={countryCode}
      label="Gender"
      onChange={handleChangeCountry}
      
    >
      {Country.getAllCountries().map((val, i) => (
        <MenuItem value={val.isoCode}>{val.name}</MenuItem>
      ))}
    </Select>
  </FormControl>
</Box>
<Box>
  <Typography
    sx={{
      fontWeight: "bold",
      fontSize: "14px",
      color: "#000",
      textAlign: "left",
    }}
  >
    State <span style={{ color: "red" }}>*</span>
  </Typography>
  <FormControl fullWidth sx={{boxShadow:"0px 4px 4px rgba(0, 0, 0, 0.25)"}}>
    
    <Select
      size="small"
      sx={{ width: 427, background: "#fff", textAlign: "left" }}
      labelId="demo-simple-select-label"
      id="demo-simple-select"
      value={state}
      label="Gender"
      onChange={handleChangeState}
      // renderValue={(selected) => selected.map(obj=> State[obj - 1].value).join(", ")}
    >
      {State.getAllStates()
        .filter((val) => {
          return val.countryCode == country;
        })
        .sort()
        .map((val) => (
          <MenuItem value={val.name}>{val.name}</MenuItem>
        ))}
    </Select>
  </FormControl>
</Box>
</Box>
<Box
sx={{
  display: "flex",
  justifyContent: "space-between",
  mt: 1,
  mb: 1,
}}
>
<Box>
  <Typography
    sx={{
      fontWeight: "bold",
      fontSize: "14px",
      color: "#000",
      textAlign: "left",
    }}
  >
    City <span style={{ color: "red" }}>*</span>
  </Typography>

  <FormControl fullWidth sx={{boxShadow:"0px 4px 4px rgba(0, 0, 0, 0.25)"}}>
    
    <Select
      size="small"
      sx={{ width: 427, background: "#fff", textAlign: "left" }}
      labelId="demo-simple-select-label"
      id="demo-simple-select"
      value={city}
      label="Gender"
      onChange={handleChangeCity}
    >
      {cities.map((val) => (
        <MenuItem value={val.name}>{val.name}</MenuItem>
      ))}
    </Select>
  </FormControl>
</Box>
<Box>
  <Typography
    sx={{
      fontWeight: "bold",
      fontSize: "14px",
      color: "#000",
      textAlign: "left",
    }}
  >
    Pincode <span style={{ color: "red" }}>*</span>
  </Typography>
  <BootstrapInput
    onChange={handleChangePincode}
    value={pincode}
    type="number"
    // sx={{ width: 480 }}
    size="small"
  />
</Box>
</Box>
<Box
sx={{
  display: "flex",
  justifyContent: "space-between",
  mt: 1,
  mb: 1,
}}
>
<Box>
  <Typography
    sx={{
      fontWeight: "bold",
      fontSize: "14px",
      color: "#000",
      textAlign: "left",
    }}
  >
    Address 1<span style={{ color: "red" }}>*</span>
  </Typography>
  <BootstrapInput
    onChange={handleChangeAddress}
    value={address}
    sx={{ width: 480 }}
    size="small"
    type="text"
  />
</Box>
<Box>
  <Typography
    sx={{
      fontWeight: "bold",
      fontSize: "14px",
      color: "#000",
      textAlign: "left",
    }}
  >
    Address 2{" "}
  </Typography>
  <BootstrapInput
    onChange={handleChangeAddresst}
    value={addresstwo}
    // sx={{ width: 480 }}
    size="small"
    type="text"
  />
</Box>
</Box> */}

                            <Box
                              sx={{
                                display: "flex",
                                justifyContent: "flex-end",
                              }}
                            >
                              <Button
                                // variant="conatained"
                                // size="small"
                                disabled={enablePay}
                                sx={{
                                  color: "#fff",
                                  mt: 1,
                                  textTransform: "none",
                                  background: "#002E5C",
                                  "&:hover": {
                                    background: "#002E5C",
                                    color: "#fff",
                                  },
                                }}
                                onClick={() => {
                                  checkoutHandler(addmissionPayment);
                                }}
                                variant="contained"
                                size="small"
                                width="150"
                                height="80"
                              >
                                Pay Now
                              </Button>
                            </Box>
                          </Box>
                        ) : (
                          <Box>
                            <Box
                              sx={{
                                display: { xs: "none", lg: "flex" },
                                justifyContent: "center",
                                alignItems: "center",
                                mt: 4,
                                mb: 2,
                              }}
                            >
                              <Box
                                sx={{
                                  background: "#fff",
                                  borderRadius: "8px",
                                  border: "1px solid #2A7BCC",
                                  px: 1,
                                  pb: 1.5,
                                  width: 450,
                                }}
                              >
                                <Box sx={{ textAlign: "center", my: 1 }}>
                                  {/* <img src={icon} alt="logo"  />  */}

                                  <CardMedia
                                    component="img"
                                    image="../images/icon.png"
                                    sx={{
                                      width: "45px",
                                      margin: "auto",
                                    }}
                                  />
                                </Box>

                                <Typography
                                  sx={{
                                    fontWeight: "300",
                                    fontSize: "16px",
                                    mb: 1,
                                    textAlign: "left",
                                    p: 1,
                                  }}
                                >
                                  Thank you for choosing Finance partner as your
                                  payment option for the program. Please note
                                  that Finance partner is a third-party payment
                                  service provider and their terms and
                                  conditions will apply to your transaction.
                                </Typography>

                                {/* <Typography sx={{fontSize:"10px",textAlign:"left",p:1}}>
I agree to the terms and conditions of the finance partner and provide required documents for loan approval.
</Typography> */}
                                <FormGroup>
                                  {/* <FormControlLabel control={<Checkbox defaultChecked />} label="Label" /> */}
                                  <FormControlLabel
                                    control={
                                      <Checkbox
                                        sx={{ fontSize: "12px" }}
                                        checked={checked}
                                        onChange={handleCheck}
                                      />
                                    }
                                    label={
                                      <div
                                        style={{
                                          fontSize: "12px",
                                          textAlign: "left",
                                        }}
                                      >
                                        <span
                                          style={{
                                            color: "red",
                                            fontSize: "13px",
                                          }}
                                        >
                                          *
                                        </span>{" "}
                                        I agree to the terms and conditions of
                                        the finance partner and provide required
                                        documents for loan approval.
                                      </div>
                                    }
                                  />
                                  {/* <FormControlLabel disabled control={<Checkbox />} label="Disabled" /> */}
                                </FormGroup>
                              </Box>
                            </Box>

                            {/* <Box
sx={{ mb: 0.5, p: 1, borderRadius: "5px" }}

>

<Typography
  sx={{
    color: "#000",
    fontWeight: "bold",
    textAlign: "center",
    fontSize: "23px",
  }}
>
  Address Details
</Typography>
</Box>

<Box
sx={{
  display: "flex",
  justifyContent: "space-between",
  mt: 1,
  mb: 1,
}}
>
<Box>
  <Typography
    sx={{
      fontWeight: "bold",
      fontSize: "14px",
      color: "#000",
      textAlign: "left",
    }}
  >
    Country <span style={{ color: "red" }}>*</span>{" "}
  </Typography>
  <FormControl fullWidth sx={{boxShadow:"0px 4px 4px rgba(0, 0, 0, 0.25)"}}>
   
    <Select
      size="small"
      sx={{ width: 427, background: "#fff", textAlign: "left" }}
      labelId="demo-simple-select-label"
      id="demo-simple-select"
      value={country}
      
      label="Gender"
      onChange={handleChangeCountry}
      
    >
      {Country.getAllCountries().map((val, i) => (
        <MenuItem value={val.isoCode}>{val.name}</MenuItem>
      ))}
    </Select>
  </FormControl>
</Box>
<Box>
  <Typography
    sx={{
      fontWeight: "bold",
      fontSize: "14px",
      color: "#000",
      textAlign: "left",
    }}
  >
    State <span style={{ color: "red" }}>*</span>
  </Typography>
  <FormControl fullWidth sx={{boxShadow:"0px 4px 4px rgba(0, 0, 0, 0.25)"}}>
   
    <Select
      size="small"
      sx={{ width: 427, background: "#fff", textAlign: "left" }}
      labelId="demo-simple-select-label"
      id="demo-simple-select"
      value={state}
      label="Gender"
      onChange={handleChangeState}
     
    >
      {State.getAllStates()
        .filter((val) => {
          return val.countryCode == country;
        })
        .sort()
        .map((val) => (
          <MenuItem value={val.name}>{val.name}</MenuItem>
        ))}
    </Select>
  </FormControl>
</Box>
</Box>
<Box
sx={{
  display: "flex",
  justifyContent: "space-between",
  mt: 1,
  mb: 1,
}}
>
<Box>
  <Typography
    sx={{
      fontWeight: "bold",
      fontSize: "14px",
      color: "#000",
      textAlign: "left",
    }}
  >
    City <span style={{ color: "red" }}>*</span>
  </Typography>

  <FormControl fullWidth sx={{boxShadow:"0px 4px 4px rgba(0, 0, 0, 0.25)"}}>
   
    <Select
      size="small"
      sx={{ width: 427, background: "#fff", textAlign: "left" }}
      labelId="demo-simple-select-label"
      id="demo-simple-select"
      value={city}
      label="Gender"
      onChange={handleChangeCity}
    >
      {cities.map((val) => (
        <MenuItem value={val.name}>{val.name}</MenuItem>
      ))}
    </Select>
  </FormControl>
</Box>
<Box>
  <Typography
    sx={{
      fontWeight: "bold",
      fontSize: "14px",
      color: "#000",
      textAlign: "left",
    }}
  >
    Pincode <span style={{ color: "red" }}>*</span>
  </Typography>
  <BootstrapInput
    onChange={handleChangePincode}
    value={pincode}
    type="number"
  
    size="small"
  />
</Box>
</Box>
<Box
sx={{
  display: "flex",
  justifyContent: "space-between",
  mt: 1,
  mb: 1,
}}
>
<Box>
  <Typography
    sx={{
      fontWeight: "bold",
      fontSize: "14px",
      color: "#000",
      textAlign: "left",
    }}
  >
    Address 1<span style={{ color: "red" }}>*</span>
  </Typography>
  <BootstrapInput
    onChange={handleChangeAddress}
    value={address}
    sx={{ width: 480 }}
    size="small"
    type="text"
  />
</Box>
<Box>
  <Typography
    sx={{
      fontWeight: "bold",
      fontSize: "14px",
      color: "#000",
      textAlign: "left",
    }}
  >
    Address 2{" "}
  </Typography>
  <BootstrapInput
    onChange={handleChangeAddresst}
    value={addresstwo}
   
    size="small"
    type="text"
  />
</Box>
</Box> */}

                            <Box
                              sx={{
                                display: "flex",
                                justifyContent: "flex-end",
                                mt: 2,
                              }}
                            >
                              <Button
                                onClick={handleClick}
                                // variant="conatained"
                                // size="small"
                                disabled={!checked}
                                sx={{
                                  color: "#fff",
                                  textTransform: "none",
                                  // mt: 1,
                                  background: "#002E5C",
                                  "&:hover": {
                                    background: "#002E5C",
                                    color: "#fff",
                                  },
                                }}
                                variant="contained"
                                size="small"
                                width="150"
                                height="80"
                              >
                                Next
                              </Button>
                              {/* </a> */}
                            </Box>
                          </Box>
                        )}
                      </Box>
                    )}
                  </Box>
                ) : (
                  <Box>
                    {localStorage.getItem("currentStepStatus") ==
                      "payment_inprocess" || showPaymentState ? (
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <Box
                          sx={{
                            background: "#fff",
                            borderRadius: "5px",
                            border: "1px solid #2A7BCC",
                            p: 3,
                            width: 300,
                            mt: 3,
                          }}
                        >
                          <BorderLinearProgress
                            variant="determinate"
                            value={80}
                            sx={{ mb: 2 }}
                          />
                          <Typography sx={{ mb: 1.5 }}>
                            We are currently processing your payment. Kindly
                            check your payment status after 30 minutes. For
                            additional details, please reach out to your
                            admission counsellor.
                          </Typography>
                          <Box
                            sx={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                          >
                            <Button
                              onClick={Refresh}
                              variant="conatained"
                              size="small"
                              sx={{
                                textAlign: "center",
                                color: "#fff",
                                textTransform: "none",
                                background: "#2A7BCC",
                                "&:hover": {
                                  background: "#2A7BCC",
                                  color: "#fff",
                                },
                              }}
                              // onClick={handleChangePayment}
                            >
                              Check Status
                            </Button>
                          </Box>
                        </Box>
                      </Box>
                    ) : (
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <Box
                          sx={{
                            background: "#fff",
                            borderRadius: "5px",
                            border: "1px solid #2A7BCC",
                            p: 3,
                            width: 350,
                            mt: 3,
                          }}
                        >
                          <BorderLinearProgress
                            variant="determinate"
                            value={80}
                            sx={{ mb: 2 }}
                          />
                          <Typography sx={{ mb: 2.5 }}>
                            Your payment is under process. Please contact your
                            admission counsellor for further details
                          </Typography>
                          <Box
                            sx={{
                              display: "flex",
                              justifyContent: "space-between",
                              alignItems: "space-between",
                            }}
                          >
                            <Button
                              onClick={handleClickChange}
                              variant="conatained"
                              size="small"
                              sx={{
                                textAlign: "center",
                                color: "#fff",
                                width: "160px",
                                height: "40px",
                                textTransform: "none",
                                background: "#2A7BCC",
                                "&:hover": {
                                  background: "#2A7BCC",
                                  color: "#fff",
                                  width: "160px",
                                  height: "40px",
                                },
                              }}
                              // onClick={handleChangePayment}
                            >
                              Switch Pay Method
                            </Button>
                            <Button
                              onClick={Refresh}
                              variant="conatained"
                              size="small"
                              sx={{
                                textAlign: "center",
                                color: "#fff",
                                width: "160px",
                                height: "40px",
                                textTransform: "none",
                                background: "#2A7BCC",
                                "&:hover": {
                                  background: "#2A7BCC",
                                  color: "#fff",
                                  width: "160px",
                                  height: "40px",
                                },
                              }}
                              // onClick={handleChangePayment}
                            >
                              Check Loan Status
                            </Button>
                          </Box>
                        </Box>
                      </Box>
                    )}
                  </Box>
                )}
              </Box>
            )}
          </Box>
        </Box>
      </Box>

      {/* mobile version */}
      <Box
        sx={{ display: { xs: "block", lg: "none", sm: "block", md: "block" } }}
      >
        <Box
          sx={{
            // display: "grid",
            // placeItems: "center",
            p: 3,
            mt: 8,
            width: "85%",
            borderRadius: "15px",

            overflowY: "scroll",
            height: { xs: 500, sm: 700, md: 800 },
            "&::-webkit-scrollbar": {
              width: "0.2em",
            },
            "&::-webkit-scrollbar-track": {
              boxShadow: "inset 0 0 6px silver",
              webkitBoxShadow: "inset 0 0 6px silver",
            },
            "&::-webkit-scrollbar-thumb": {
              backgroundColor: "silver",
              outline: "1px solid silver",
            },
          }}
        >
          <Box
            sx={{
              borderRadius: "8px",
              p: 1.5,
              background: "#fff",
              backgroundColor: "rgba(255, 255, 255, .60)",
              pl: 4,
              pr: 4,
              backdropFilter: "blur(5px)",
              display: "grid",
              placeContent: "center",
              border: "1px solid #002E5C",
            }}
          >
            <Box sx={{ display: "grid", placeItems: "center" }}>
              {/* <Box sx={{display:"flex"}}> */}
              <Typography
                sx={{
                  color: "red",
                  animation: "blink 1s infinite ",
                  "@keyframes blink": {
                    "0%, 100%": {
                      opacity: 1,
                      transform: "scale(1)",
                    },
                    "50%": {
                      opacity: 1,
                      transform: "scale(1.1)",
                    },
                  },
                  fontSize: { xs: "14px", lg: "16px" },
                }}
              >
                <i style={{ textAlign: "center" }}>
                  Please select a payment option
                </i>
              </Typography>
              <ArrowDownwardIcon
                sx={{
                  color: "red",
                  mb: 1,
                  animation: "blink 1s infinite ",
                  "@keyframes blink": {
                    "0%, 100%": {
                      opacity: 1,
                      transform: "scale(1)",
                    },
                    "50%": {
                      opacity: 1,
                      transform: "scale(1.1)",
                    },
                  },
                }}
              />
              <Box
                sx={{
                  border: "2px solid black",
                  width: { xs: 220, lg: "fit-content" },
                  borderRadius: { xs: "7px", lg: "9px" },
                  height: "41px",
                }}
              >
                <Tabs
                  orientation="horizontal"
                  onChange={handleChange}
                  value={value}
                  centered
                  sx={{
                    //  border:"2px solid black",
                    "& button": {
                      borderRadius: 1,
                      color: "#000 !important",
                      minHeight: "fit-content !important",
                      textTransform: "initial !important",
                    },
                    "& button.Mui-selected": {
                      backgroundColor: "#2A7BCC !important",
                      color: "#fff!important",
                      borderRadius: "8px",
                      // width: "100%",
                    },
                    "& span.MuiTabs-indicator": {
                      display: "none",
                    },
                  }}
                >
                  <Tab
                    value={0}
                    sx={{ textAlign: "left" }}
                    label="Registration"
                  ></Tab>
                  <Tab
                    onClick={full_paymentClick}
                    value={1}
                    sx={{ textAlign: "left" }}
                    label="Full Payment"
                  ></Tab>
                </Tabs>
              </Box>
              {/* </Box> */}
            </Box>
            {/* <Typography sx={{fontWeight:"bold",fontSize:"20px",textAlign:"left",mb:2}}>Application Fee</Typography> */}
            {value === 0 && (
              <Box>
                {localStorage.getItem("currentStepStatus") == "pending" &&
                showPaymentState == false ? (
                  <Box sx={{ p: 1.5 }}>
                    <Box
                      sx={{
                        mb: 1,
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                      //   onClick={handleClickPersonal}
                    >
                      {/* {openPersonal ? <ExpandLess sx={{color:"#fff"}} /> : <ExpandMore sx={{color:"#fff"}} />} */}
                      {/* <ArrowBackIcon sx={{cursor:"pointer",}} onClick={handleBack}/> */}
                      <Typography
                        sx={{
                          color: "#000",
                          fontWeight: "bold",
                          textAlign: "center",
                          fontSize: "23px",
                          //  ml:3
                        }}
                      >
                        Application Fee
                      </Typography>
                    </Box>

                    {/* <Box
                 sx={{
                   // display: "flex",
                   // justifyContent: "space-between",
                   mt: 1,
                   mb: 1,
                 }}
               >
                 <Box>
                   <Typography
                     sx={{
                       fontWeight: "bold",
                       fontSize: "14px",
                       color: "#000",
                       textAlign: "left",
                     }}
                   >
                     First Name
                   </Typography>
                   <BootstrapInput
                     sx={{ width: 220 }}
                     size="small"
                     inputProps={{ readOnly: true }}
                     value={localStorage.getItem("firstname")}
                   />
                 </Box>
                 <Box>
                   <Typography
                     sx={{
                       fontWeight: "bold",
                       fontSize: "14px",
                       color: "#000",
                       textAlign: "left",
                     }}
                   >
                     Middle Name
                   </Typography>
                   <BootstrapInput
                     sx={{ width: 220 }}
                     size="small"
                     inputProps={{ readOnly: true }}
                     value={localStorage.getItem("middlename")}
                   />
                 </Box>
               </Box>
               <Box
                 sx={{
                   // display: "flex",
                   // justifyContent: "space-between",
                   mt: 1,
                   mb: 1,
                 }}
               >
                 <Box>
                   <Typography
                     sx={{
                       fontWeight: "bold",
                       fontSize: "14px",
                       color: "#000",
                       textAlign: "left",
                     }}
                   >
                     Last Name
                   </Typography>
                   <BootstrapInput
                     sx={{ width: 220 }}
                     size="small"
                     inputProps={{ readOnly: true }}
                     value={localStorage.getItem("lastname")}
                   />
                 </Box>
                 <Box>
                   <Typography
                     sx={{
                       fontWeight: "bold",
                       fontSize: "14px",
                       color: "#000",
                       textAlign: "left",
                     }}
                   >
                     Phone Number
                   </Typography>
                   <BootstrapInput
                     type="number"
                     sx={{ width: 220 }}
                     size="small"
                     inputProps={{ readOnly: true }}
                     value={localStorage.getItem("mobile")}
                   />
                 </Box>
               </Box> */}
                    <Box
                      sx={{
                        // display: "flex",
                        // justifyContent: "space-between",
                        mt: 1,
                        mb: 1,
                      }}
                    >
                      <Box>
                        <Typography
                          sx={{
                            fontWeight: "bold",
                            fontSize: "14px",
                            color: "#000",
                            textAlign: "left",
                          }}
                        >
                          Email ID <span style={{ color: "red" }}>*</span>
                        </Typography>
                        <TextField
                          type="email"
                          sx={{
                            width: { xs: 220, sm: 430, md: 450 },
                            mb: { xs: 0, sm: 2, md: 2 },
                            borderRadius: "5px",
                            background: "rgb(217, 217, 217)",
                          }}
                          size="small"
                          inputProps={{ readOnly: true }}
                          value={localStorage.getItem("email")}
                        />
                      </Box>
                      <Box>
                        <Typography
                          sx={{
                            fontWeight: "bold",
                            fontSize: "14px",
                            color: "#000",
                            textAlign: "left",
                          }}
                        >
                          Program <span style={{ color: "red" }}>*</span>
                        </Typography>
                        {/* <FormControl fullWidth>
                     <Select
                       size="small"
                       sx={{ width: 220, background: "#fff", textAlign: "left" }}
                       labelId="demo-simple-select-label"
                       id="demo-simple-select"
                       // value={course}
                       defaultValue={"Select the Program"}
                       // label="course"
                       // onChange={handleChangecourse}
                     >
                       <MenuItem value="Select the Program">
                       <i>Select the Program</i>
                       </MenuItem>
                       {course.map((val) => (
                         <MenuItem
                           key={val.pid}
                           value={val.pid}
                           onClick={() => handleChangecourse(val.pcode, val.pid,val.category_id)}
                         >
                           {val.pname}
                         </MenuItem>
                       ))}
                     </Select>
                   </FormControl> */}
                        {course.length == 1 ? (
                          <TextField
                            // type="email"
                            sx={{
                              width: { xs: 220, sm: 430, md: 450 },
                              borderRadius: "5px",
                              background: "#d9d9d9",
                            }}
                            size="small"
                            inputProps={{ readOnly: true }}
                            value={program_name}
                          />
                        ) : (
                          <FormControl
                            fullWidth
                            sx={{
                              boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
                            }}
                          >
                            <Select
                              size="small"
                              sx={{
                                width: { xs: 220, sm: 430, md: 450 },
                                background: "#fff",
                                textAlign: "left",
                              }}
                              labelId="demo-simple-select-label"
                              id="demo-simple-select"
                              defaultValue={"Select the Program"}

                              // label="course"
                              // onChange={handleChangecourse}
                            >
                              <MenuItem disabled value="Select the Program">
                                <i>Select the Program</i>
                              </MenuItem>
                              {course.map((val) => (
                                <MenuItem
                                  key={val.pid}
                                  value={val.pid}
                                  onClick={() =>
                                    handleChangecourse(
                                      val.pcode,
                                      val.pid,
                                      val.pname
                                    )
                                  }
                                >
                                  {val.pname}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        )}
                        {/* <TextField
                                 // type="email"
                                 sx={{ width: 220,borderRadius:"5px",background:"#fff" }}
                                 size="small"
                                 inputProps={{ readOnly: true }}
                                 value={program_name}
                               /> */}
                      </Box>
                    </Box>
                    <Box
                      sx={{
                        // display: "flex",
                        // justifyContent: "space-between",
                        mt: 1,
                        mb: 1,
                      }}
                    >
                      <Box>
                        <Typography
                          sx={{
                            fontWeight: "bold",
                            fontSize: "14px",
                            color: "#000",
                            textAlign: "left",
                          }}
                        >
                          Application Fee{" "}
                          <span style={{ color: "red" }}>*</span>
                        </Typography>
                        <TextField
                          sx={{
                            width: { xs: 220, sm: 430, md: 450 },
                            mb: { xs: 0, sm: 2, md: 2 },
                            borderRadius: "5px",
                            background: "rgb(217, 217, 217)",
                          }}
                          size="small"
                          inputProps={{ readOnly: true }}
                          value={`INR 10,000 + GST`}
                        />
                      </Box>
                      <Box sx={{ mb: 1 }}>
                        <Typography
                          sx={{
                            fontWeight: "bold",
                            fontSize: "14px",
                            color: "#000",
                            textAlign: "left",
                          }}
                        >
                          Batch <span style={{ color: "red" }}>*</span>
                        </Typography>
                       
                   {batch.length==1?(
                       <TextField
                       // type="email"
                       sx={{
                         width: { xs: 220, sm: 430, md: 450 },
                         mb: { xs: 0, sm: 2, md: 2 },
                         borderRadius: "5px",
                         background: "rgb(217, 217, 217)",
                       }}
                       size="small"
                       inputProps={{ readOnly: true }}
                       value={batchtext}
                     />
                   ):(
                    <FormControl fullWidth>
                    
                    <Select
                      size="small"
                      sx={{ width: 220, background: "#fff", textAlign: "left" }}
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      // value={batchs}
                      defaultValue="Select the Batch"
                      // label="batch"
                      // onChange={handleChangebatch}
                    >
                      <MenuItem value="Select the Batch">
                      <i>Select the Batch</i>
                      </MenuItem>
                       {batch.map((val, i) => (
                        <MenuItem  onClick={() => handleChangebatch(val.register_id,val.batch_id,val.batch_text,val.batch)} value={val.batch_id}>{val.batch_text}</MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                   )}
                       
                      </Box>
                    </Box>
                    <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
                      <Button
                        // variant="conatained"
                        // size="small"
                        // onClick={()=>{props.handleNext()}}
                        disabled={nxt}
                        sx={{
                          color: "#fff",
                          mt: 1,
                          textTransform: "none",
                          background: "#002E5C",
                          "&:hover": { background: "#002E5C", color: "#fff" },
                        }}
                        onClick={() => {
                          checkoutHandler(props.reg_amount);
                        }}
                        variant="contained"
                        size="small"
                        width="150"
                        height="80"
                      >
                        Pay Now
                      </Button>
                    </Box>
                  </Box>
                ) : (
                  <Box>
                    {localStorage.getItem("currentStepStatus") ==
                      "payment_inprocess" || showPaymentState ? (
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <Box
                          sx={{
                            background: "#fff",
                            borderRadius: "5px",
                            border: "1px solid #2A7BCC",
                            p: 3,
                            width: 250,
                            mt: 3,
                          }}
                        >
                          <BorderLinearProgress
                            variant="determinate"
                            value={80}
                            sx={{ mb: 2 }}
                          />
                          <Typography sx={{ mb: 2.5 }}>
                            We are currently processing your payment. Kindly
                            check your payment status after 30 minutes. For
                            additional details, please reach out to your
                            admission counsellor.
                          </Typography>
                          <Box
                            sx={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                          >
                            <Button
                              onClick={Refresh}
                              variant="conatained"
                              size="small"
                              sx={{
                                textAlign: "center",
                                color: "#fff",
                                width: "160px",
                                height: "40px",
                                textTransform: "none",
                                background: "#2A7BCC",
                                "&:hover": {
                                  background: "#2A7BCC",
                                  color: "#fff",
                                  width: "160px",
                                  height: "40px",
                                },
                              }}
                              // onClick={handleChangePayment}
                            >
                              Check Status
                            </Button>
                          </Box>
                        </Box>
                      </Box>
                    ) : (
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <Box
                          sx={{
                            background: "#fff",
                            borderRadius: "5px",
                            border: "1px solid #2A7BCC",
                            p: 3,
                            width: 250,
                            mt: 3,
                          }}
                        >
                          <BorderLinearProgress
                            variant="determinate"
                            value={80}
                            sx={{ mb: 2 }}
                          />
                          <Typography sx={{ mb: 2.5 }}>
                            Your payment is under process. Please contact your
                            admission counsellor for further details
                          </Typography>
                          <Box
                            sx={
                              {
                                // display: "flex",
                                // justifyContent: "space-between",
                                // alignItems: "space-between",
                              }
                            }
                          >
                            <Button
                              onClick={handleClickChange}
                              variant="conatained"
                              size="small"
                              sx={{
                                textAlign: "center",
                                color: "#fff",
                                // width:"120px",
                                // height:"40px",
                                textTransform: "none",
                                background: "#2A7BCC",
                                "&:hover": {
                                  background: "#2A7BCC",
                                  color: "#fff",
                                },
                              }}
                              // onClick={handleChangePayment}
                            >
                              Switch Pay Method
                            </Button>
                            <Button
                              onClick={Refresh}
                              variant="conatained"
                              size="small"
                              sx={{
                                textAlign: "center",
                                color: "#fff",
                                mt: 2,
                                // width:"120px",
                                // height:"40px",
                                textTransform: "none",
                                background: "#2A7BCC",
                                "&:hover": {
                                  background: "#2A7BCC",
                                  color: "#fff",
                                },
                              }}
                              // onClick={handleChangePayment}
                            >
                              Check Loan Status
                            </Button>
                          </Box>
                        </Box>
                      </Box>
                    )}
                  </Box>
                )}
              </Box>
            )}

            {value === 1 && (
              <Box>
                {!show &&
                localStorage.getItem("currentStepStatus") == "pending" &&
                showPaymentState == false ? (
                  <Box sx={{ p: 1.5 }}>
                    <Box sx={{ mb: 3 }}>
                      <Typography
                        sx={{
                          color: "#000",
                          fontWeight: "bold",
                          textAlign: "center",
                          fontSize: "23px",
                        }}
                      >
                        Select Your Payment Method
                      </Typography>
                    </Box>

                    <Box
                      sx={{
                        // display: "flex",
                        // justifyContent: "space-between",

                        mt: 1,
                        mb: 3,
                      }}
                    >
                      <Box
                        id="one"
                        sx={{
                          p: 2,
                          border: "1px solid #2A7BCC",
                          borderRadius: "7px",
                          background: "#fff",
                          cursor: "pointer",
                          mb: 1,
                        }}
                        onClick={handlePayment}
                      >
                        <Typography
                          id="onet"
                          sx={{
                            fontWeight: "bold",
                            fontSize: "14px",
                            color: "#000",
                            textAlign: "left",
                          }}
                        >
                          One time payment
                        </Typography>
                        {/* <BootstrapInput
  sx={{ width: 480 }}
  size="small"
  inputProps={{ readOnly: true }}
  value={localStorage.getItem("firstname")}
/> */}
                      </Box>
                      <Box
                        id="credit"
                        sx={{
                          p: 2,
                          border: "1px solid #2A7BCC",
                          borderRadius: "5px",
                          background: "#fff",
                          cursor: "pointer",
                          mb: 1,
                        }}
                        onClick={handlePaymentCredit}
                      >
                        <Typography
                          id="creditt"
                          sx={{
                            fontWeight: "bold",
                            fontSize: "14px",
                            color: "#000",
                            textAlign: "left",
                          }}
                        >
                          Credit Card EMI
                        </Typography>
                        {/* <BootstrapInput
  sx={{ width: 480 }}
  size="small"
  inputProps={{ readOnly: true }}
  value={localStorage.getItem("middlename")}
/> */}
                      </Box>
                      {/* <Box
      id="debit"
      sx={{
        p: 2,
        border: "1px solid #2A7BCC",
        borderRadius: "5px",
        background: "#fff",
        cursor: "pointer",
      }}
      onClick={handlePaymentDebit}
    >
      <Typography
        id="debitt"
        sx={{
          fontWeight: "bold",
          fontSize: "14px",
          color: "#000",
          textAlign: "left",
        }}
      >
        Debit Card EMI
      </Typography>

    </Box> */}
                      <Box
                        id="finance"
                        sx={{
                          p: 2,
                          border: "1px solid #2A7BCC",
                          borderRadius: "5px",
                          background: "#fff",
                          cursor: "pointer",
                        }}
                        onClick={handlePaymentFinance}
                      >
                        <Typography
                          id="financet"
                          sx={{
                            fontWeight: "bold",
                            fontSize: "14px",
                            color: "#000",
                            textAlign: "left",
                          }}
                        >
                          Finance partner
                        </Typography>
                        {/* <BootstrapInput
  sx={{ width: 480 }}
  size="small"
  inputProps={{ readOnly: true }}
  value={localStorage.getItem("firstname")}
/> */}
                      </Box>
                    </Box>
                    {/* <Box
sx={{
display: "flex",
justifyContent: "space-between",
mt: 1,
mb: 1,
}}
>



</Box> */}
                    {payment == "" ? (
                      <Box></Box>
                    ) : (
                      <Box>
                        {!(payment == "finance") ? (
                          <Box>
                            <Box
                              sx={{
                                // display: "flex",
                                // justifyContent: "space-between",
                                mt: 1,
                                mb: 1,
                              }}
                            >
                              <Box>
                                <Typography
                                  sx={{
                                    fontWeight: "bold",
                                    fontSize: "14px",
                                    color: "#000",
                                    textAlign: "left",
                                  }}
                                >
                                  Email ID{" "}
                                  <span style={{ color: "red" }}>*</span>
                                </Typography>
                                <TextField
                                  type="email"
                                  sx={{
                                    width: { xs: 250, sm: 430, md: 450 },
                                    mb: { xs: 0, sm: 2, md: 2 },
                                    borderRadius: "5px",
                                    background: "rgb(217, 217, 217)",
                                  }}
                                  size="small"
                                  inputProps={{ readOnly: true }}
                                  value={localStorage.getItem("email")}
                                />
                              </Box>
                              <Box>
                                <Typography
                                  sx={{
                                    fontWeight: "bold",
                                    fontSize: "14px",
                                    color: "#000",
                                    textAlign: "left",
                                  }}
                                >
                                  Program{" "}
                                  <span style={{ color: "red" }}>*</span>
                                </Typography>
                                {/* <TextField
                // type="email"
                sx={{ width: 250,borderRadius:"5px",background:"#fff" }}
                size="small"
                inputProps={{ readOnly: true }}
                value={program_name}
              /> */}
                                {course.length == 1 ? (
                                  <TextField
                                    // type="email"
                                    sx={{
                                      width: { xs: 250, sm: 430, md: 450 },
                                      borderRadius: "5px",
                                      background: "#d9d9d9",
                                    }}
                                    size="small"
                                    inputProps={{ readOnly: true }}
                                    value={program_name}
                                  />
                                ) : (
                                  <FormControl
                                    fullWidth
                                    sx={{
                                      boxShadow:
                                        "0px 4px 4px rgba(0, 0, 0, 0.25)",
                                    }}
                                  >
                                    <Select
                                      size="small"
                                      sx={{
                                        width: { xs: 220, sm: 430, md: 450 },
                                        background: "#fff",
                                        textAlign: "left",
                                      }}
                                      labelId="demo-simple-select-label"
                                      id="demo-simple-select"
                                      defaultValue={"Select the Program"}

                                      // label="course"
                                      // onChange={handleChangecourse}
                                    >
                                      <MenuItem
                                        disabled
                                        value="Select the Program"
                                      >
                                        <i>Select the Program</i>
                                      </MenuItem>
                                      {course.map((val) => (
                                        <MenuItem
                                          key={val.pid}
                                          value={val.pid}
                                          onClick={() =>
                                            handleChangecourse(
                                              val.pcode,
                                              val.pid,
                                              val.pname
                                            )
                                          }
                                        >
                                          {val.pname}
                                        </MenuItem>
                                      ))}
                                    </Select>
                                  </FormControl>
                                )}
                                {/* <FormControl fullWidth>
                <Select
                  size="small"
                  sx={{
                    width: 428,
                    background: "#fff",
                    textAlign: "left",
                  }}
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  // value={country}
                  defaultValue="38"
                  label="course"
                  // onChange={handleChangeCountry}
                >
                  {course.map((val) => (
                    <MenuItem
                      key={val.pid}
                      value={val.pid}
                      onClick={() =>
                        handleChangecourse(val.pcode, val.pid)
                      }
                    >
                      {val.pname}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl> */}
                              </Box>
                            </Box>
                            <Box
                              sx={{
                                // display: "flex",
                                // justifyContent: "space-between",
                                mt: 1,
                                mb: 1,
                              }}
                            >
                              <Box>
                                <Typography
                                  sx={{
                                    fontWeight: "bold",
                                    fontSize: "14px",
                                    color: "#000",
                                    textAlign: "left",
                                  }}
                                >
                                  Admission Fee{" "}
                                  <span style={{ color: "red" }}>*</span>
                                </Typography>
                                <TextField
                                  type="number"
                                  sx={{
                                    width: { xs: 250, sm: 430, md: 450 },
                                    mb: { xs: 0, sm: 2, md: 2 },
                                    background: "#fff",
                                    borderRadius: "6px",
                                  }}
                                  size="small"
                                  error={error}
                                  helperText={
                                    error ? "Please Enter a valid Amount" : ""
                                  }
                                  // inputProps={{ readOnly: true }}
                                  onChange={paymentChange}
                                />
                                {/* <BootstrapInput
               type="number"
                sx={{ width: {xs:250,sm:430,md:450},mb:{xs:0,sm:2,md:2} }}
                size="small"
                // inputProps={{ readOnly: true }}
                onChange={paymentChange}
              /> */}
                              </Box>
                              <Box sx={{ mb: 1 }}>
                                <Typography
                                  sx={{
                                    fontWeight: "bold",
                                    fontSize: "14px",
                                    color: "#000",
                                    textAlign: "left",
                                  }}
                                >
                                  Batch <span style={{ color: "red" }}>*</span>
                                </Typography>
                                {batch.length==1?(
                       <TextField
                       // type="email"
                       sx={{
                         width: { xs: 250, sm: 430, md: 450 },
                         mb: { xs: 0, sm: 2, md: 2 },
                         borderRadius: "5px",
                         background: "rgb(217, 217, 217)",
                       }}
                       size="small"
                       inputProps={{ readOnly: true }}
                       value={batchtext}
                     />
                   ):(
                    <FormControl fullWidth>
                    
                    <Select
                      size="small"
                      sx={{ width: 250, background: "#fff", textAlign: "left" }}
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      // value={batchs}
                      defaultValue="Select the Batch"
                      // label="batch"
                      // onChange={handleChangebatch}
                    >
                      <MenuItem value="Select the Batch">
                      <i>Select the Batch</i>
                      </MenuItem>
                       {batch.map((val, i) => (
                        <MenuItem  onClick={() => handleChangebatch(val.register_id,val.batch_id,val.batch_text,val.batch)} value={val.batch_id}>{val.batch_text}</MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                   )}
                                {/* <TextField
                                  // type="email"
                                  sx={{
                                    width: { xs: 250, sm: 430, md: 450 },
                                    mb: { xs: 0, sm: 2, md: 2 },
                                    borderRadius: "5px",
                                    background: "rgb(217, 217, 217)",
                                  }}
                                  size="small"
                                  inputProps={{ readOnly: true }}
                                  value={batchtext}
                                /> */}
                                {/* <FormControl fullWidth>
               
                <Select
                  size="small"
                  sx={{
                    width: 428,
                    background: "#fff",
                    textAlign: "left",
                  }}
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={batchs}
                  // defaultValue="2023-06-01"
                  // label="batch"
                  onChange={handleChangebatch}
                >
                  {batch.map((val, i) => (
                    <MenuItem value={val.batch_id}>
                      {val.batch_text}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl> */}
                              </Box>
                            </Box>

                            {/* <Box
sx={{ mb: 0.5, p: 1, borderRadius: "5px" }}
// onClick={handleClickaddress}
>

<Typography
  sx={{
    color: "#000",
    fontWeight: "bold",
    textAlign: "center",
    fontSize: "23px",
  }}
>
  Address Details
</Typography>
</Box>

<Box
sx={{
  // display: "flex",
  // justifyContent: "space-between",
  mt: 1,
  mb: 1,
}}
>
<Box>
  <Typography
    sx={{
      fontWeight: "bold",
      fontSize: "14px",
      color: "#000",
      textAlign: "left",
    }}
  >
    Country <span style={{ color: "red" }}>*</span>{" "}
  </Typography>
  <FormControl fullWidth>
    
    <Select
      size="small"
      sx={{ width: 250, background: "#fff", textAlign: "left" }}
      labelId="demo-simple-select-label"
      id="demo-simple-select"
      value={country}
      label="Gender"
      onChange={handleChangeCountry}
    >
      {Country.getAllCountries().map((val, i) => (
        <MenuItem value={val.isoCode}>{val.name}</MenuItem>
      ))}
    </Select>
  </FormControl>
</Box>
<Box>
  <Typography
    sx={{
      fontWeight: "bold",
      fontSize: "14px",
      color: "#000",
      textAlign: "left",
    }}
  >
    State <span style={{ color: "red" }}>*</span>
  </Typography>
  <FormControl fullWidth>
    
    <Select
      size="small"
      sx={{ width: 250, background: "#fff", textAlign: "left" }}
      labelId="demo-simple-select-label"
      id="demo-simple-select"
      value={state}
      label="Gender"
      onChange={handleChangeState}
    >
      {State.getAllStates()
        .filter((val) => {
          return val.countryCode == country;
        })
        .sort()
        .map((val) => (
          <MenuItem value={val.name}>{val.name}</MenuItem>
        ))}
    </Select>
  </FormControl>
</Box>
</Box>
<Box
sx={{
  // display: "flex",
  // justifyContent: "space-between",
  mt: 1,
  mb: 1,
}}
>
<Box>
  <Typography
    sx={{
      fontWeight: "bold",
      fontSize: "14px",
      color: "#000",
      textAlign: "left",
    }}
  >
    City <span style={{ color: "red" }}>*</span>
  </Typography>

  <FormControl fullWidth>
   
    <Select
      size="small"
      sx={{ width: 250, background: "#fff", textAlign: "left" }}
      labelId="demo-simple-select-label"
      id="demo-simple-select"
      value={city}
      label="Gender"
      onChange={handleChangeCity}
    >
      {cities.map((val) => (
        <MenuItem value={val.name}>{val.name}</MenuItem>
      ))}
    </Select>
  </FormControl>
</Box>
<Box>
  <Typography
    sx={{
      fontWeight: "bold",
      fontSize: "14px",
      color: "#000",
      textAlign: "left",
    }}
  >
    Pincode <span style={{ color: "red" }}>*</span>
  </Typography>
  <BootstrapInput
    onChange={handleChangePincode}
    value={pincode}
    type="number"
    sx={{ width: 250 }}
    size="small"
  />
</Box>
</Box>
<Box
sx={{
  // display: "flex",
  // justifyContent: "space-between",
  mt: 1,
  mb: 1,
}}
>
<Box>
  <Typography
    sx={{
      fontWeight: "bold",
      fontSize: "14px",
      color: "#000",
      textAlign: "left",
    }}
  >
    Address 1 <span style={{ color: "red" }}>*</span>
  </Typography>
  <BootstrapInput
    onChange={handleChangeAddress}
    value={address}
    sx={{ width: 250 }}
    size="small"
    type="text"
  />
</Box>
<Box>
  <Typography
    sx={{
      fontWeight: "bold",
      fontSize: "14px",
      color: "#000",
      textAlign: "left",
    }}
  >
    Address 2{" "}
  </Typography>
  <BootstrapInput
    onChange={handleChangeAddresst}
    value={addresstwo}
    sx={{ width: 250 }}
    size="small"
    type="text"
  />
</Box>
</Box> */}

                            <Box
                              sx={{
                                display: "flex",
                                justifyContent: "flex-end",
                              }}
                            >
                              <Button
                                // variant="conatained"
                                // size="small"
                                disabled={enablePay}
                                sx={{
                                  color: "#fff",
                                  mt: 1,
                                  textTransform: "none",
                                  background: "#002E5C",
                                  "&:hover": {
                                    background: "#002E5C",
                                    color: "#fff",
                                  },
                                }}
                                onClick={() => {
                                  checkoutHandler(addmissionPayment);
                                }}
                                variant="contained"
                                size="small"
                                width="150"
                                height="80"
                              >
                                Pay Now
                              </Button>
                            </Box>
                          </Box>
                        ) : (
                          <Box>
                            <Box
                              sx={{
                                display: { xs: "flex", lg: "none" },
                                justifyContent: "center",
                                alignItems: "center",
                                mt: 4,
                              }}
                            >
                              <Box
                                sx={{
                                  background: "#fff",
                                  borderRadius: "8px",
                                  border: "1px solid #2A7BCC",
                                  px: 1,
                                  pb: 1.5,
                                  width: { xs: 280, sm: 400, md: 450 },
                                }}
                              >
                                <Box sx={{ textAlign: "center", my: 1 }}>
                                  {/* <img src={icon} alt="logo"  />  */}
                                  <CardMedia
                                    component="img"
                                    image="../images/icon.png"
                                    sx={{
                                      width: "45px",
                                      margin: "auto",
                                    }}
                                  />
                                </Box>

                                <Typography
                                  sx={{
                                    fontWeight: "300",
                                    fontSize: "16px",
                                    mb: 2.5,
                                    textAlign: "center",
                                    p: 1,
                                  }}
                                >
                                  Thank you for choosing Finance partner as your
                                  payment option for the program. Please note
                                  that Finance partner is a third-party payment
                                  service provider and their terms and
                                  conditions will apply to your transaction.
                                </Typography>
                                <FormGroup>
                                  {/* <FormControlLabel control={<Checkbox defaultChecked />} label="Label" /> */}
                                  <FormControlLabel
                                    control={
                                      <Checkbox
                                        sx={{ fontSize: "12px" }}
                                        checked={checked}
                                        onChange={handleCheck}
                                      />
                                    }
                                    label={
                                      <div
                                        style={{
                                          fontSize: "10px",
                                          padding: "5px",
                                          textAlign: "left",
                                        }}
                                      >
                                        <span style={{ color: "red" }}>*</span>{" "}
                                        I agree to the terms and conditions of
                                        the finance partner and provide required
                                        documents for loan approval.
                                      </div>
                                    }
                                  />
                                  {/* <FormControlLabel disabled control={<Checkbox />} label="Disabled" /> */}
                                </FormGroup>
                              </Box>
                            </Box>

                            {/* <Box
sx={{ mb: 0.5, p: 1, borderRadius: "5px" }}
// onClick={handleClickaddress}
>

<Typography
  sx={{
    color: "#000",
    fontWeight: "bold",
    textAlign: "center",
    fontSize: "23px",
  }}
>
  Address Details
</Typography>
</Box> */}

                            {/* <Box
sx={{
  // display: "flex",
  // justifyContent: "space-between",
  mt: 1,
  mb: 1,
}}
>
<Box>
  <Typography
    sx={{
      fontWeight: "bold",
      fontSize: "14px",
      color: "#000",
      textAlign: "left",
    }}
  >
    Country <span style={{ color: "red" }}>*</span>{" "}
  </Typography>
  <FormControl fullWidth>
   
    <Select
      size="small"
      sx={{ width: 250, background: "#fff", textAlign: "left" }}
      labelId="demo-simple-select-label"
      id="demo-simple-select"
      value={country}
      label="Gender"
      onChange={handleChangeCountry}
    >
      {Country.getAllCountries().map((val, i) => (
        <MenuItem value={val.isoCode}>{val.name}</MenuItem>
      ))}
    </Select>
  </FormControl>
</Box>
<Box>
  <Typography
    sx={{
      fontWeight: "bold",
      fontSize: "14px",
      color: "#000",
      textAlign: "left",
    }}
  >
    State <span style={{ color: "red" }}>*</span>
  </Typography>
  <FormControl fullWidth>
   
    <Select
      size="small"
      sx={{ width: 250, background: "#fff", textAlign: "left" }}
      labelId="demo-simple-select-label"
      id="demo-simple-select"
      value={state}
      label="Gender"
      onChange={handleChangeState}
    >
      {State.getAllStates()
        .filter((val) => {
          return val.countryCode == country;
        })
        .sort()
        .map((val) => (
          <MenuItem value={val.name}>{val.name}</MenuItem>
        ))}
    </Select>
  </FormControl>
</Box>
</Box> */}
                            {/* <Box
sx={{
  // display: "flex",
  // justifyContent: "space-between",
  mt: 1,
  mb: 1,
}}
>
<Box>
  <Typography
    sx={{
      fontWeight: "bold",
      fontSize: "14px",
      color: "#000",
      textAlign: "left",
    }}
  >
    City <span style={{ color: "red" }}>*</span>
  </Typography>

  <FormControl fullWidth>
    
    <Select
      size="small"
      sx={{ width: 250, background: "#fff", textAlign: "left" }}
      labelId="demo-simple-select-label"
      id="demo-simple-select"
      value={city}
      label="Gender"
      onChange={handleChangeCity}
    >
      {cities.map((val) => (
        <MenuItem value={val.name}>{val.name}</MenuItem>
      ))}
    </Select>
  </FormControl>
</Box>
<Box>
  <Typography
    sx={{
      fontWeight: "bold",
      fontSize: "14px",
      color: "#000",
      textAlign: "left",
    }}
  >
    Pincode <span style={{ color: "red" }}>*</span>
  </Typography>
  <BootstrapInput
    onChange={handleChangePincode}
    value={pincode}
    type="number"
    sx={{ width: 250 }}
    size="small"
  />
</Box>
</Box> */}
                            {/* <Box
sx={{
  // display: "flex",
  // justifyContent: "space-between",
  mt: 1,
  mb: 1,
}}
>
<Box>
  <Typography
    sx={{
      fontWeight: "bold",
      fontSize: "14px",
      color: "#000",
      textAlign: "left",
    }}
  >
    Address 1 <span style={{ color: "red" }}>*</span>
  </Typography>
  <BootstrapInput
    onChange={handleChangeAddress}
    value={address}
    sx={{ width: 250 }}
    size="small"
    type="text"
  />
</Box>
<Box>
  <Typography
    sx={{
      fontWeight: "bold",
      fontSize: "14px",
      color: "#000",
      textAlign: "left",
    }}
  >
    Address 2{" "}
  </Typography>
  <BootstrapInput
    onChange={handleChangeAddresst}
    value={addresstwo}
    sx={{ width: 250 }}
    size="small"
    type="text"
  />
</Box>
</Box> */}

                            <Box
                              sx={{
                                display: "flex",
                                justifyContent: "flex-end",
                                mt: 2,
                              }}
                            >
                              <Button
                                onClick={handleClick}
                                // variant="conatained"
                                // size="small"
                                disabled={!checked}
                                sx={{
                                  color: "#fff",
                                  textTransform: "none",
                                  // mt: 1,
                                  background: "#002E5C",
                                  "&:hover": {
                                    background: "#002E5C",
                                    color: "#fff",
                                  },
                                }}
                                variant="contained"
                                size="small"
                                width="150"
                                height="80"
                              >
                                Next
                              </Button>
                              {/* </a> */}
                            </Box>
                          </Box>
                        )}
                      </Box>
                    )}
                  </Box>
                ) : (
                  <Box>
                    {localStorage.getItem("currentStepStatus") ==
                      "payment_inprocess" || showPaymentState ? (
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <Box
                          sx={{
                            background: "#fff",
                            borderRadius: "5px",
                            border: "1px solid #2A7BCC",
                            p: 3,
                            width: 250,
                            mt: 3,
                          }}
                        >
                          <BorderLinearProgress
                            variant="determinate"
                            value={80}
                            sx={{ mb: 2 }}
                          />
                          <Typography sx={{ mb: 2.5 }}>
                            We are currently processing your payment. Kindly
                            check your payment status after 30 minutes. For
                            additional details, please reach out to your
                            admission counsellor.
                          </Typography>
                          <Box
                            sx={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                          >
                            <Button
                              onClick={Refresh}
                              variant="conatained"
                              size="small"
                              sx={{
                                textAlign: "center",
                                color: "#fff",
                                width: "160px",
                                height: "40px",
                                textTransform: "none",
                                background: "#2A7BCC",
                                "&:hover": {
                                  background: "#2A7BCC",
                                  color: "#fff",
                                  width: "160px",
                                  height: "40px",
                                },
                              }}
                              // onClick={handleChangePayment}
                            >
                              Check Status
                            </Button>
                          </Box>
                        </Box>
                      </Box>
                    ) : (
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <Box
                          sx={{
                            background: "#fff",
                            borderRadius: "5px",
                            border: "1px solid #2A7BCC",
                            p: 3,
                            width: 250,
                            mt: 3,
                          }}
                        >
                          <BorderLinearProgress
                            variant="determinate"
                            value={80}
                            sx={{ mb: 2 }}
                          />
                          <Typography sx={{ mb: 2.5 }}>
                            Your payment is under process. Please contact your
                            admission counsellor for further details
                          </Typography>
                          <Box
                            sx={
                              {
                                // display: "flex",
                                // justifyContent: "space-between",
                                // alignItems: "space-between",
                              }
                            }
                          >
                            <Button
                              onClick={handleClickChange}
                              variant="conatained"
                              size="small"
                              sx={{
                                textAlign: "center",
                                color: "#fff",
                                // width:"120px",
                                // height:"40px",
                                textTransform: "none",
                                background: "#2A7BCC",
                                "&:hover": {
                                  background: "#2A7BCC",
                                  color: "#fff",
                                },
                              }}
                              // onClick={handleChangePayment}
                            >
                              Switch Pay Method
                            </Button>
                            <Button
                              onClick={Refresh}
                              variant="conatained"
                              size="small"
                              sx={{
                                textAlign: "center",
                                color: "#fff",
                                mt: 2,
                                // width:"120px",
                                // height:"40px",
                                textTransform: "none",
                                background: "#2A7BCC",
                                "&:hover": {
                                  background: "#2A7BCC",
                                  color: "#fff",
                                },
                              }}
                              // onClick={handleChangePayment}
                            >
                              Check Loan Status
                            </Button>
                          </Box>
                        </Box>
                      </Box>
                    )}
                  </Box>
                )}
              </Box>
            )}
          </Box>
        </Box>
      </Box>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={backopen}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <ToastContainer />
    </>
  );
};

export default ApplicationFee;
