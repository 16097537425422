
import './App.css';
import {Box,Typography, Paper,
  Grid,
  CardMedia,Button} from '@mui/material'
import SignupUpdated from './components/signup-updated'
import Basic from './components/form-steps/Basic';
import LoginUpdated from './components/Login-updated'
import Page404 from './components/Page404';
import { useNetworkCheck } from './network-context';
import {
  Routes,
  Route,
  Navigate,
  useParams,

} from "react-router-dom";
import { useEffect, useState } from 'react';
import SignalWifiConnectedNoInternet4Icon from '@mui/icons-material/SignalWifiConnectedNoInternet4';


function App() {
  const {isOnline} = useNetworkCheck();
 
  return (
    <div  className="App">
     {isOnline?(
      <Routes>
   
      {/* <Route path='/' element={<Navigate to="/spjain" replace/>} /> */}
      <Route path='/' element={<SignupUpdated />} />
      <Route path='/login' element={<LoginUpdated />} />
      <Route path='/form' element={<Basic />} />
      <Route  element={<Page404/>} />
      </Routes>
     ):(
      <Box sx={{display:"flex",alignItems:"center",justifyContent:"center",width:"100%",height:"100vh"}}>
      <Paper sx={{pt:3,pb:3,pl:6,pr:6,borderRadius:"5px",display:"flex",alignItems:"center",justifyContent:"center",width:{lg:"auto",md:"auto",sm:"auto",xs:"70%"}}} elevation={3}>
          <Box>
              <Box sx={{display:"flex",alignItems:"center",justifyContent:"center"}}>
              {/* <CardMedia
                component="img"
                image="../images/lost_conn.webp"
                alt="lost connection"
                sx={{
                 width:"250px",
                 height:"100px",
                 mb:2,
                  // height:"100%",382px
                  display: { xs: "block", lg: "block",sm:"block",md:"block" },
                }}
              /> */}
              <SignalWifiConnectedNoInternet4Icon sx={{fontSize:"50px",color:"#1976d2"}}/>
              
              </Box>
              <Typography sx={{fontWeight:"500",fontSize:"22px",textAlign:"center",mb:2}}>
          Lost Connection
      </Typography>
          <Typography sx={{fontWeight:"400",fontSize:"18px",textAlign:"center",mb:2,color:"gray",width:"300px"}}>
          Opps! No internet connection found. Check you Connection
      </Typography>
      <Box sx={{display:"flex",alignItems:"center",justifyContent:"center"}}>
      <Button onClick={()=>{window.location.reload()}}  target="_blank" sx={{textTransform:"none"}} variant="contained">Try Again</Button>
      </Box>
     
          </Box>
           
          
     
      </Paper>
      </Box>
     )}
     
    </div>
  );
}

export default App;
