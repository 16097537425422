import React, { useEffect, useState } from "react";
import {
  Typography,
  Button,
  Box,
  Paper,
  Grid,
  TextField,
  FormControlLabel,
  Link,
  Badge,
  Checkbox,
  Tabs,
  Tab,
  Step,
  CardMedia,
} from "@mui/material";

import CheckCircleIcon from "@mui/icons-material/CheckCircle";

const Offer = (props) => {
  return (
    <>
      {/* desktop version */}

      <Box
        sx={{
          display: { xs: "none", lg: "flex", sm: "none", md: "none" },
          justifyContent: "center",
          alignItems: "center",
          mt: 7,
        }}
      >
        <Box
          sx={{
            background: "#fff",
            borderRadius: "5px",
            border: "1px solid #2A7BCC",
            px: 3,
            pb: 3,
            width: 330,
          }}
        >
          <Box sx={{ textAlign: "center" }}>
            <CheckCircleIcon
              sx={{ color: "#2A7BCC", fontSize: "80px", pt: 1, pb: 0.5 }}
            />
          </Box>
          <Typography
            sx={{
              textAlign: "center",
              fontWeight: "bold",
              fontSize: "20px",
              mb: 0.5,
            }}
          >
            Thank You!
          </Typography>
          <Typography
            sx={{
              textAlign: "center",
              fontWeight: "300",
              fontSize: "16px",
              mb: 3,
            }}
          >
            {props.text}
          </Typography>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItem: "center",
            }}
          >
            <Button
              href="https://accredian.com/Login"
              target="blank"
              variant="conatained"
              size="small"
              sx={{
                color: "#fff",
                background: "#2A7BCC",
                textTransform: "none",
                textAlign: "center",
                width: 150,
                "&:hover": { background: "#2A7BCC", color: "#fff" },
              }}
            >
              Go To Dashboard
            </Button>
            {/* </a> */}
          </Box>
        </Box>
      </Box>
      {/* mobile version */}
      <Box
        sx={{
          display: { xs: "flex", lg: "none", sm: "flex", md: "flex" },
          justifyContent: "center",
          alignItems: "center",
          mt: { xs: 10, sm: 20, md: 30 },
        }}
      >
        <Box
          sx={{
            background: "#fff",
            borderRadius: "5px",
            border: "1px solid #2A7BCC",
            px: 3,
            pb: 3,
            width: { xs: 270, sm: 370, md: 450 },
          }}
        >
          <Box sx={{ textAlign: "center" }}>
            <CheckCircleIcon
              sx={{ color: "#2A7BCC", fontSize: "80px", pt: 1, pb: 0.5 }}
            />
          </Box>
          <Typography
            sx={{
              textAlign: "center",
              fontWeight: "500",
              fontSize: "20px",
              mb: 0.5,
            }}
          >
            Thank You!
          </Typography>
          <Typography
            sx={{
              textAlign: "center",
              fontWeight: "300",
              fontSize: "16px",
              mb: 3,
            }}
          >
            {props.text}
          </Typography>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItem: "center",
            }}
          >
            <Button
              href="https://accredian.com/Login"
              target="blank"
              variant="conatained"
              size="small"
              sx={{
                color: "#fff",
                background: "#2A7BCC",
                textTransform: "none",
                textAlign: "center",
                width: 150,
                "&:hover": { background: "#2A7BCC", color: "#fff" },
              }}
            >
              Go To Dashboard
            </Button>
          </Box>
        </Box>
      </Box>
    </>
  );
};
export default Offer;
