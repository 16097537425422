import React, { useEffect, useState } from "react";
import {
  Box,
  Paper,
  Grid,
  Typography,
  Button,
  Container,
  TextField,
  FormControl,
  InputLabel,
  OutlinedInput,
  InputAdornment,
  IconButton,
} from "@mui/material";
// import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
// import InputLabel from "@mui/material/InputLabel";
import { alpha, styled } from "@mui/material/styles";
import MenuItem from "@mui/material/MenuItem";
import InputBase from "@mui/material/InputBase";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Swal from "sweetalert2";
import Checkbox from "@mui/material/Checkbox";
import EmailIcon from "@mui/icons-material/Email";
import { Country, State, City } from "country-state-city";
const BootstrapInput = styled(InputBase)(({ theme }) => ({
  "label + &": {
    marginTop: theme.spacing(3),
  },
  "& .MuiInputBase-input": {
    borderRadius: 7,
    position: "relative",
    backgroundColor: theme.palette.mode === "light" ? "#fcfcfb" : "#2b2b2b",
    border: "1px solid #ced4da",
    fontSize: 16,
    width: 400,
    height: 19,
    padding: "10px 12px",
    boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
    transition: theme.transitions.create([
      "border-color",
      "background-color",
      "box-shadow",
    ]),
    // Use the system font instead of the default Roboto font.
    fontFamily: [
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
    "&:focus": {
      boxShadow: `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
      borderColor: theme.palette.primary.main,
    },
  },
}));

const Education = (props) => {
  const [openPersonal, setOpenPersonal] = useState(false);
  const [openADD, setOpenADD] = useState(false);
  const [openaddress, setOpenaddress] = useState(false);
  const [bachelorDegree, setBachelorDegree] = useState("");
  const [bachelorDegreeOther, setBachelorDegreeother] = useState("");
  const [collegeName, setCollegename] = useState("");
  const [yearOfCompletion, setCompletionyear] = useState("");
  const [cgpa, setCgpa] = useState("");
  const [companyname, setCompanyName] = useState("");
  const [companyemail, setCompanyemail] = useState();
  const [previuosCompany, setPreviouscompany] = useState();
  const [bachelorDocuments, setBachelorDcument] = useState("");
  const [experienceDocuments, setexperienceDcument] = useState("");
  const [nxt, setNxt] = useState(true);
  const [check, setChecked] = useState("");
  const [check2, setChecked2] = useState("");
  const [choice, setChoice] = useState("");
  const [backopen, setBackopen] = useState(false);
  const [qualification, setqualification] = useState("");
  const [isEmailValid, setIsEmailValid] = useState(true);
  const [isyearValid, setIsyearValid] = useState(true);
  const [iscgpaValid, setIscgpaValid] = useState(true);
  const [ispincodeValid, setIspincodeValid] = useState(true);
  const [isexpValid, setIsexpValid] = useState(true);
  const [Total_exp, setExp] = useState("");
  const [industry, setIndustry] = useState("");
  const [industryOther, setIndustryOther] = useState("");
  const [designation, setDesignation] = useState("");
  const [previndustry, setprevIndustry] = useState("");
  const [previndustryOther, setprevIndustryOther] = useState("");
  const [error, setError] = useState(false);
  const [validCmpname, setCmpname] = useState(false);

  const [country, setCountry] = useState();
  const [state, setState] = useState();
  const [city, setCity] = useState();
  const [pincode, setPincode] = useState();
  const [address, setaddress] = useState();
  const [addresstwo, setAddresstwo] = useState("");
  const [country_name, setCountryName] = useState();
  const [cities, setCities] = useState([]);
  const [stateCode, setStatecode] = useState();
  const LoaderClose = () => {
    setBackopen(false);
  };
  const LoaderOpen = () => {
    setBackopen(true);
  };

  const option = [
    {
      value: "Yes",
      label: "Yes",
    },
    { value: "No", label: "No" },
  ];

  const handleChangePincode = (event) => {
    setPincode(event.target.value);
    // setCgpa(event.target.value);
    setIspincodeValid(/^\d*\.?\d*$/.test(event.target.value));
  };
  const handleChangeAddress = (event) => {
    setaddress(event.target.value);
  };
  const handleChangeAddresst = (event) => {
    setAddresstwo(event.target.value);
  };
  const handleChangeCountry = (event) => {
    setCountry(event.target.value);
    setState("");
    setCity("");
  };
  const handleChangeState = (event) => {
    setState(event.target.value);
    setCity("");
  };
  const handleChangeCity = (event) => {
    setCity(event.target.value);
  };
  useEffect(() => {
    const getCities = async () => {
      try {
        const result = await City.getCitiesOfState(country, stateCode);
        let allCities = [];
        allCities = result?.map(({ name }) => ({
          name,
        }));
        // console.log(allCities,"rrrr")
        setCities(allCities);
      } catch (error) {
        setCities([]);
      }
    };

    getCities();
  }, [state, stateCode, country]);
  // console.log(gender,"uuuu")
  useEffect(() => {
    if (country) {
      const country_name = Country.getAllCountries().filter((val) => {
        return val.isoCode == country;
      });
      // console.log(country_name[0].name)
      setCountryName(country_name[0].name);
    }
  }, [country]);
  useEffect(() => {
    if (state) {
      const states = State.getAllStates().filter((val) => {
        return val.name == state;
      });
      // console.log(states[0].isoCode)
      setStatecode(states[0].isoCode);
    }
  }, [state]);

  const handleChangeChoice = (val) => {
    setChoice(val);
    setCompanyName("");
    setCompanyemail("");
    setPreviouscompany("");
    setExp("");
    setIndustry("");
    setDesignation("");
    setIndustryOther("");
    setprevIndustry("");
    setprevIndustryOther("");
    setChecked(false);
    setChecked2(false);
  };
  const handleChangeDegree = (event) => {
    setBachelorDegree(event.target.value);
  };
  const handleChangeDegreeOther = (event) => {
    setBachelorDegreeother(event.target.value);
  };
  // const handleChangeCollegeName = (event) => {
  //   setCollegename(event.target.value);
  // };
  const handleChangeCollegeName = (event) => {
    const regex = /[!@#$%^&*()_+=[\]{};':"\\|,.<>/?~]/;
    if (regex.test(event.target.value)) {
      setError(true);
    } else {
      setError(false);
    }
    setCollegename(event.target.value);
  };
  const handleChangeYear = (event) => {
    // setCompletionyear(event.target.value);
    setCompletionyear(event.target.value);
    setIsyearValid(/^\d*\.?\d*$/.test(event.target.value)&& event.target.value.length<=4);
  };
  const handleChangeCgpa = (event) => {
    // setCgpa(event.target.value);
    setCgpa(event.target.value);
    setIscgpaValid(/^\d*\.?\d*$/.test(event.target.value));
  };
  // const handleChangeCpmanyName = (event) => {
  //   setCompanyName(event.target.value);
  // };
  const handleChangeCpmanyName = (event) => {
    const regex = /[!@#$%^&*()_+=[\]{};':"\\|,.<>/?~]/;
    if (regex.test(event.target.value)) {
      setCmpname(true);
    } else {
      setCmpname(false);
    }
    setCompanyName(event.target.value);
  };
  const handleChangeCompanyemail = (event) => {
    setCompanyemail(event.target.value);
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    setIsEmailValid(emailRegex.test(event.target.value));
  };
  const handleChangePreviousCompany = (event) => {
    setPreviouscompany(event.target.value);
  };
  const handleChangeDesignation = (event) => {
    setDesignation(event.target.value);
  };
  const handleChangePrevIndustry = (event) => {
    setprevIndustry(event.target.value);
  };
  const handleChangePrevIndustryOther = (event) => {
    setprevIndustryOther(event.target.value);
  };
  const handleChangeBachelorDocuments = (event) => {
    const selectedFile = event.target.files[0];
    if (selectedFile && selectedFile.size <= 5000000) {
      setBachelorDcument(selectedFile);
    } else {
      setBachelorDcument(null);
      alert("Please select a file of size not more than 5MB");
    }
    // setBachelorDcument(event.target.files[0]);
  };
  const handleChangeTotalExp = (event) => {
    // setExp(event.target.value);
    setExp(event.target.value);
    setIsexpValid(/^\d*\.?\d*$/.test(event.target.value));
  };
  const handleChangeExperinceDocuments = (event) => {
    const selectedFile = event.target.files[0];
    if (selectedFile && selectedFile.size <= 5000000) {
      setexperienceDcument(selectedFile);
    } else {
      setexperienceDcument(null);
      alert("Please select a file of size not more than 5MB");
    }
    // setexperienceDcument(event.target.files[0]);
  };
  const handleChangequalification = (event) => {
    setqualification(event.target.value);
  };
  const handleChangeIndustry = (event) => {
    setIndustry(event.target.value);
  };
  const handleChangeIndustryOther = (event) => {
    setIndustryOther(event.target.value);
  };

  const handleClickPersonal = () => {
    setOpenPersonal(!openPersonal);
  };
  const handleClickAdd = () => {
    setOpenADD(!openADD);
  };
  const handleClickaddress = () => {
    setOpenaddress(!openaddress);
  };
  const handleChange2 = (event) => {
    setChecked2(event.target.checked);
  };

  useEffect(() => {
    if (
      (error ? false : collegeName) &&
      country_name &&
      state &&
      city &&
      check2 &&
      (ispincodeValid?pincode:false) &&
      address &&
      (isyearValid&&yearOfCompletion<2030?yearOfCompletion:false)&&(iscgpaValid&&cgpa<=100?cgpa:false)&&
      check &&
      qualification &&
      bachelorDocuments &&
      (bachelorDegree == "others" ? bachelorDegreeOther : bachelorDegree) &&
      (((isEmailValid ? companyemail : false) &&
        (validCmpname ? false : companyname) &&
       (isexpValid&&Total_exp<=100?Total_exp:false) &&
        designation &&
        (industry == "others" ? industryOther : industry)) ||
        (previuosCompany &&
         (isexpValid&&Total_exp<=100?Total_exp:false) &&
          experienceDocuments &&
          (previndustry == "others" ? previndustryOther : previndustry)))
    ) {
      setNxt(false);
    } else {
      setNxt(true);
    }
  }, [
    bachelorDegree,
    country_name,
    state,
    city,
    pincode,
    address,
    bachelorDegreeOther,
    collegeName,
    yearOfCompletion,
    cgpa,
    companyname,
    companyemail,
    previuosCompany,
    check,
    check2,
    qualification,
    industry,
    industryOther,
    previndustry,
    previndustryOther,
    designation,
    Total_exp,
    bachelorDocuments,
    experienceDocuments,
  ]);

  const InsertintoSheet = (data) => {
    const formDatab = new FormData();
    // formDatab.append("Sno",sn);
    const originalTimestamp = new Date(data.dob);
const formattedDate = originalTimestamp.toISOString().slice(0, 10);
    formDatab.append("Batch", data.batch);
    formDatab.append("Name", data.name);
    formDatab.append("Email", data.email);
    formDatab.append("Gender", data.gender);
    formDatab.append("Dob", formattedDate);
    formDatab.append("Phone", data.phone);
    formDatab.append("City", data.city);
    formDatab.append("Workex", data.experience);
    formDatab.append("Education", data.highest_education);
    formDatab.append("Company", data.company);
    formDatab.append("Designation", data.designation);
    formDatab.append("Industry", data.industry);
    formDatab.append("WorkexLink", data.company_doc_file_path);
    formDatab.append("Educationlink", data.degree_file_path);
    formDatab.append("LMS", "Yes");
    formDatab.append("Enrollment", "Yes");

    fetch(props.sheetURL, {
      method: "POST",
      body: formDatab,
    })
      .then((res) => {
        axios({
          method: "post",
          url: `${process.env.REACT_APP_NODE_URL}/api/v1/paymentPortal/updateLastStepStatus`,
          data: {
            type: "update_last_step_status",
            user_id: parseInt(localStorage.getItem("user_id")),
            university: localStorage.getItem("university"),
          },
        })
          .then((res) => {
            localStorage.setItem(
              "currentStep",
              res.data.data.current_step_count
            );
            localStorage.setItem(
              "currentStepStatus",
              res.data.data.current_step_status
            );
          })
          .catch((err) => {
            console.log(err);
          });

        res.json();
      })
      .then((data) => {
        console.log(data);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const handleNext = () => {
    if (
      (bachelorDegree || bachelorDegreeOther) &&
      collegeName &&
      yearOfCompletion &&
      cgpa &&
      bachelorDocuments &&
      qualification
      // bachelorDocuments
    ) {
      LoaderOpen();
      var formData = new FormData();
      formData.append("education_doc", bachelorDocuments);
      // ? :/
      if (choice == "No") {
        formData.append("work_doc", experienceDocuments);
      }
      formData.append("type", "insert_student_details");
      formData.append(
        "degree",
        bachelorDegree == "others" ? bachelorDegreeOther : bachelorDegree
      );
      formData.append("qualification", qualification);
      formData.append("email", localStorage.getItem("email"));
      formData.append("college_name", collegeName);
      formData.append("completion_year", yearOfCompletion);
      formData.append("cgpa", cgpa);
      formData.append("country", country_name);
      formData.append("state", state);
      formData.append("city", city);
      formData.append("pincode", parseInt(pincode));
      formData.append("address", address);
      formData.append("addresstwo", addresstwo);
      formData.append(
        "company_name",
        choice == "Yes" ? companyname : previuosCompany
      );
      formData.append("company_email", companyemail);
      formData.append("designation", designation);
      choice == "Yes"
        ? formData.append(
            "current_industry",
            industry == "others" ? industryOther : industry
          )
        : formData.append(
            "previous_industry",
            previndustry == "others" ? previndustryOther : previndustry
          );
      // formData.append("current_industry", cgpa);
      formData.append("experience", Total_exp);
      formData.append("working", choice.toLowerCase());
      formData.append("user_id", parseInt(localStorage.getItem("user_id")));
      formData.append("university", localStorage.getItem("university"));

      axios({
        method: "post",
        url: `${process.env.REACT_APP_NODE_URL}/api/v1/fileUplaod/insertStudentDetails`,
        headers: { "Content-Type": "multipart/form-data" },
        data: formData,
      })
        .then(function (response) {
          if (response.status == 200) {
            axios({
              method: "post",
              url: `${process.env.REACT_APP_NODE_URL}/api/v1/pushTogenerateApplication`,
              data: {
                user_id: localStorage.getItem("user_id"),
                category_id: localStorage.getItem("category"),
                fullname:localStorage.getItem("firstname")+" "+localStorage.getItem("lastname"),
                university:localStorage.getItem("university"),
                email:localStorage.getItem("email")
              },

              // options
            })
              .then((res) => {
                if (res.data.status == 200) {
                  LoaderClose();
                  props.handleNext();
                  localStorage.setItem(
                    "currentStep",
                    res.data.current_step_count
                  );
                  localStorage.setItem(
                    "currentStepStatus",
                    res.data.current_step_status
                  );
                  axios({
                    method: "post",
                    url: `${process.env.REACT_APP_NODE_URL}/api/v1/paymentPortal/fetchSheetData`,
                    data: {
                      type: "fetch_sheet_data",
                      user_id: parseInt(localStorage.getItem("user_id")),
                      category: localStorage.getItem("category"),
                      university: localStorage.getItem("university"),
                      email:localStorage.getItem("email"),
                    },
                  })
                    .then((res) => {
                      // console.log(res)
                      if (res.data.statusCode == 200) {
                        InsertintoSheet(res.data.data[0]);
                      }
                      else{
                        LoaderClose();
                Swal.fire({
                  title: "Oops!",
                  text: props.elseErrormessage,
                  icon: "error",
                  confirmButtonText: "OK",
                });
                      }
                    })
                    .catch((err) => {
                      LoaderClose();
                      Swal.fire({
                        title: "Oops!",
                        text: props.catchErrorMessage,
                        icon: "error",
                        confirmButtonText: "OK",
                      });
                    });
                }
                else{
                  LoaderClose();
          Swal.fire({
            title: "Oops!",
            text: props.elseErrormessage,
            icon: "error",
            confirmButtonText: "OK",
          });
                }
              })
              .catch((err) => {
                LoaderClose();
                Swal.fire({
                  title: "Oops!",
                  text: props.catchErrorMessage,
                  icon: "error",
                  confirmButtonText: "OK",
                });
              });
          }
        })
        .catch((err) => {
          LoaderClose();
          Swal.fire({
            title: "Oops!",
            text: props.catchErrorMessage,
            icon: "error",
            confirmButtonText: "OK",
          });
        });
    } else {
      toast.error("Please Enter all the required feilds", {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    }
  };

  const handleChange = (event) => {
    setChecked(event.target.checked);
  };
  // const choice=""
  return (
    <>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={backopen}
      >
        <Box sx={{ display: "flex" }}>
          <CircularProgress color="inherit" />
          <Typography sx={{ ml: 2 }}>
            Please wait while we process your application form.
          </Typography>
        </Box>
      </Backdrop>
      <Box
        sx={{ display: { xs: "none", lg: "block", sm: "none", md: "none" } }}
      >
        <Box
          sx={{
            display: "grid",
            placeItems: "center",
            p: 3,
            width: "95%",
            borderRadius: "15px",

            overflowY: "scroll",
            height: 350,
            "&::-webkit-scrollbar": {
              width: "0.2em",
            },
            "&::-webkit-scrollbar-track": {
              boxShadow: "inset 0 0 6px silver",
              webkitBoxShadow: "inset 0 0 6px silver",
            },
            "&::-webkit-scrollbar-thumb": {
              backgroundColor: "silver",
              outline: "1px solid silver",
            },
          }}
        >
          <Box
            sx={{
              borderRadius: "8px",
              p: 1.5,
              background: "#fff",
              backgroundColor: "rgba(255, 255, 255, .60)",
              // pl: 4,
              // pr: 4,

              ml: 5,
              backdropFilter: "blur(5px)",
              // display: "grid",
              placeContent: "center",
              border: "1px solid #002E5C",
              width: "82%",
            }}
          >
            {/* <Typography sx={{fontWeight:"bold",fontSize:"20px",textAlign:"left",mb:2}}>Education and Work Details</Typography> */}
            <Box sx={{ p: 1.5 }}>
              <Box
                sx={{ mb: 0.5, p: 1, borderRadius: "5px" }}
                // onClick={handleClickaddress}
              >
                <Typography
                  sx={{
                    color: "#000",
                    fontWeight: "bold",
                    textAlign: "center",
                    fontSize: "23px",
                  }}
                >
                  Address Details
                </Typography>
              </Box>

              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  mt: 1,
                  mb: 1,
                }}
              >
                <Box>
                  <Typography
                    sx={{
                      fontWeight: "bold",
                      fontSize: "14px",
                      color: "#000",
                      textAlign: "left",
                    }}
                  >
                    Country <span style={{ color: "red" }}>*</span>{" "}
                  </Typography>
                  <FormControl
                    fullWidth
                    sx={{ boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)" }}
                  >
                    <Select
                      size="small"
                      sx={{ width: 427, background: "#fff", textAlign: "left" }}
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={country}
                      // defaultValue={countryCode}
                      label="Gender"
                      onChange={handleChangeCountry}
                    >
                      {Country.getAllCountries().map((val, i) => (
                        <MenuItem value={val.isoCode}>{val.name}</MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Box>
                <Box>
                  <Typography
                    sx={{
                      fontWeight: "bold",
                      fontSize: "14px",
                      color: "#000",
                      textAlign: "left",
                    }}
                  >
                    State <span style={{ color: "red" }}>*</span>
                  </Typography>
                  <FormControl
                    fullWidth
                    sx={{ boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)" }}
                  >
                    <Select
                      size="small"
                      sx={{ width: 427, background: "#fff", textAlign: "left" }}
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={state}
                      label="Gender"
                      onChange={handleChangeState}
                      // renderValue={(selected) => selected.map(obj=> State[obj - 1].value).join(", ")}
                    >
                      {State.getAllStates()
                        .filter((val) => {
                          return val.countryCode == country;
                        })
                        .sort()
                        .map((val) => (
                          <MenuItem value={val.name}>{val.name}</MenuItem>
                        ))}
                    </Select>
                  </FormControl>
                </Box>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  mt: 1,
                  mb: 1,
                }}
              >
                <Box>
                  <Typography
                    sx={{
                      fontWeight: "bold",
                      fontSize: "14px",
                      color: "#000",
                      textAlign: "left",
                    }}
                  >
                    City <span style={{ color: "red" }}>*</span>
                  </Typography>

                  <FormControl
                    fullWidth
                    sx={{ boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)" }}
                  >
                    <Select
                      size="small"
                      sx={{ width: 427, background: "#fff", textAlign: "left" }}
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={city}
                      label="Gender"
                      onChange={handleChangeCity}
                    >
                      {cities.map((val) => (
                        <MenuItem value={val.name}>{val.name}</MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Box>
                <Box>
                  <Typography
                    sx={{
                      fontWeight: "bold",
                      fontSize: "14px",
                      color: "#000",
                      textAlign: "left",
                    }}
                  >
                    Pincode <span style={{ color: "red" }}>*</span>
                  </Typography>
                  {/* <BootstrapInput
                    onChange={handleChangePincode}
                    value={pincode}
                    type="number"
                    // sx={{ width: 480 }}
                    size="small"
                  /> */}
                   <TextField
                    type="text"
                    error={!ispincodeValid}
                    helperText={!ispincodeValid ? 'Please enter only number' : ''}
                    
                    onChange={handleChangePincode}
                    value={pincode}
                    sx={{ width: 427, background: "#fff", borderRadius: "5px" }}
                    size="small"
                  />
                </Box>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  mt: 1,
                  mb: 1,
                }}
              >
                <Box>
                  <Typography
                    sx={{
                      fontWeight: "bold",
                      fontSize: "14px",
                      color: "#000",
                      textAlign: "left",
                    }}
                  >
                    Address 1<span style={{ color: "red" }}>*</span>
                  </Typography>
                  <BootstrapInput
                    onChange={handleChangeAddress}
                    value={address}
                    sx={{ width: 480 }}
                    size="small"
                    type="text"
                  />
                </Box>
                <Box>
                  <Typography
                    sx={{
                      fontWeight: "bold",
                      fontSize: "14px",
                      color: "#000",
                      textAlign: "left",
                    }}
                  >
                    Address 2{" "}
                  </Typography>
                  <BootstrapInput
                    onChange={handleChangeAddresst}
                    value={addresstwo}
                    // sx={{ width: 480 }}
                    size="small"
                    type="text"
                  />
                </Box>
              </Box>
              <Box sx={{ mb: 1 }}>
                <Typography
                  sx={{
                    color: "#000",
                    fontWeight: "bold",
                    textAlign: "center",
                    fontSize: "23px",
                  }}
                >
                  Education Details
                </Typography>
              </Box>

              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  mt: 1,
                  mb: 1,
                }}
              >
                <Box>
                  <Typography
                    sx={{
                      fontWeight: "bold",
                      fontSize: "14px",
                      color: "#000",
                      textAlign: "left",
                    }}
                  >
                    Highest Qualification{" "}
                    <span style={{ color: "red" }}>*</span>
                  </Typography>
                  <FormControl
                    fullWidth
                    sx={{ boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)" }}
                  >
                    {/* <InputLabel id="demo-simple-select-label">Payment Type</InputLabel> */}
                    <Select
                      size="small"
                      sx={{ width: 427, background: "#fff", textAlign: "left" }}
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      //  value={bachelorDegree}
                      onChange={handleChangequalification}
                      defaultValue={"select"}
                    >
                      <MenuItem disabled="true" value={"select"}>
                        Select your Qualification
                      </MenuItem>
                      <MenuItem value={"High School Diploma"}>
                        High School Diploma
                      </MenuItem>
                      <MenuItem value={"Associate Degree"}>
                        Associate Degree
                      </MenuItem>
                      <MenuItem value={"Bachelor Degree"}>
                        Bachelor Degree
                      </MenuItem>
                      <MenuItem value={"Master Degree"}>Master Degree</MenuItem>
                      <MenuItem value={"Doctorate/Ph.D"}>
                        Doctorate/Ph.D
                      </MenuItem>

                      {/* <MenuItem value={30}>Others</MenuItem> */}
                    </Select>
                  </FormControl>
                  {/* {name?"":<Typography sx={{color:"red"}}>This Filed is Required</Typography>} */}
                </Box>

                <Box>
                  <Typography
                    sx={{
                      fontWeight: "bold",
                      fontSize: "14px",
                      color: "#000",
                      textAlign: "left",
                    }}
                  >
                    Degree Name
                    <span style={{ color: "red" }}>*</span>
                  </Typography>
                  {/* <FormControl
                    fullWidth
                    sx={{ boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)" }}
                  >
                   
                    <Select
                      size="small"
                      sx={{ width: 427, background: "#fff", textAlign: "left" }}
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={bachelorDegree}
                      onChange={handleChangeDegree}
                      defaultValue={"select"}
                    >
                      <MenuItem disabled="true" value={"select"}>
                        Select your degree
                      </MenuItem>
                      <MenuItem value={"B.Tech"}>B.Tech</MenuItem>
                      <MenuItem value={"B.Voc"}>B.Voc</MenuItem>
                      <MenuItem value={"B.Sc"}>B.Sc</MenuItem>
                      <MenuItem value={"B.S"}>B.S</MenuItem>
                      <MenuItem value={"BPharm"}>BPharm</MenuItem>
                      <MenuItem value={"B.com"}>B.com</MenuItem>
                      <MenuItem value={"BBA"}>BBA</MenuItem>
                      <MenuItem value={"BBA Honors"}>BBA Honors</MenuItem>
                      <MenuItem value={"BCA"}>BCA</MenuItem>
                      <MenuItem value={"BArch"}>BArch</MenuItem>
                      <MenuItem value={"B.L.L.A.B"}>B.L.L.A.B</MenuItem>
                      <MenuItem value={"others"}>Others</MenuItem>
                     
                    </Select>
                  </FormControl> */}
                  <FormControl
                    fullWidth
                    sx={{ boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)" }}
                  >
                    {qualification == "Master Degree" ? (
                      <Select
                        size="small"
                        sx={{
                          width: 427,
                          background: "#fff",
                          textAlign: "left",
                        }}
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={bachelorDegree}
                        onChange={handleChangeDegree}
                        defaultValue={"select"}
                      >
                        <MenuItem disabled="true" value={"select"}>
                          Select your degree
                        </MenuItem>
                        {/* <MenuItem disabled="true" value={"select"}>
                         Select your degree
                       </MenuItem> */}
                        <MenuItem value={"MBA"}>MBA</MenuItem>
                        <MenuItem value={"MS"}>MS</MenuItem>
                        <MenuItem value={"M.Tech"}>M.Tech</MenuItem>
                        <MenuItem value={"MA"}>MA</MenuItem>
                        <MenuItem value={"MEd"}>MEd</MenuItem>
                        <MenuItem value={"MCA"}>MCA</MenuItem>
                        <MenuItem value={"M.Arch"}>M.Arch</MenuItem>
                        <MenuItem value={"LLM"}>LLM</MenuItem>
                        <MenuItem value={"others"}>Others</MenuItem>
                      </Select>
                    ) : (
                      <Select
                        size="small"
                        sx={{
                          width: 427,
                          background: "#fff",
                          textAlign: "left",
                        }}
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={bachelorDegree}
                        onChange={handleChangeDegree}
                        defaultValue={"select"}
                      >
                        <MenuItem value={"B.Voc"}>B.Voc</MenuItem>
                        <MenuItem value={"B.Sc"}>B.Sc</MenuItem>
                        <MenuItem value={"B.S"}>B.S</MenuItem>
                        <MenuItem value={"BPharm"}>BPharm</MenuItem>
                        <MenuItem value={"B.com"}>B.com</MenuItem>
                        <MenuItem value={"BBA"}>BBA</MenuItem>
                        <MenuItem value={"BBA Honors"}>BBA Honors</MenuItem>
                        <MenuItem value={"BCA"}>BCA</MenuItem>
                        <MenuItem value={"BArch"}>BArch</MenuItem>
                        <MenuItem value={"B.L.L.A.B"}>B.L.L.A.B</MenuItem>
                        <MenuItem value={"others"}>Others</MenuItem>
                      </Select>
                    )}
                  </FormControl>
                  {bachelorDegree == "others" ? (
                    <Box sx={{ mt: 1 }}>
                      <TextField
                        placeholder="Enter Your Degree Name"
                        onChange={handleChangeDegreeOther}
                        value={bachelorDegreeOther}
                        sx={{
                          width: 425,
                          background: "#fff",
                          borderRadius: "5px",
                        }}
                        size="small"
                        //  onKeyPress={(e) => {
                        //     if (new RegExp(/[a-zA-Z]/).test(e.key)) {
                        //     } else e.preventDefault();
                        //   }}
                      />
                    </Box>
                  ) : (
                    <Box></Box>
                  )}
                  {/* {name?"":<Typography sx={{color:"red"}}>This Filed is Required</Typography>} */}
                </Box>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  mt: 1,
                  mb: 1,
                }}
              >
                <Box>
                  <Typography
                    sx={{
                      fontWeight: "bold",
                      fontSize: "14px",
                      color: "#000",
                      textAlign: "left",
                    }}
                  >
                    College/University Name{" "}
                    <span style={{ color: "red" }}>*</span>
                  </Typography>
                  {/* <BootstrapInput
                    onChange={handleChangeCollegeName}
                    value={collegeName}
                      sx={{ width: 480 }}
                    size="small"
                    //  onKeyPress={(e) => {
                    //     if (new RegExp(/[a-zA-Z]/).test(e.key)) {
                    //     } else e.preventDefault();
                    //   }}
                  /> */}
                  <TextField
                    onChange={handleChangeCollegeName}
                    value={collegeName}
                    sx={{ width: 425, background: "#fff", borderRadius: "5px" }}
                    size="small"
                    error={error}
                    // onKeyPress={handleKeyPress}
                    helperText={
                      error ? "Special characters are not allowed" : ""
                    }
                    //  onKeyPress={(e) => {
                    //     if (new RegExp(/[a-zA-Z]/).test(e.key)) {
                    //     } else e.preventDefault();
                    //   }}
                  />
                  {/* {name?"":<Typography sx={{color:"red"}}>This Filed is Required</Typography>} */}
                </Box>
                <Box>
                  <Typography
                    sx={{
                      fontWeight: "bold",
                      fontSize: "14px",
                      color: "#000",
                      textAlign: "left",
                    }}
                  >
                    Year of Completion<span style={{ color: "red" }}>* <i style={{ color: "#000", fontSize: "12px" }}>
                        (only in number)
                      </i></span>
                  </Typography>
                  {/* <BootstrapInput
                    type="number"
                    // sx={{ width: 480 }}
                    size="small"
                    onChange={handleChangeYear}
                    value={yearOfCompletion}
                  /> */}
                   <TextField
                    type="text"
                    error={!isyearValid||(yearOfCompletion&&yearOfCompletion>=2030)}
                    helperText={!isyearValid ? 'Please enter a valid year':yearOfCompletion<2030?'':'Please enter a valid year' }
                    
                    
                    onChange={handleChangeYear}
                    value={yearOfCompletion}
                    sx={{ width: 427, background: "#fff", borderRadius: "5px" }}
                    size="small"
                  />
                </Box>
              </Box>

              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  mt: 1,
                  mb: 1,
                }}
              >
                <Box>
                  <Typography
                    sx={{
                      fontWeight: "bold",
                      fontSize: "14px",
                      color: "#000",
                      textAlign: "left",
                    }}
                  >
                    Degree % / CGPA
                    <span style={{ color: "red" }}>*</span>
                  </Typography>
                  {/* <BootstrapInput
                    type="number"
                    // sx={{ width: 480 }}
                    size="small"
                    onChange={handleChangeCgpa}
                    value={cgpa}
                  /> */}
                   <TextField
                    type="text"
                    error={!iscgpaValid || (cgpa&&cgpa>100)}
                    helperText={!iscgpaValid ? 'Please enter only number' : cgpa<=100?'':'Please enter a CGPA/percentage less than 100.'}
                    
                    
                    onChange={handleChangeCgpa}
                    value={cgpa}
                    sx={{ width: 427, background: "#fff", borderRadius: "5px" }}
                    size="small"
                  />
                </Box>

                <Box>
                  <Typography
                    sx={{
                      fontWeight: "bold",
                      fontSize: "14px",
                      color: "#000",
                      textAlign: "left",
                    }}
                  >
                    Upload Relevant Document
                    <span style={{ color: "red" }}>
                      *
                      <i style={{ color: "#000", fontSize: "12px" }}>
                        (pdf format)
                      </i>
                    </span>
                  </Typography>
                  <TextField
                    inputProps={{
                      accept: "application/pdf",
                      // onChange: handleFileChange,
                    }}
                    onChange={handleChangeBachelorDocuments}
                    size="small"
                    sx={{ width: 427, background: "#fff", borderRadius: "4px" }}
                    fullWidth
                    type="file"
                  ></TextField>
                </Box>
              </Box>

              <Box sx={{ mb: 0.5, p: 1, borderRadius: "5px" }}>
                <Typography
                  sx={{
                    color: "#000",
                    fontWeight: "bold",
                    textAlign: "center",
                    fontSize: "23px",
                  }}
                >
                  Work Experience Details
                </Typography>
              </Box>

              <Box sx={{ display: "flex", mb: 2 }}>
                <Typography
                  sx={{
                    fontWeight: "bold",
                    fontSize: "14px",
                    color: "#000",
                    mt: 2,
                    mb: 1,
                    width: 300,
                  }}
                >
                  Are you currently working?
                </Typography>
                <FormControl
                  sx={{ boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)" }}
                >
                  <Select
                    size="small"
                    sx={{
                      width: 427,
                      background: "#fff",
                      mt: 1,
                      textAlign: "left",
                      borderRadius: "5px",
                    }}
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    // value={choice}
                    // onChange={handleChangeChoice}
                    placeholder="Select an Option"
                    defaultValue={"select"}
                  >
                    <MenuItem disabled value={"select"}>
                      <i>Select an Option</i>
                    </MenuItem>
                    {option.map((val) => (
                      <MenuItem
                        onClick={() => {
                          handleChangeChoice(val.value);
                        }}
                        value={val.value}
                      >
                        {val.label}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Box>
              {choice == "" ? (
                <Box></Box>
              ) : (
                <Box>
                  {choice == "Yes" ? (
                    <Box>
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                          mt: 1,
                          mb: 1,
                        }}
                      >
                        <Box>
                          <Typography
                            sx={{
                              fontWeight: "bold",
                              fontSize: "14px",
                              color: "#000",
                              textAlign: "left",
                            }}
                          >
                            Total Years of Experience(Full-time only){" "}
                            <span style={{ color: "red" }}>*</span>
                          </Typography>
                          {/* <TextField
                            type="number"
                            onChange={handleChangeTotalExp}
                            value={Total_exp}
                            sx={{
                              width: { xs: 280, lg: 427 },
                              background: "#fff",
                              borderRadius: "5px",
                            }}
                            size="small"
                          /> */}
                          <TextField
                    type="text"
                    error={!isexpValid||(Total_exp&&Total_exp>100)}
                    helperText={!isexpValid ? 'Please enter only number' : Total_exp<=100?'':'Please enter a valid experience'}
                    
                    
                    onChange={handleChangeTotalExp}
                    value={Total_exp}
                    sx={{ width: 427, background: "#fff", borderRadius: "5px" }}
                    size="small"
                  />
                        </Box>

                        <Box>
                          <Typography
                            sx={{
                              fontWeight: "bold",
                              fontSize: "14px",
                              color: "#000",
                              textAlign: "left",
                            }}
                          >
                            Current Industry
                            <span style={{ color: "red" }}>*</span>
                          </Typography>
                          <FormControl fullWidth>
                            {/* <InputLabel id="demo-simple-select-label">Payment Type</InputLabel> */}
                            <Select
                              size="small"
                              sx={{
                                width: 427,
                                background: "#fff",
                                textAlign: "left",
                              }}
                              labelId="demo-simple-select-label"
                              id="demo-simple-select"
                              value={industry}
                              onChange={handleChangeIndustry}
                              defaultValue={"select"}
                            >
                              <MenuItem disabled="true" value={"select"}>
                                Select your Current Industry
                              </MenuItem>
                              <MenuItem value={"Automotive"}>
                                Automotive
                              </MenuItem>
                              <MenuItem
                                value={"Banking and Financial Services"}
                              >
                                Banking and Financial Services
                              </MenuItem>
                              <MenuItem value={"Construction and Engineering"}>
                                Construction and Engineering
                              </MenuItem>
                              <MenuItem value={"Consumer Goods and Retail"}>
                                Consumer Goods and Retail
                              </MenuItem>
                              <MenuItem value={"Energy and Utilities"}>
                                Energy and Utilities
                              </MenuItem>
                              <MenuItem value={"Food and Beverage"}>
                                Food and Beverage
                              </MenuItem>
                              <MenuItem
                                value={"Healthcare and Pharmaceuticals"}
                              >
                                Healthcare and Pharmaceuticals
                              </MenuItem>
                              <MenuItem
                                value={"Information Technology and Services"}
                              >
                                Information Technology and Services
                              </MenuItem>
                              <MenuItem value={"Insurance"}>Insurance</MenuItem>
                              <MenuItem value={"Manufacturing"}>
                                Manufacturing
                              </MenuItem>
                              <MenuItem value={"Media and Entertainment"}>
                                Media and Entertainment
                              </MenuItem>
                              <MenuItem value={"Professional Services"}>
                                Professional Services
                              </MenuItem>
                              <MenuItem value={"Real Estate"}>
                                Real Estate
                              </MenuItem>
                              <MenuItem value={"Transportation and Logistics"}>
                                Transportation and Logistics
                              </MenuItem>
                              <MenuItem value={"Travel and Hospitality"}>
                                Travel and Hospitality
                              </MenuItem>
                              <MenuItem value={"others"}>Others</MenuItem>
                              {/* <MenuItem value={30}>Others</MenuItem> */}
                            </Select>
                          </FormControl>
                          {industry == "others" ? (
                            <Box sx={{ mt: 1 }}>
                              <TextField
                                sx={{
                                  width: 425,
                                  background: "#fff",
                                  borderRadius: "5px",
                                }}
                                placeholder="Enter Your Current Industry"
                                onChange={handleChangeIndustryOther}
                                value={industryOther}
                                //   sx={{ width: 480 }}
                                size="small"
                                //  onKeyPress={(e) => {
                                //     if (new RegExp(/[a-zA-Z]/).test(e.key)) {
                                //     } else e.preventDefault();
                                //   }}
                              />
                            </Box>
                          ) : (
                            <Box></Box>
                          )}
                          {/* {name?"":<Typography sx={{color:"red"}}>This Filed is Required</Typography>} */}
                        </Box>
                      </Box>
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                          mt: 1,
                          mb: 1,
                        }}
                      >
                        <Box>
                          <Typography
                            sx={{
                              fontWeight: "bold",
                              fontSize: "14px",
                              color: "#000",
                              textAlign: "left",
                            }}
                          >
                            Current Company Name{" "}
                            <span style={{ color: "red" }}>*</span>
                          </Typography>
                          {/* <BootstrapInput
                          onChange={handleChangeCpmanyName}
                          value={companyname}
                          sx={{ width: 480 }}
                          size="small"
                          // onKeyPress={(e) => {
                          //   if (new RegExp(/[a-zA-Z]/).test(e.key)) {
                          //   } else e.preventDefault();
                          // }}
                        /> */}
                          <TextField
                            onChange={handleChangeCpmanyName}
                            value={companyname}
                            sx={{
                              width: 425,
                              background: "#fff",
                              borderRadius: "5px",
                            }}
                            size="small"
                            error={validCmpname}
                            // onKeyPress={handleKeyPress}
                            helperText={
                              validCmpname
                                ? "Special characters are not allowed"
                                : ""
                            }
                            //  onKeyPress={(e) => {
                            //     if (new RegExp(/[a-zA-Z]/).test(e.key)) {
                            //     } else e.preventDefault();
                            //   }}
                          />
                        </Box>
                        <Box>
                          <Typography
                            sx={{
                              fontWeight: "bold",
                              fontSize: "14px",
                              color: "#000",
                              textAlign: "left",
                            }}
                          >
                            Current Designation
                            <span style={{ color: "red" }}>*</span>
                          </Typography>
                          <BootstrapInput
                            onChange={handleChangeDesignation}
                            value={designation}
                            // sx={{ width:480}}
                            size="small"
                            // onKeyPress={(e) => {
                            //   if (new RegExp(/[a-zA-Z]/).test(e.key)) {
                            //   } else e.preventDefault();
                            // }}
                          />
                        </Box>
                      </Box>
                      <Box>
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "space-between",
                            mt: 1,
                            mb: 1,
                          }}
                        >
                          <Box sx={{ mb: 1 }}>
                            <Typography
                              sx={{
                                fontWeight: "bold",
                                fontSize: "14px",
                                color: "#000",
                                textAlign: "left",
                              }}
                            >
                              Company Email Id{" "}
                              <span style={{ color: "red" }}>*</span>
                            </Typography>
                            {/* <TextField
                          type="email"
                          onChange={handleChangeCompanyemail}
                          value={companyemail}
                          sx={{
                            width: 427,
                            background: "#fff",
                            borderRadius: "5px",
                          }}
                          size="small"
                        /> */}
                            <TextField
                              type="email"
                              error={!isEmailValid}
                              helperText={
                                !isEmailValid ? "Invalid email format" : ""
                              }
                              InputProps={{
                                endAdornment: (
                                  <InputAdornment position="end">
                                    <EmailIcon
                                      color={!isEmailValid ? "error" : "action"}
                                    />
                                  </InputAdornment>
                                ),
                              }}
                              onChange={handleChangeCompanyemail}
                              value={companyemail}
                              sx={{
                                width: 427,
                                background: "#fff",
                                borderRadius: "5px",
                              }}
                              size="small"
                            />
                          </Box>
                        </Box>
                        <Box sx={{ mt: 2 }}>
                          <FormGroup sx={{ mb: 1 }}>
                            {/* <FormControlLabel control={<Checkbox defaultChecked />} label="Label" /> */}
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={check}
                                  onChange={handleChange}
                                />
                              }
                              label={
                                <div
                                  style={{
                                    color: "#000",
                                    fontWeight: "bold",
                                    fontSize: "14px",
                                  }}
                                >
                                  I accept all the terms and conditions{" "}
                                  <a
                                    href={props.policy}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    style={{
                                      color: "blue",
                                      cursor: "pointer",
                                      textDecoration: "none",
                                    }}
                                    //  className={classes.hyperlink}
                                  >
                                    (Read more)
                                    <span style={{ color: "red" }}>*</span>
                                  </a>
                                </div>
                              }
                            />
                            {/* <FormControlLabel disabled control={<Checkbox />} label="Disabled" /> */}
                          </FormGroup>
                          <FormGroup sx={{ display: "flex", mt: 1 }}>
                            {/* <FormControlLabel control={<Checkbox defaultChecked />} label="Label" /> */}
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={check2}
                                  onChange={handleChange2}
                                />
                              }
                              label={
                                <div
                                  style={{
                                    color: "#000",
                                    fontWeight: "bold",
                                    fontSize: "14px",
                                  }}
                                >
                                  I confirm that I have gone through the program
                                  and it’s details and the program fits my
                                  career objective
                                  <span style={{ color: "red" }}>*</span>{" "}
                                  <a
                                    href={props.inclusion}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    style={{
                                      color: "blue",
                                      cursor: "pointer",
                                      textDecoration: "none",
                                    }}
                                    //  className={classes.hyperlink}
                                  >
                                    (Read more)
                                  </a>
                                </div>
                              }
                            />

                            {/* <FormControlLabel disabled control={<Checkbox />} label="Disabled" /> */}
                          </FormGroup>
                          <FormGroup sx={{display:"flex",mt:1}}>
      {/* <FormControlLabel control={<Checkbox defaultChecked />} label="Label" /> */}
      <FormControlLabel  control={<Checkbox checked={true}   />}  label={
         <div style={{color:"#000",fontWeight:"bold",fontSize:"14px"}}>
        I give consent to receive communications through WhatsApp and SMS.<span style={{color:"red"}}>*</span>{' '}
       </div>
      } />
     
      {/* <FormControlLabel disabled control={<Checkbox />} label="Disabled" /> */}
    </FormGroup>
                        </Box>
                      </Box>
                    </Box>
                  ) : (
                    <Box>
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                          mt: 1,
                          mb: 1,
                        }}
                      >
                        <Box>
                          <Typography
                            sx={{
                              fontWeight: "bold",
                              fontSize: "14px",
                              color: "#000",
                              textAlign: "left",
                            }}
                          >
                            Total Years of Experience(Full-time only){" "}
                            <span style={{ color: "red" }}>*</span>
                          </Typography>
                          {/* <TextField
                            type="number"
                            onChange={handleChangeTotalExp}
                            value={Total_exp}
                            sx={{
                              width: { xs: 280, lg: 427 },
                              background: "#fff",
                              borderRadius: "5px",
                            }}
                            size="small"
                          /> */}
                          <TextField
                    type="text"
                    error={!isexpValid||(Total_exp&&Total_exp>100)}
                    helperText={!isexpValid ? 'Please enter only number' : Total_exp<=100?'':'Please enter a valid experience'}
                    
                    
                    onChange={handleChangeTotalExp}
                    value={Total_exp}
                    sx={{ width: 427, background: "#fff", borderRadius: "5px" }}
                    size="small"
                  />
                        </Box>
                        <Box>
                          <Typography
                            sx={{
                              fontWeight: "bold",
                              fontSize: "14px",
                              color: "#000",
                              textAlign: "left",
                            }}
                          >
                            Previous Industry
                            <span style={{ color: "red" }}>*</span>
                          </Typography>
                          <FormControl fullWidth>
                            {/* <InputLabel id="demo-simple-select-label">Payment Type</InputLabel> */}
                            <Select
                              size="small"
                              sx={{
                                width: 427,
                                background: "#fff",
                                textAlign: "left",
                              }}
                              labelId="demo-simple-select-label"
                              id="demo-simple-select"
                              value={previndustry}
                              onChange={handleChangePrevIndustry}
                              defaultValue={"select"}
                            >
                              <MenuItem disabled="true" value={"select"}>
                                Select your Previous Industry
                              </MenuItem>
                              <MenuItem value={"Automotive"}>
                                Automotive
                              </MenuItem>
                              <MenuItem
                                value={"Banking and Financial Services"}
                              >
                                Banking and Financial Services
                              </MenuItem>
                              <MenuItem value={"Construction and Engineering"}>
                                Construction and Engineering
                              </MenuItem>
                              <MenuItem value={"Consumer Goods and Retail"}>
                                Consumer Goods and Retail
                              </MenuItem>
                              <MenuItem value={"Energy and Utilities"}>
                                Energy and Utilities
                              </MenuItem>
                              <MenuItem value={"Food and Beverage"}>
                                Food and Beverage
                              </MenuItem>
                              <MenuItem
                                value={"Healthcare and Pharmaceuticals"}
                              >
                                Healthcare and Pharmaceuticals
                              </MenuItem>
                              <MenuItem
                                value={"Information Technology and Services"}
                              >
                                Information Technology and Services
                              </MenuItem>
                              <MenuItem value={"Insurance"}>Insurance</MenuItem>
                              <MenuItem value={"Manufacturing"}>
                                Manufacturing
                              </MenuItem>
                              <MenuItem value={"Media and Entertainment"}>
                                Media and Entertainment
                              </MenuItem>
                              <MenuItem value={"Professional Services"}>
                                Professional Services
                              </MenuItem>
                              <MenuItem value={"Real Estate"}>
                                Real Estate
                              </MenuItem>
                              <MenuItem value={"Transportation and Logistics"}>
                                Transportation and Logistics
                              </MenuItem>
                              <MenuItem value={"Travel and Hospitality"}>
                                Travel and Hospitality
                              </MenuItem>
                              <MenuItem value={"others"}>Others</MenuItem>
                              {/* <MenuItem value={30}>Others</MenuItem> */}
                            </Select>
                          </FormControl>
                          {previndustry == "others" ? (
                            <Box sx={{ mt: 1 }}>
                              <TextField
                                sx={{
                                  width: 425,
                                  background: "#fff",
                                  borderRadius: "5px",
                                }}
                                placeholder="Enter Your Previous Industry"
                                onChange={handleChangePrevIndustryOther}
                                value={previndustryOther}
                                //   sx={{ width: 480 }}
                                size="small"
                                //  onKeyPress={(e) => {
                                //     if (new RegExp(/[a-zA-Z]/).test(e.key)) {
                                //     } else e.preventDefault();
                                //   }}
                              />
                            </Box>
                          ) : (
                            <Box></Box>
                          )}
                          {/* {name?"":<Typography sx={{color:"red"}}>This Filed is Required</Typography>} */}
                        </Box>
                      </Box>
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                          mt: 1,
                          mb: 1,
                        }}
                      >
                        <Box>
                          <Typography
                            sx={{
                              fontWeight: "bold",
                              fontSize: "14px",
                              color: "#000",
                              textAlign: "left",
                              ml: 0.5,
                            }}
                          >
                            {" "}
                            Previous Company Name{" "}
                            <span style={{ color: "red" }}>*</span>
                          </Typography>
                          <BootstrapInput
                            onChange={handleChangePreviousCompany}
                            value={previuosCompany}
                            sx={{ width: 480 }}
                            size="small"
                            onKeyPress={(e) => {
                              if (new RegExp(/[a-zA-Z]/).test(e.key)) {
                              } else e.preventDefault();
                            }}
                          />
                        </Box>
                        <Box sx={{ mb: 1 }}>
                          <Typography
                            sx={{
                              fontWeight: "bold",
                              fontSize: "14px",
                              color: "#000",
                              textAlign: "left",
                            }}
                          >
                            Upload Relevant Document
                            <span style={{ color: "red" }}>
                              *
                              <i style={{ color: "#000", fontSize: "12px" }}>
                                (pdf format)
                              </i>
                            </span>
                          </Typography>
                          <TextField
                            inputProps={{
                              accept: "application/pdf",
                              // onChange: handleFileChange,
                            }}
                            onChange={handleChangeExperinceDocuments}
                            size="small"
                            sx={{
                              width: 427,
                              background: "#fff",
                              borderRadius: "4px",
                            }}
                            fullWidth
                            type="file"
                          ></TextField>
                        </Box>
                      </Box>
                      <Box>
                        <Box sx={{ mt: 2 }}>
                          <FormGroup sx={{ mb: 1 }}>
                            {/* <FormControlLabel control={<Checkbox defaultChecked />} label="Label" /> */}
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={check}
                                  onChange={handleChange}
                                />
                              }
                              label={
                                <div
                                  style={{
                                    color: "#000",
                                    fontWeight: "bold",
                                    fontSize: "14px",
                                  }}
                                >
                                  I accept all the terms and conditions{" "}
                                  <a
                                    href={props.policy}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    style={{
                                      color: "blue",
                                      cursor: "pointer",
                                      textDecoration: "none",
                                    }}
                                    //  className={classes.hyperlink}
                                  >
                                    (Read more)
                                    <span style={{ color: "red" }}>*</span>
                                  </a>
                                </div>
                              }
                            />
                            {/* <FormControlLabel disabled control={<Checkbox />} label="Disabled" /> */}
                          </FormGroup>
                          <FormGroup sx={{ display: "flex", mt: 1 }}>
                            {/* <FormControlLabel control={<Checkbox defaultChecked />} label="Label" /> */}
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={check2}
                                  onChange={handleChange2}
                                />
                              }
                              label={
                                <div
                                  style={{
                                    color: "#000",
                                    fontWeight: "bold",
                                    fontSize: "14px",
                                  }}
                                >
                                  I confirm that I have gone through the program
                                  and it’s details and the program fits my
                                  career objective
                                  <span style={{ color: "red" }}>*</span>{" "}
                                  <a
                                    href={props.inclusion}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    style={{
                                      color: "blue",
                                      cursor: "pointer",
                                      textDecoration: "none",
                                    }}
                                    //  className={classes.hyperlink}
                                  >
                                    (Read more)
                                  </a>
                                </div>
                              }
                            />

                            {/* <FormControlLabel disabled control={<Checkbox />} label="Disabled" /> */}
                          </FormGroup>
                          <FormGroup sx={{display:"flex",mt:1}}>
      {/* <FormControlLabel control={<Checkbox defaultChecked />} label="Label" /> */}
      <FormControlLabel  control={<Checkbox checked={true}   />}  label={
         <div style={{color:"#000",fontWeight:"bold",fontSize:"14px"}}>
        I give consent to receive communications through WhatsApp and SMS.<span style={{color:"red"}}>*</span>{' '}
       </div>
      } />
     
      {/* <FormControlLabel disabled control={<Checkbox />} label="Disabled" /> */}
    </FormGroup>
                        </Box>
                      </Box>
                    </Box>
                  )}
                </Box>
              )}
              {/* {choice == "" ? (
          <Box></Box>
        ) : (
          <Box>
            {choice == "Yes" ? (
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  mt: 1,
                  mb: 1,
                }}
              >
                <Box>
                  <Typography
                    sx={{
                      fontWeight: "bold",
                      fontSize: "14px",
                      color: "#000",
                    }}
                  >
                    Current Company Name <span style={{ color: "red" }}>*</span>
                  </Typography>
                  <BootstrapInput
                    // onChange={handleChangeCpmanyName}
                    // value={companyname}
                    sx={{ width: 480 }}
                    size="small"
                    onKeyPress={(e) => {
                      if (new RegExp(/[a-zA-Z]/).test(e.key)) {
                      } else e.preventDefault();
                    }}
                  />
                </Box>
                <Box>
                  <Typography
                    sx={{
                      fontWeight: "bold",
                      fontSize: "14px",
                      color: "#000",
                    }}
                  >
                    Company Email Id <span style={{ color: "red" }}>*</span>
                  </Typography>
                  <TextField
                    type="email"
                    // onChange={handleChangeCompanyemail}
                    // value={companyemail}
                    sx={{ width: 480, background: "#fff", borderRadius: "5px" }}
                    size="small"
                  />
                </Box>
              </Box>
            ) : (
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  mt: 1,
                  mb: 1,
                }}
              >
                <Box>
                  <Typography
                    sx={{
                      fontWeight: "bold",
                      fontSize: "14px",
                      color: "#000",
                    }}
                  >
                    {" "}
                    Previous Company Name{" "}
                    <span style={{ color: "red" }}>*</span>
                  </Typography>
                  <BootstrapInput
                    // onChange={handleChangePreviousCompany}
                    // value={previuosCompany}
                    sx={{ width: 480 }}
                    size="small"
                    onKeyPress={(e) => {
                      if (new RegExp(/[a-zA-Z]/).test(e.key)) {
                      } else e.preventDefault();
                    }}
                  />
                </Box>
                <Box>
                  <Typography
                    sx={{
                      fontWeight: "bold",
                      fontSize: "14px",
                      color: "#000",
                    }}
                  >
                    Upload Relevant Document
                    <span style={{ color: "red" }}>*</span>
                  </Typography>
                  <TextField
                    inputProps={{
                      accept: "application/pdf, image/*",
                      // onChange: handleFileChange,
                    }}
                    // onChange={handleChangeExperinceDocuments}
                    size="small"
                    sx={{ width: 480, background: "#fff", borderRadius: "4px" }}
                    fullWidth
                    type="file"
                  ></TextField>
                </Box>
              </Box>
            )}
          </Box>
        )} */}

              {/* </Collapse> */}
              {/* <Box sx={{ display: "flex", justifyContent: "end", mt: 1 }}>
          <Button
            variant="conatained"
            size="small"
            sx={{
              color: "#fff",
              background: "#fdb714",
              "&:hover": { background: "#fdb714", color: "#fff" },
            }}
            // onClick={handleNext}
            // disabled={nxt}
          >
            Submit
          </Button>
        </Box> */}
            </Box>

            <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
              {/* <Typography
              sx={{
                fontWeight: "bold",
                fontSize: "14px",
                color: "#fff",
                mb: 1,
              }}
            >
              <i>Please make the payment below</i>
            </Typography> */}

              <Button
                sx={{
                  background: "#002E5C",
                  textTransform: "none",
                  color: "#fff",
                  "&:hover": { background: "#002E5C", color: "#fff" },
                }}
                variant="contained"
                size="small"
                width="150"
                height="80"
                onClick={handleNext}
                disabled={nxt}
              >
                Submit
              </Button>
            </Box>
          </Box>
        </Box>
      </Box>

      {/* mobile version */}
      <Box
        sx={{ display: { xs: "block", lg: "none", sm: "block", md: "block" } }}
      >
        <Box
          sx={{
            // display: "grid",
            // placeItems: "center",
            p: 3,
            width: "85%",
            borderRadius: "15px",
            mt: 3,
            overflowY: "scroll",
            height: { xs: 500, sm: 700, md: 800 },
            "&::-webkit-scrollbar": {
              width: "0.2em",
            },
            "&::-webkit-scrollbar-track": {
              boxShadow: "inset 0 0 6px silver",
              webkitBoxShadow: "inset 0 0 6px silver",
            },
            "&::-webkit-scrollbar-thumb": {
              backgroundColor: "silver",
              outline: "1px solid silver",
            },
          }}
        >
          <Box
            sx={{
              borderRadius: "8px",
              p: 1.5,
              background: "#fff",
              backgroundColor: "rgba(255, 255, 255, .60)",
              pl: 4,
              pr: 4,
              backdropFilter: "blur(5px)",
              display: "grid",
              placeContent: "center",
              border: "1px solid #002E5C",
            }}
          >
            {/* <Typography sx={{fontWeight:"bold",fontSize:"20px",textAlign:"left",mb:2}}>Education and Work Details</Typography> */}
            <Box sx={{ p: 1.5 }}>
              <Box
                sx={{ mb: 0.5, p: 1, borderRadius: "5px" }}
                // onClick={handleClickaddress}
              >
                <Typography
                  sx={{
                    color: "#000",
                    fontWeight: "bold",
                    textAlign: "center",
                    fontSize: "23px",
                  }}
                >
                  Address Details
                </Typography>
              </Box>

              <Box
                sx={{
                  // display: "flex",
                  // justifyContent: "space-between",
                  mt: 1,
                  mb: 1,
                }}
              >
                <Box>
                  <Typography
                    sx={{
                      fontWeight: "bold",
                      fontSize: "14px",
                      color: "#000",
                      textAlign: "left",
                    }}
                  >
                    Country <span style={{ color: "red" }}>*</span>{" "}
                  </Typography>
                  <FormControl fullWidth>
                    <Select
                      size="small"
                      sx={{
                        width: { xs: 250, sm: 430, md: 450 },
                        background: "#fff",
                        textAlign: "left",
                      }}
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={country}
                      label="Gender"
                      onChange={handleChangeCountry}
                    >
                      {Country.getAllCountries().map((val, i) => (
                        <MenuItem value={val.isoCode}>{val.name}</MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Box>
                <Box>
                  <Typography
                    sx={{
                      fontWeight: "bold",
                      fontSize: "14px",
                      color: "#000",
                      textAlign: "left",
                    }}
                  >
                    State <span style={{ color: "red" }}>*</span>
                  </Typography>
                  <FormControl fullWidth>
                    <Select
                      size="small"
                      sx={{
                        width: { xs: 250, sm: 430, md: 450 },
                        background: "#fff",
                        textAlign: "left",
                      }}
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={state}
                      label="Gender"
                      onChange={handleChangeState}
                    >
                      {State.getAllStates()
                        .filter((val) => {
                          return val.countryCode == country;
                        })
                        .sort()
                        .map((val) => (
                          <MenuItem value={val.name}>{val.name}</MenuItem>
                        ))}
                    </Select>
                  </FormControl>
                </Box>
              </Box>
              <Box
                sx={{
                  // display: "flex",
                  // justifyContent: "space-between",
                  mt: 1,
                  mb: 1,
                }}
              >
                <Box>
                  <Typography
                    sx={{
                      fontWeight: "bold",
                      fontSize: "14px",
                      color: "#000",
                      textAlign: "left",
                    }}
                  >
                    City <span style={{ color: "red" }}>*</span>
                  </Typography>

                  <FormControl fullWidth>
                    <Select
                      size="small"
                      sx={{
                        width: { xs: 250, sm: 430, md: 450 },
                        background: "#fff",
                        textAlign: "left",
                      }}
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={city}
                      label="Gender"
                      onChange={handleChangeCity}
                    >
                      {cities.map((val) => (
                        <MenuItem value={val.name}>{val.name}</MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Box>
                <Box>
                  <Typography
                    sx={{
                      fontWeight: "bold",
                      fontSize: "14px",
                      color: "#000",
                      textAlign: "left",
                    }}
                  >
                    Pincode <span style={{ color: "red" }}>*</span>
                  </Typography>
                  {/* <TextField
                    onChange={handleChangePincode}
                    value={pincode}
                    type="number"
                    sx={{
                      width: { xs: 250, sm: 430, md: 450 },
                      background: "#fff",
                      borderRadius: "5px",
                    }}
                    // sx={{ width: {xs:250,sm:430,md:450} }}
                    size="small"
                  /> */}
                   <TextField
                    type="text"
                    error={!ispincodeValid}
                    helperText={!ispincodeValid ? 'Please enter only number' : ''}
                    
                    onChange={handleChangePincode}
                    value={pincode}
                    sx={{ width: { xs: 250, sm: 430, md: 450 }, background: "#fff", borderRadius: "5px" }}
                    size="small"
                  />
                </Box>
              </Box>
              <Box
                sx={{
                  // display: "flex",
                  // justifyContent: "space-between",
                  mt: 1,
                  mb: 1,
                }}
              >
                <Box>
                  <Typography
                    sx={{
                      fontWeight: "bold",
                      fontSize: "14px",
                      color: "#000",
                      textAlign: "left",
                    }}
                  >
                    Address 1 <span style={{ color: "red" }}>*</span>
                  </Typography>
                  <TextField
                    onChange={handleChangeAddress}
                    value={address}
                    sx={{
                      width: { xs: 250, sm: 430, md: 450 },
                      background: "#fff",
                      borderRadius: "5px",
                    }}
                    size="small"
                    type="text"
                  />
                </Box>
                <Box>
                  <Typography
                    sx={{
                      fontWeight: "bold",
                      fontSize: "14px",
                      color: "#000",
                      textAlign: "left",
                    }}
                  >
                    Address 2{" "}
                  </Typography>
                  <TextField
                    onChange={handleChangeAddresst}
                    value={addresstwo}
                    sx={{
                      width: { xs: 250, sm: 430, md: 450 },
                      background: "#fff",
                      borderRadius: "5px",
                    }}
                    size="small"
                    type="text"
                  />
                </Box>
              </Box>
              <Box sx={{ mb: 1 }}>
                <Typography
                  sx={{
                    color: "#000",
                    fontWeight: "bold",
                    textAlign: "center",
                    fontSize: "23px",
                  }}
                >
                  Education Details
                </Typography>
              </Box>

              <Box
                sx={{
                  // display: "flex",
                  // justifyContent: "space-between",
                  mt: 1,
                  mb: 1,
                }}
              >
                <Box>
                  <Typography
                    sx={{
                      fontWeight: "bold",
                      fontSize: "14px",
                      color: "#000",
                      textAlign: "left",
                    }}
                  >
                    Highest Qualification{" "}
                    <span style={{ color: "red" }}>*</span>
                  </Typography>
                  <FormControl
                    fullWidth
                    sx={{ boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)" }}
                  >
                    {/* <InputLabel id="demo-simple-select-label">Payment Type</InputLabel> */}
                    <Select
                      size="small"
                      sx={{
                        width: { xs: 250, sm: 430, md: 450 },
                        background: "#fff",
                        textAlign: "left",
                      }}
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      //  value={bachelorDegree}
                      onChange={handleChangequalification}
                      defaultValue={"select"}
                    >
                      <MenuItem disabled="true" value={"select"}>
                        Select your Qualification
                      </MenuItem>
                      <MenuItem value={"High School Diploma"}>
                        High School Diploma
                      </MenuItem>
                      <MenuItem value={"Associate Degree"}>
                        Associate Degree
                      </MenuItem>
                      <MenuItem value={"Bachelor Degree"}>
                        Bachelor Degree
                      </MenuItem>
                      <MenuItem value={"Master Degree"}>Master Degree</MenuItem>
                      <MenuItem value={"Doctorate/Ph.D"}>
                        Doctorate/Ph.D
                      </MenuItem>

                      {/* <MenuItem value={30}>Others</MenuItem> */}
                    </Select>
                  </FormControl>
                  {/* {name?"":<Typography sx={{color:"red"}}>This Filed is Required</Typography>} */}
                </Box>
                <Box>
                  <Typography
                    sx={{
                      fontWeight: "bold",
                      fontSize: "14px",
                      color: "#000",
                      textAlign: "left",
                    }}
                  >
                    Degree Name
                    <span style={{ color: "red" }}>*</span>
                  </Typography>
                  {/* <FormControl fullWidth>
                    
                    <Select
                      size="small"
                      sx={{ width: {xs:250,sm:430,md:450}, background: "#fff", textAlign: "left" }}
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={bachelorDegree}
                      onChange={handleChangeDegree}
                      defaultValue={"select"}
                    >
                      <MenuItem disabled="true" value={"select"}>
                        Select your degree
                      </MenuItem>
                      <MenuItem value={"B.Tech"}>B.Tech</MenuItem>
                      <MenuItem value={"B.Voc"}>B.Voc</MenuItem>
                      <MenuItem value={"B.Sc"}>B.Sc</MenuItem>
                      <MenuItem value={"B.S"}>B.S</MenuItem>
                      <MenuItem value={"BPharm"}>BPharm</MenuItem>
                      <MenuItem value={"B.com"}>B.com</MenuItem>
                      <MenuItem value={"BBA"}>BBA</MenuItem>
                      <MenuItem value={"BBA Honors"}>BBA Honors</MenuItem>
                      <MenuItem value={"BCA"}>BCA</MenuItem>
                      <MenuItem value={"BArch"}>BArch</MenuItem>
                      <MenuItem value={"B.L.L.A.B"}>B.L.L.A.B</MenuItem>
                      <MenuItem value={"others"}>Others</MenuItem>
                     
                    </Select>
                  </FormControl> */}
                  <FormControl fullWidth>
                    {/* <InputLabel id="demo-simple-select-label">Payment Type</InputLabel> */}
                    {qualification == "Master Degree" ? (
                      <Select
                        size="small"
                        sx={{
                          width: { xs: 250, sm: 430, md: 450 },
                          background: "#fff",
                          textAlign: "left",
                        }}
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={bachelorDegree}
                        onChange={handleChangeDegree}
                        defaultValue={"select"}
                      >
                        <MenuItem disabled="true" value={"select"}>
                          Select your degree
                        </MenuItem>
                        {/* <MenuItem disabled="true" value={"select"}>
                        Select your degree
                      </MenuItem> */}
                        <MenuItem value={"MBA"}>MBA</MenuItem>
                        <MenuItem value={"MS"}>MS</MenuItem>
                        <MenuItem value={"M.Tech"}>M.Tech</MenuItem>
                        <MenuItem value={"MA"}>MA</MenuItem>
                        <MenuItem value={"MEd"}>MEd</MenuItem>
                        <MenuItem value={"MCA"}>MCA</MenuItem>
                        <MenuItem value={"M.Arch"}>M.Arch</MenuItem>
                        <MenuItem value={"LLM"}>LLM</MenuItem>
                        <MenuItem value={"others"}>Others</MenuItem>
                      </Select>
                    ) : (
                      <Select
                        size="small"
                        sx={{
                          width: { xs: 250, sm: 430, md: 450 },
                          background: "#fff",
                          textAlign: "left",
                        }}
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={bachelorDegree}
                        onChange={handleChangeDegree}
                        defaultValue={"select"}
                      >
                        <MenuItem disabled="true" value={"select"}>
                          Select your degree
                        </MenuItem>
                        <MenuItem value={"B.Tech"}>B.Tech</MenuItem>
                        <MenuItem value={"B.Voc"}>B.Voc</MenuItem>
                        <MenuItem value={"B.Sc"}>B.Sc</MenuItem>
                        <MenuItem value={"B.S"}>B.S</MenuItem>
                        <MenuItem value={"BPharm"}>BPharm</MenuItem>
                        <MenuItem value={"B.com"}>B.com</MenuItem>
                        <MenuItem value={"BBA"}>BBA</MenuItem>
                        <MenuItem value={"BBA Honors"}>BBA Honors</MenuItem>
                        <MenuItem value={"BCA"}>BCA</MenuItem>
                        <MenuItem value={"BArch"}>BArch</MenuItem>
                        <MenuItem value={"B.L.L.A.B"}>B.L.L.A.B</MenuItem>
                        <MenuItem value={"others"}>Others</MenuItem>
                        {/* <MenuItem value={30}>Others</MenuItem> */}
                      </Select>
                    )}
                  </FormControl>
                  {bachelorDegree == "others" ? (
                    <Box sx={{ mt: 1 }}>
                      <BootstrapInput
                        placeholder="Enter Your Degree Name"
                        onChange={handleChangeDegreeOther}
                        value={bachelorDegreeOther}
                        sx={{ width: { xs: 250, sm: 430, md: 450 } }}
                        size="small"
                        //  onKeyPress={(e) => {
                        //     if (new RegExp(/[a-zA-Z]/).test(e.key)) {
                        //     } else e.preventDefault();
                        //   }}
                      />
                    </Box>
                  ) : (
                    <Box></Box>
                  )}
                  {/* {name?"":<Typography sx={{color:"red"}}>This Filed is Required</Typography>} */}
                </Box>
                <Box>
                  <Typography
                    sx={{
                      fontWeight: "bold",
                      fontSize: "14px",
                      color: "#000",
                      textAlign: "left",
                    }}
                  >
                    College/University Name{" "}
                    <span style={{ color: "red" }}>*</span>
                  </Typography>
                  {/* <BootstrapInput
                    onChange={handleChangeCollegeName}
                    value={collegeName}
                    sx={{ width: 260 }}
                    size="small"
                    //  onKeyPress={(e) => {
                    //     if (new RegExp(/[a-zA-Z]/).test(e.key)) {
                    //     } else e.preventDefault();
                    //   }}
                  /> */}
                  <TextField
                    onChange={handleChangeCollegeName}
                    value={collegeName}
                    sx={{
                      width: { xs: 250, sm: 430, md: 450 },
                      background: "#fff",
                      borderRadius: "5px",
                    }}
                    size="small"
                    error={error}
                    // onKeyPress={handleKeyPress}
                    helperText={
                      error ? "Special characters are not allowed" : ""
                    }
                    //  onKeyPress={(e) => {
                    //     if (new RegExp(/[a-zA-Z]/).test(e.key)) {
                    //     } else e.preventDefault();
                    //   }}
                  />
                  {/* {name?"":<Typography sx={{color:"red"}}>This Filed is Required</Typography>} */}
                </Box>
              </Box>
              <Box
                sx={{
                  // display: "flex",
                  // justifyContent: "space-between",
                  mt: 1,
                  mb: 1,
                }}
              >
                <Box>
                  <Typography
                    sx={{
                      fontWeight: "bold",
                      fontSize: "14px",
                      color: "#000",
                      textAlign: "left",
                    }}
                  >
                    Year of Completion<span style={{ color: "red" }}>* <i style={{ color: "#000", fontSize: "12px" }}>
                        (only in number)
                      </i></span>
                  </Typography>
                  {/* <BootstrapInput
                    type="number"
                    sx={{ width: { xs: 250, sm: 430, md: 450 } }}
                    size="small"
                    onChange={handleChangeYear}
                    value={yearOfCompletion}
                  /> */}
                  <TextField
                    type="text"
                    error={!isyearValid||(yearOfCompletion&&yearOfCompletion>=2030)}
                    helperText={!isyearValid ? 'Please enter a valid year':yearOfCompletion<2030?'':'Please enter a valid year' }
                    
                    onChange={handleChangeYear}
                    value={yearOfCompletion}
                    sx={{ width: {xs:250,sm:430,md:450}, background: "#fff", borderRadius: "5px" }}
                    size="small"
                  />
                </Box>
                <Box>
                  <Typography
                    sx={{
                      fontWeight: "bold",
                      fontSize: "14px",
                      color: "#000",
                      textAlign: "left",
                    }}
                  >
                    Degree % / CGPA
                    <span style={{ color: "red" }}>*</span>
                  </Typography>
                  {/* <BootstrapInput
                    type="number"
                    sx={{ width: { xs: 250, sm: 430, md: 450 } }}
                    size="small"
                    onChange={handleChangeCgpa}
                    value={cgpa}
                  /> */}
                   <TextField
                    type="text"
                    error={!iscgpaValid || (cgpa&&cgpa>100)}
                    helperText={!iscgpaValid ? 'Please enter only number' : cgpa<=100?'':'Please enter a CGPA/percentage less than 100.'}
                    
                    onChange={handleChangeCgpa}
                    value={cgpa}
                    sx={{ width: {xs:250,sm:430,md:450}, background: "#fff", borderRadius: "5px" }}
                    size="small"
                  />
                </Box>
              </Box>

              <Box
                sx={{
                  // display: "flex",
                  // justifyContent: "space-between",
                  mt: 1,
                  mb: 1,
                }}
              >
                <Box>
                  <Typography
                    sx={{
                      fontWeight: "bold",
                      fontSize: "14px",
                      color: "#000",
                      textAlign: "left",
                    }}
                  >
                    Upload Relevant Document
                    <span style={{ color: "red" }}>* <i style={{ color: "#000", fontSize: "12px" }}>
                        (pdf format)
                      </i></span>
                  </Typography>
                  <TextField
                    inputProps={{
                      accept: "application/pdf",
                      // onChange: handleFileChange,
                    }}
                    onChange={handleChangeBachelorDocuments}
                    size="small"
                    sx={{
                      width: { xs: 250, sm: 430, md: 450 },
                      background: "#fff",
                      borderRadius: "4px",
                    }}
                    fullWidth
                    type="file"
                  ></TextField>
                </Box>
              </Box>

              <Box sx={{ mb: 0.5, p: 1, borderRadius: "5px" }}>
                <Typography
                  sx={{
                    color: "#000",
                    fontWeight: "bold",
                    textAlign: "center",
                    fontSize: "23px",
                  }}
                >
                  Work Experience Details
                </Typography>
              </Box>

              <Box>
                <Typography
                  sx={{
                    fontWeight: "bold",
                    fontSize: "14px",
                    color: "#000",
                    mt: 2,
                    mb: 1,
                    // width: 300,
                  }}
                >
                  Are you currently working?
                </Typography>
                <FormControl fullWidth>
                  <Select
                    size="small"
                    sx={{
                      width: { xs: 250, sm: 430, md: 450 },
                      background: "#fff",
                      mt: 1,
                      textAlign: "left",
                      borderRadius: "5px",
                    }}
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    // value={choice}
                    // onChange={handleChangeChoice}
                    defaultValue={"select"}
                  >
                    <MenuItem disabled value={"select"}>
                      <i>Select an Option</i>
                    </MenuItem>
                    {option.map((val) => (
                      <MenuItem
                        onClick={() => {
                          handleChangeChoice(val.value);
                        }}
                        value={val.value}
                      >
                        {val.label}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Box>
              {choice == "" ? (
                <Box></Box>
              ) : (
                <Box>
                  {choice == "Yes" ? (
                    <Box
                      sx={{
                        // display: "flex",
                        // justifyContent: "space-between",
                        mt: 1,
                        mb: 1,
                      }}
                    >
                      <Box>
                        <Typography
                          sx={{
                            fontWeight: "bold",
                            fontSize: "14px",
                            color: "#000",
                            textAlign: "left",
                          }}
                        >
                          Total Years of Experience(Full-time only){" "}
                          <span style={{ color: "red" }}>*</span>
                        </Typography>
                        {/* <TextField
                          type="number"
                          onChange={handleChangeTotalExp}
                          value={Total_exp}
                          sx={{
                            width: { xs: 250, sm: 430, md: 450 },
                            background: "#fff",
                            borderRadius: "5px",
                          }}
                          size="small"
                        /> */}
                         <TextField
                    type="text"
                    error={!isexpValid||(Total_exp&&Total_exp>100)}
                    helperText={!isexpValid ? 'Please enter only number' : Total_exp<=100?'':'Please enter a valid experience'}
                    
                    
                    onChange={handleChangeTotalExp}
                    value={Total_exp}
                    sx={{ width: {xs:250,sm:430,md:450}, background: "#fff", borderRadius: "5px" }}
                    size="small"
                  />
                      </Box>

                      <Box>
                        <Typography
                          sx={{
                            fontWeight: "bold",
                            fontSize: "14px",
                            color: "#000",
                            textAlign: "left",
                          }}
                        >
                          Current Industry
                          <span style={{ color: "red" }}>*</span>
                        </Typography>
                        <FormControl fullWidth>
                          {/* <InputLabel id="demo-simple-select-label">Payment Type</InputLabel> */}
                          <Select
                            size="small"
                            sx={{
                              width: { xs: 250, sm: 430, md: 450 },
                              background: "#fff",
                              textAlign: "left",
                            }}
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={industry}
                            onChange={handleChangeIndustry}
                            defaultValue={"select"}
                          >
                            <MenuItem disabled="true" value={"select"}>
                              Select your Current Industry
                            </MenuItem>
                            <MenuItem value={"Automotive"}>Automotive</MenuItem>
                            <MenuItem value={"Banking and Financial Services"}>
                              Banking and Financial Services
                            </MenuItem>
                            <MenuItem value={"Construction and Engineering"}>
                              Construction and Engineering
                            </MenuItem>
                            <MenuItem value={"Consumer Goods and Retail"}>
                              Consumer Goods and Retail
                            </MenuItem>
                            <MenuItem value={"Energy and Utilities"}>
                              Energy and Utilities
                            </MenuItem>
                            <MenuItem value={"Food and Beverage"}>
                              Food and Beverage
                            </MenuItem>
                            <MenuItem value={"Healthcare and Pharmaceuticals"}>
                              Healthcare and Pharmaceuticals
                            </MenuItem>
                            <MenuItem
                              value={"Information Technology and Services"}
                            >
                              Information Technology and Services
                            </MenuItem>
                            <MenuItem value={"Insurance"}>Insurance</MenuItem>
                            <MenuItem value={"Manufacturing"}>
                              Manufacturing
                            </MenuItem>
                            <MenuItem value={"Media and Entertainment"}>
                              Media and Entertainment
                            </MenuItem>
                            <MenuItem value={"Professional Services"}>
                              Professional Services
                            </MenuItem>
                            <MenuItem value={"Real Estate"}>
                              Real Estate
                            </MenuItem>
                            <MenuItem value={"Transportation and Logistics"}>
                              Transportation and Logistics
                            </MenuItem>
                            <MenuItem value={"Travel and Hospitality"}>
                              Travel and Hospitality
                            </MenuItem>
                            <MenuItem value={"others"}>Others</MenuItem>
                            {/* <MenuItem value={30}>Others</MenuItem> */}
                          </Select>
                        </FormControl>
                        {industry == "others" ? (
                          <Box sx={{ mt: 1 }}>
                            <TextField
                              sx={{
                                width: { xs: 250, sm: 430, md: 450 },
                                background: "#fff",
                                borderRadius: "5px",
                              }}
                              placeholder="Enter Your Current Industry"
                              onChange={handleChangeIndustryOther}
                              value={industryOther}
                              //   sx={{ width: 480 }}
                              size="small"
                              //  onKeyPress={(e) => {
                              //     if (new RegExp(/[a-zA-Z]/).test(e.key)) {
                              //     } else e.preventDefault();
                              //   }}
                            />
                          </Box>
                        ) : (
                          <Box></Box>
                        )}
                        {/* {name?"":<Typography sx={{color:"red"}}>This Filed is Required</Typography>} */}
                      </Box>
                      <Box>
                        <Typography
                          sx={{
                            fontWeight: "bold",
                            fontSize: "14px",
                            textAlign: "left",
                            color: "#000",
                          }}
                        >
                          Current Company Name{" "}
                          <span style={{ color: "red" }}>*</span>
                        </Typography>
                        {/* <BootstrapInput
                          onChange={handleChangeCpmanyName}
                          value={companyname}
                          sx={{ width: 260 }}
                          size="small"
                          // onKeyPress={(e) => {
                          //   if (new RegExp(/[a-zA-Z]/).test(e.key)) {
                          //   } else e.preventDefault();
                          // }}
                        /> */}
                        <TextField
                          onChange={handleChangeCpmanyName}
                          value={companyname}
                          sx={{
                            width: { xs: 250, sm: 430, md: 450 },
                            background: "#fff",
                            borderRadius: "5px",
                          }}
                          size="small"
                          error={validCmpname}
                          // onKeyPress={handleKeyPress}
                          helperText={
                            validCmpname
                              ? "Special characters are not allowed"
                              : ""
                          }
                          //  onKeyPress={(e) => {
                          //     if (new RegExp(/[a-zA-Z]/).test(e.key)) {
                          //     } else e.preventDefault();
                          //   }}
                        />
                      </Box>
                      <Box>
                        <Typography
                          sx={{
                            fontWeight: "bold",
                            fontSize: "14px",
                            color: "#000",
                            textAlign: "left",
                          }}
                        >
                          Current Designation
                          <span style={{ color: "red" }}>*</span>
                        </Typography>
                        <BootstrapInput
                          onChange={handleChangeDesignation}
                          value={designation}
                          sx={{ width: { xs: 250, sm: 430, md: 450 } }}
                          size="small"
                          // onKeyPress={(e) => {
                          //   if (new RegExp(/[a-zA-Z]/).test(e.key)) {
                          //   } else e.preventDefault();
                          // }}
                        />
                      </Box>
                      <Box>
                        <Typography
                          sx={{
                            fontWeight: "bold",
                            fontSize: "14px",
                            textAlign: "left",
                            color: "#000",
                          }}
                        >
                          Company Email Id{" "}
                          <span style={{ color: "red" }}>*</span>
                        </Typography>
                        {/* <TextField
                          type="email"
                          onChange={handleChangeCompanyemail}
                          value={companyemail}
                          sx={{
                            width: 260,
                            background: "#fff",
                            borderRadius: "5px",
                          }}
                          size="small"
                        /> */}
                        <TextField
                          type="email"
                          error={!isEmailValid}
                          helperText={
                            !isEmailValid ? "Invalid email format" : ""
                          }
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <EmailIcon
                                  color={!isEmailValid ? "error" : "action"}
                                />
                              </InputAdornment>
                            ),
                          }}
                          onChange={handleChangeCompanyemail}
                          value={companyemail}
                          sx={{
                            width: { xs: 250, sm: 430, md: 450 },
                            mb: { xs: 0, sm: 2, md: 2 },
                            background: "#fff",
                            borderRadius: "5px",
                          }}
                          size="small"
                        />
                      </Box>

                      <Box sx={{ mt: 2 }}>
                        <FormGroup sx={{ mb: 1 }}>
                          {/* <FormControlLabel control={<Checkbox defaultChecked />} label="Label" /> */}
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={check}
                                onChange={handleChange}
                              />
                            }
                            label={
                              <div
                                style={{
                                  color: "#000",
                                  fontWeight: "bold",
                                  fontSize: "14px",
                                }}
                              >
                                I accept all the terms and conditions{" "}
                                <a
                                  href={props.policy}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                  style={{
                                    color: "blue",
                                    cursor: "pointer",
                                    textDecoration: "none",
                                  }}
                                  //  className={classes.hyperlink}
                                >
                                  (Read more)
                                  <span style={{ color: "red" }}>*</span>
                                </a>
                              </div>
                            }
                          />
                          {/* <FormControlLabel disabled control={<Checkbox />} label="Disabled" /> */}
                        </FormGroup>
                        <FormGroup sx={{ display: "flex", mt: 1 }}>
                          {/* <FormControlLabel control={<Checkbox defaultChecked />} label="Label" /> */}
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={check2}
                                onChange={handleChange2}
                              />
                            }
                            label={
                              <div
                                style={{
                                  color: "#000",
                                  fontWeight: "bold",
                                  fontSize: "14px",
                                }}
                              >
                                I confirm that I have gone through the program
                                and it’s details and the program fits my career
                                objective<span style={{ color: "red" }}>*</span>{" "}
                                <a
                                  href={props.inclusion}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                  style={{
                                    color: "blue",
                                    cursor: "pointer",
                                    textDecoration: "none",
                                  }}
                                  //  className={classes.hyperlink}
                                >
                                  (Read more)
                                </a>
                              </div>
                            }
                          />

                          {/* <FormControlLabel disabled control={<Checkbox />} label="Disabled" /> */}
                        </FormGroup>
                        <FormGroup sx={{display:"flex",mt:1}}>
      {/* <FormControlLabel control={<Checkbox defaultChecked />} label="Label" /> */}
      <FormControlLabel  control={<Checkbox checked={true}   />}  label={
         <div style={{color:"#000",fontWeight:"bold",fontSize:"14px"}}>
        I give consent to receive communications through WhatsApp and SMS.<span style={{color:"red"}}>*</span>{' '}
       </div>
      } />
     
      {/* <FormControlLabel disabled control={<Checkbox />} label="Disabled" /> */}
    </FormGroup>
                      </Box>
                    </Box>
                  ) : (
                    <Box>
                      <Box
                        sx={{
                          // display: "flex",
                          // justifyContent: "space-between",
                          mt: 1,
                          mb: 1,
                        }}
                      >
                        <Box>
                          <Typography
                            sx={{
                              fontWeight: "bold",
                              fontSize: "14px",
                              color: "#000",
                              textAlign: "left",
                            }}
                          >
                            Total Years of Experience(Full-time only){" "}
                            <span style={{ color: "red" }}>*</span>
                          </Typography>
                          {/* <TextField
                            type="number"
                            onChange={handleChangeTotalExp}
                            value={Total_exp}
                            sx={{
                              width: { xs: 250, sm: 430, md: 450 },
                              mb: { xs: 0, sm: 2, md: 2 },
                              background: "#fff",
                              borderRadius: "5px",
                            }}
                            size="small"
                          /> */}
                           <TextField
                    type="text"
                    error={!isexpValid||(Total_exp&&Total_exp>100)}
                    helperText={!isexpValid ? 'Please enter only number' : Total_exp<=100?'':'Please enter a valid experience'}
                    
                    
                    onChange={handleChangeTotalExp}
                    value={Total_exp}
                    sx={{ width: {xs:250,sm:430,md:450}, background: "#fff", borderRadius: "5px" }}
                    size="small"
                  />
                        </Box>
                        <Box>
                          <Typography
                            sx={{
                              fontWeight: "bold",
                              fontSize: "14px",
                              color: "#000",
                              textAlign: "left",
                            }}
                          >
                            Previous Industry
                            <span style={{ color: "red" }}>*</span>
                          </Typography>
                          <FormControl fullWidth>
                            {/* <InputLabel id="demo-simple-select-label">Payment Type</InputLabel> */}
                            <Select
                              size="small"
                              sx={{
                                width: { xs: 250, sm: 430, md: 450 },
                                background: "#fff",
                                textAlign: "left",
                              }}
                              labelId="demo-simple-select-label"
                              id="demo-simple-select"
                              value={previndustry}
                              onChange={handleChangePrevIndustry}
                              defaultValue={"select"}
                            >
                              <MenuItem disabled="true" value={"select"}>
                                Select your Previous Industry
                              </MenuItem>
                              <MenuItem value={"Automotive"}>
                                Automotive
                              </MenuItem>
                              <MenuItem
                                value={"Banking and Financial Services"}
                              >
                                Banking and Financial Services
                              </MenuItem>
                              <MenuItem value={"Construction and Engineering"}>
                                Construction and Engineering
                              </MenuItem>
                              <MenuItem value={"Consumer Goods and Retail"}>
                                Consumer Goods and Retail
                              </MenuItem>
                              <MenuItem value={"Energy and Utilities"}>
                                Energy and Utilities
                              </MenuItem>
                              <MenuItem value={"Food and Beverage"}>
                                Food and Beverage
                              </MenuItem>
                              <MenuItem
                                value={"Healthcare and Pharmaceuticals"}
                              >
                                Healthcare and Pharmaceuticals
                              </MenuItem>
                              <MenuItem
                                value={"Information Technology and Services"}
                              >
                                Information Technology and Services
                              </MenuItem>
                              <MenuItem value={"Insurance"}>Insurance</MenuItem>
                              <MenuItem value={"Manufacturing"}>
                                Manufacturing
                              </MenuItem>
                              <MenuItem value={"Media and Entertainment"}>
                                Media and Entertainment
                              </MenuItem>
                              <MenuItem value={"Professional Services"}>
                                Professional Services
                              </MenuItem>
                              <MenuItem value={"Real Estate"}>
                                Real Estate
                              </MenuItem>
                              <MenuItem value={"Transportation and Logistics"}>
                                Transportation and Logistics
                              </MenuItem>
                              <MenuItem value={"Travel and Hospitality"}>
                                Travel and Hospitality
                              </MenuItem>
                              <MenuItem value={"others"}>Others</MenuItem>
                              {/* <MenuItem value={30}>Others</MenuItem> */}
                            </Select>
                          </FormControl>
                          {previndustry == "others" ? (
                            <Box sx={{ mt: 1 }}>
                              <TextField
                                sx={{
                                  width: { xs: 250, sm: 430, md: 450 },
                                  mb: { xs: 0, sm: 2, md: 2 },
                                  background: "#fff",
                                  borderRadius: "5px",
                                }}
                                placeholder="Enter Your Previous Industry"
                                onChange={handleChangePrevIndustryOther}
                                value={previndustryOther}
                                //   sx={{ width: 480 }}
                                size="small"
                                //  onKeyPress={(e) => {
                                //     if (new RegExp(/[a-zA-Z]/).test(e.key)) {
                                //     } else e.preventDefault();
                                //   }}
                              />
                            </Box>
                          ) : (
                            <Box></Box>
                          )}
                          {/* {name?"":<Typography sx={{color:"red"}}>This Filed is Required</Typography>} */}
                        </Box>
                        <Box>
                          <Typography
                            sx={{
                              fontWeight: "bold",
                              fontSize: "14px",

                              color: "#000",
                              textAlign: "left",
                            }}
                          >
                            {" "}
                            Previous Company Name{" "}
                            <span style={{ color: "red" }}>*</span>
                          </Typography>
                          <BootstrapInput
                            onChange={handleChangePreviousCompany}
                            value={previuosCompany}
                            sx={{
                              width: { xs: 250, sm: 430, md: 450 },
                              mb: { xs: 0, sm: 2, md: 2 },
                            }}
                            size="small"
                            onKeyPress={(e) => {
                              if (new RegExp(/[a-zA-Z]/).test(e.key)) {
                              } else e.preventDefault();
                            }}
                          />
                        </Box>
                        <Box>
                          <Typography
                            sx={{
                              fontWeight: "bold",
                              fontSize: "14px",
                              color: "#000",
                              textAlign: "left",
                            }}
                          >
                            Upload Relevant Document
                            <span style={{ color: "red" }}>* <i style={{ color: "#000", fontSize: "12px" }}>
                        (pdf format)
                      </i></span>
                          </Typography>
                          <TextField
                            inputProps={{
                              accept: "application/pdf",
                              // onChange: handleFileChange,
                            }}
                            onChange={handleChangeExperinceDocuments}
                            size="small"
                            sx={{
                              width: { xs: 250, sm: 430, md: 450 },
                              background: "#fff",
                              borderRadius: "4px",
                            }}
                            fullWidth
                            type="file"
                          ></TextField>
                        </Box>
                      </Box>

                      <Box sx={{ mt: 2 }}>
                        <FormGroup sx={{ mb: 1 }}>
                          {/* <FormControlLabel control={<Checkbox defaultChecked />} label="Label" /> */}
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={check}
                                onChange={handleChange}
                              />
                            }
                            label={
                              <div
                                style={{
                                  color: "#000",
                                  fontWeight: "bold",
                                  fontSize: "14px",
                                }}
                              >
                                I accept all the terms and conditions{" "}
                                <a
                                  href={props.policy}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                  style={{
                                    color: "blue",
                                    cursor: "pointer",
                                    textDecoration: "none",
                                  }}
                                  //  className={classes.hyperlink}
                                >
                                  (Read more)
                                  <span style={{ color: "red" }}>*</span>
                                </a>
                              </div>
                            }
                          />
                          {/* <FormControlLabel disabled control={<Checkbox />} label="Disabled" /> */}
                        </FormGroup>
                        <FormGroup sx={{ display: "flex", mt: 1 }}>
                          {/* <FormControlLabel control={<Checkbox defaultChecked />} label="Label" /> */}
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={check2}
                                onChange={handleChange2}
                              />
                            }
                            label={
                              <div
                                style={{
                                  color: "#000",
                                  fontWeight: "bold",
                                  fontSize: "14px",
                                }}
                              >
                                I confirm that I have gone through the program
                                and it’s details and the program fits my career
                                objective<span style={{ color: "red" }}>*</span>{" "}
                                <a
                                  href={props.inclusion}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                  style={{
                                    color: "blue",
                                    cursor: "pointer",
                                    textDecoration: "none",
                                  }}
                                  //  className={classes.hyperlink}
                                >
                                  (Read more)
                                </a>
                              </div>
                            }
                          />

                          {/* <FormControlLabel disabled control={<Checkbox />} label="Disabled" /> */}
                        </FormGroup>
                        <FormGroup sx={{display:"flex",mt:1}}>
      {/* <FormControlLabel control={<Checkbox defaultChecked />} label="Label" /> */}
      <FormControlLabel  control={<Checkbox checked={true}   />}  label={
         <div style={{color:"#000",fontWeight:"bold",fontSize:"14px"}}>
        I give consent to receive communications through WhatsApp and SMS.<span style={{color:"red"}}>*</span>{' '}
       </div>
      } />
     
      {/* <FormControlLabel disabled control={<Checkbox />} label="Disabled" /> */}
    </FormGroup>
                      </Box>
                    </Box>
                  )}
                </Box>
              )}
              {/* {choice == "" ? (
          <Box></Box>
        ) : (
          <Box>
            {choice == "Yes" ? (
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  mt: 1,
                  mb: 1,
                }}
              >
                <Box>
                  <Typography
                    sx={{
                      fontWeight: "bold",
                      fontSize: "14px",
                      color: "#000",
                    }}
                  >
                    Current Company Name <span style={{ color: "red" }}>*</span>
                  </Typography>
                  <BootstrapInput
                    // onChange={handleChangeCpmanyName}
                    // value={companyname}
                    sx={{ width: 480 }}
                    size="small"
                    onKeyPress={(e) => {
                      if (new RegExp(/[a-zA-Z]/).test(e.key)) {
                      } else e.preventDefault();
                    }}
                  />
                </Box>
                <Box>
                  <Typography
                    sx={{
                      fontWeight: "bold",
                      fontSize: "14px",
                      color: "#000",
                    }}
                  >
                    Company Email Id <span style={{ color: "red" }}>*</span>
                  </Typography>
                  <TextField
                    type="email"
                    // onChange={handleChangeCompanyemail}
                    // value={companyemail}
                    sx={{ width: 480, background: "#fff", borderRadius: "5px" }}
                    size="small"
                  />
                </Box>
              </Box>
            ) : (
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  mt: 1,
                  mb: 1,
                }}
              >
                <Box>
                  <Typography
                    sx={{
                      fontWeight: "bold",
                      fontSize: "14px",
                      color: "#000",
                    }}
                  >
                    {" "}
                    Previous Company Name{" "}
                    <span style={{ color: "red" }}>*</span>
                  </Typography>
                  <BootstrapInput
                    // onChange={handleChangePreviousCompany}
                    // value={previuosCompany}
                    sx={{ width: 480 }}
                    size="small"
                    onKeyPress={(e) => {
                      if (new RegExp(/[a-zA-Z]/).test(e.key)) {
                      } else e.preventDefault();
                    }}
                  />
                </Box>
                <Box>
                  <Typography
                    sx={{
                      fontWeight: "bold",
                      fontSize: "14px",
                      color: "#000",
                    }}
                  >
                    Upload Relevant Document
                    <span style={{ color: "red" }}>*</span>
                  </Typography>
                  <TextField
                    inputProps={{
                      accept: "application/pdf, image/*",
                      // onChange: handleFileChange,
                    }}
                    // onChange={handleChangeExperinceDocuments}
                    size="small"
                    sx={{ width: 480, background: "#fff", borderRadius: "4px" }}
                    fullWidth
                    type="file"
                  ></TextField>
                </Box>
              </Box>
            )}
          </Box>
        )} */}

              {/* </Collapse> */}
              {/* <Box sx={{ display: "flex", justifyContent: "end", mt: 1 }}>
          <Button
            variant="conatained"
            size="small"
            sx={{
              color: "#fff",
              background: "#fdb714",
              "&:hover": { background: "#fdb714", color: "#fff" },
            }}
            // onClick={handleNext}
            // disabled={nxt}
          >
            Submit
          </Button>
        </Box> */}
            </Box>

            <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
              {/* <Typography
              sx={{
                fontWeight: "bold",
                fontSize: "14px",
                color: "#fff",
                mb: 1,
              }}
            >
              <i>Please make the payment below</i>
            </Typography> */}

              <Button
                // variant="conatained"
                // size="small"
                sx={{
                  color: "#fff",
                  mt: 1,
                  background: "#002E5C",
                  "&:hover": { background: "#002E5C", color: "#fff" },
                }}
                // onClick={handleNext}
                // disabled={nxt}
                variant="contained"
                size="small"
                width="150"
                height="80"
                onClick={handleNext}
                disabled={nxt}
              >
                Submit
              </Button>
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default Education;
